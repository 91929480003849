import React, { useEffect, useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login, register } from "../redux/features/authSlice";
import { loginValidation, signUpValidation } from "./../utils/validation";
import { isYupError, parseYupError } from "./../utils/Yup";
import { toast } from "react-toastify";

const Login = () => {
  const emailData = JSON.parse(localStorage.getItem("user_email"));
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);
  const [eye, setEye] = useState(false);
  const [email, setEmail] = useState({ email: "" });
  const [emailError, setEmailError] = useState({});

  const [form, setForm] = useState({
    email: "",
    password: "",
    verificationCode: "",
  });
  useEffect(() => {
    setForm({
      email: emailData || "",
    });
    setEmail({ email: emailData || "" });
  }, [emailData]);

  const [formError, setFormError] = useState({});
  const navigate = useNavigate();
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setFormError({});
      await loginValidation.validate(form, {
        abortEarly: false,
      });
      dispatch(login({ form, navigate, toast }));
    } catch (error) {
      if (isYupError(error)) {
        setFormError(parseYupError(error));
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
    setFormError({
      ...formError,
      [name]: "",
    });
  };
  const handleVerificationCode = async (e) => {
    e.preventDefault();
    try {
      setEmailError("");
      localStorage.setItem("user_email", JSON.stringify(email.email));
      await signUpValidation.validate(email, {
        abortEarly: false,
      });
      dispatch(register({ email, setStep, toast }));
    } catch (error) {
      if (isYupError(error)) {
        setEmailError(parseYupError(error));
      } else {
      }
    }
  };
  const [step, setStep] = useState(1);
  console.log(emailData, "emailData");
  return (
    <div className=" min-h-screen w-full flex justify-center bg-opacity-50 bg-blue-50 items-center">
      {step === 1 && (
        <div className="shadow-[rgba(7,_65,_210,_0.1)_0px_9px_30px]  bg-white p-14 flex flex-col items-center rounded-xl max-w-[550px] w-full">
          <h2 className="text-theme-darker text-center  font-black">
            Welcome Back
          </h2>
          <span className="text-[18px] mt-2 font-medium text-theme-text ">
            Sign Into Account
          </span>

          <form className="w-full flex flex-col gap-6 mt-4">
            <div className="input_field">
              <input
                type="text"
                placeholder="Enter Email Address"
                name="email"
                value={email.email}
                onChange={(e) => {
                  setEmail({ email: e.target.value });
                  setEmailError({ ...emailError, [e.target.name]: "" });
                }}
              />
              {emailError.email && (
                <div className="form-eror">{emailError.email}</div>
              )}
            </div>
            <button
              onClick={handleVerificationCode}
              type="submit"
              className="btn btn-primary w-full max-w-[100%] px-2 uppercase  text-[18px] "
            >
              {loading ? "Loading.." : "Sent Verification code"}
            </button>
          </form>
        </div>
      )}
      {step === 2 && (
        <div className="shadow-[rgba(7,_65,_210,_0.1)_0px_9px_30px]    bg-white p-14 flex flex-col items-center rounded-xl max-w-[550px] w-full">
          <h2 className="text-theme-darker  font-black  text-center">
            Welcome Back
          </h2>
          <span className="text-[18px] mt-2 font-medium text-theme-text ">
            Sign Into Account
          </span>

          <form className="w-full flex flex-col gap-6 mt-4">
            <div className="input_field">
              <input
                type="text"
                placeholder="Email Address"
                name="email"
                value={form.email}
                onChange={handleChange}
              />
              {formError.email && (
                <div className="form-eror">{formError.email}</div>
              )}
            </div>
            <div>
              <div className="input_field relative">
                <input
                  type={eye ? "text" : "password"}
                  placeholder="Password"
                  name="password"
                  onChange={handleChange}
                />

                <span
                  className="absolute right-4 top-[50%] translate-y-[-50%] cursor-pointer z-10"
                  onClick={() => setEye(!eye)}
                >
                  {eye ? (
                    <AiFillEye className="text-2xl text-theme-text" />
                  ) : (
                    <AiFillEyeInvisible className="text-2xl text-theme-text" />
                  )}
                </span>
              </div>
              {formError.password && (
                <div className="form-eror">{formError.password}</div>
              )}
            </div>

            <div className="input_field">
              <input
                type="text"
                placeholder="Enter OTP Code"
                name="verificationCode"
                onChange={handleChange}
              />
              {formError.verificationCode && (
                <div className="form-eror">{formError.verificationCode}</div>
              )}
            </div>
            <button
              onClick={handleLogin}
              type="submit"
              className="btn btn-primary w-full max-w-[100%] uppercase  text-[18px] "
            >
              {loading ? "Loading.." : "Login"}
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default Login;
