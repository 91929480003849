import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";

const TotalReferralUsers = ({ handleClose, open, referralUsers }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="a-center border-0 ring-0 outline-0 bg-white rounded-2xl drop-shadow-lg shadow-lg text-black max-w-[400px] w-full p-4">
        <h6 className="mb-4 text-center">Total Users</h6>
        <ul className="referral-users flex flex-col gap-2 pr-2">
          {referralUsers.map((item) => (
            <Link key={item.userId} to={`/profile/${item.userId}`}>
              <li className="shadow-sm border-2 border-zinc-100 p-2 rounded-lg hover:shadow-md duration-100">
                <strong className="name text-xs lg:text-sm xl:text-base">
                  {item.fullname}
                </strong>
                {item.wallets.map((innerItem, index) => (
                  <div
                    className="flex items-start gap-2 justify-between"
                    key={index}
                  >
                    <div className="left">
                      <strong className="text-zinc-600 text-[10px] lg:text-[12px]">
                        Wallet
                      </strong>
                      <p className="text-[9px] lg:text-[11px]">
                        {" "}
                        {innerItem?.wallet.slice(1, 6) +
                          "........" +
                          innerItem?.wallet.slice(25, 29) || "" + "...."}
                      </p>
                    </div>
                    <div className="right">
                      <strong className="text-zinc-600 text-[10px] lg:text-[12px]">
                        Earnings
                      </strong>
                      <p className=" text-[9px] lg:text-[11px]">
                        {innerItem.earnings}
                      </p>
                    </div>
                  </div>
                ))}
              </li>
            </Link>
          ))}
        </ul>
      </Box>
    </Modal>
  );
};

export default TotalReferralUsers;
