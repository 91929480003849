import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";

const UserDetail = ({ handleClose, open, data }) => {
  const handleCloseModal = () => {
    handleClose();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseModal}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitle id="scroll-dialog-title">
          {" "}
          <h4 className=" text-center">User Details</h4>
        </DialogTitle>
        <DialogContent dividers={"paper"}>
          <div className="w-full ">
            <div className=" flex flex-col gap-1 py-6 px-6 ">
              <div className="flex items-center">
                <strong className="font-semibold min-w-[140px]">Name:</strong>
                <p>{data?.fullname}</p>
              </div>
              <div className="flex items-center">
                <strong className="font-semibold min-w-[140px]">Email:</strong>
                <p>{data?.email}</p>
              </div>
              <div className="flex items-center">
                <strong className="font-semibold min-w-[140px]">Mobile:</strong>
                <p>{data?.mobile}</p>
              </div>
              <div className="flex items-center">
                <strong className="font-semibold min-w-[140px]">
                  Country:
                </strong>
                <p>{data?.country || "N/A"}</p>
              </div>
              <div className="flex items-center">
                <strong className="font-semibold min-w-[140px]">State:</strong>
                <p>{data?.state || "N/A"}</p>
              </div>
              <div className="flex items-center">
                <strong className="font-semibold min-w-[140px]">City:</strong>
                <p>{data?.city || "N/A"}</p>
              </div>

              <div className="flex items-center">
                <strong className="font-semibold min-w-[140px]">
                  UTR Number:
                </strong>
                <p>{data?.utrCode || "N/A"}</p>
              </div>

              <div className="flex items-center">
                <strong className="font-semibold min-w-[140px]">
                  Current UPI:
                </strong>
                <p>{data?.platformUpiId || "N/A"}</p>
              </div>

              <div className="flex items-center">
                <strong className="font-semibold min-w-[140px]">Amount:</strong>
                <p>{data?.transactionAmount || "N/A"}</p>
              </div>

              <div className="flex items-center">
                <strong className="font-semibold min-w-[140px]">
                  Screenshot:
                </strong>
                <a
                  href={data?.screenshotUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="underline text-[#0000FF]"
                >
                  {data?.screenshotUrl}
                </a>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            type="button"
            onClick={handleCloseModal}
            className={`btn btn-danger `}
          >
            Close
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserDetail;
