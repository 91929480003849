import * as api from "../redux/api";
import { toast } from "react-toastify";

export const activateMerchantSatatus = async (id, callBackFun) => {
  try {
    const res = await api.activateMerchnat(id);
    await console.log(res, "res");
    if (res.data.status === "success") {
      toast.success("Merchant Activated Successfully");
      //   getMerchantsInfo();
      callBackFun();
    } else {
      toast.error("Error Occurred");
    }
  } catch (err) {
    console.log(err);
  }
};

export const deAtivateMerchantSatatus = async (id, callBackFun) => {
  try {
    const res = await api.deActivateMerchant(id);
    if (res.data.status === "success") {
      toast.success("Merchant Inactivated Successfully");
      // getMerchantsInfo();
      callBackFun();
    } else {
      toast.error("Error Occurred");
    }
  } catch (err) {
    console.log(err);
  }
};
export const blockMerchantSatatus = async (id, callBackFun, status) => {
  try {
    const res = await api.blockUnblockMerchant(id);
    console.log(res, "res");
    if (res.data.status === "success") {
      toast.success(`Merchant ${status} Successfully`);
      // getMerchantsInfo();
      callBackFun();
    } else {
      toast.error("Error Occurred");
    }
  } catch (err) {
    console.log(err);
  }
};
export const changeCurrentMerchnatStatus = async (
  id,
  path,
  message,
  callBackFun
) => {
  try {
    const res = await api.changeMerchantStatus(id, path);
    if (res.data.status === "success") {
      toast.success(`Merchant ${message} Successfully`);
      callBackFun();
    } else {
      toast.error("Error Occurred");
    }
  } catch (err) {
    console.log(err);
  }
};

export const handleAcceptKYCRequest = async (id, callBackFun) => {
  try {
    const res = await api.acceptUserKYCRequest(id);
    if (res.data.status === "success") {
      toast.success("KYC request accepted successfully. ");
      callBackFun();
    } else {
      toast.error("Error Occurred");
    }
  } catch (err) {
    console.log(err);
  }
};

export const handleRejectKYCRequest = async (id, callBackFun) => {
  try {
    const res = await api.rejectMerchantKYCRequest(id);
    if (res.data.status === "success") {
      toast.success("KYC request rejected successfully. ");
      callBackFun();
    } else {
      toast.error("Error Occurred");
    }
  } catch (err) {
    console.log(err);
  }
};
