import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { LinearProgress } from "@mui/material";
import CustomNoRowsOverlay from "./../utils/styles";
import * as api from "../redux/api";

import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import EditMerchant from "../components/modals/EditMerchant";
import ActionButton from "../components/buttons/ActionButton";
import { reactIcons } from "../utils/icons";
import MerchantKycDetails from "../components/modals/MerchantKycDetails";
import ActionButtonforBlock from "../components/buttons/ActionButtonforBlock";

const Merchants = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [userData, setUserData] = useState(null);
  const [isEditUserOpen, setIsEditUserOpen] = useState(false);
  const [openKycDetailModal, setOpenKycDetailModal] = useState(false);
  const isNewMerchant = searchParams.get("filter") === "new-merchant";
  const isKycMechant = searchParams.get("filter") === "KYC";
  const isAll = !isNewMerchant && !isKycMechant;
  const [isLoading, setIsLoading] = useState(false);
  const [kycItem, setKycItem] = useState({});
  // const [selectVal, setSelectVal] = useState("None");
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 8,
  });
  const [rowCountState, setRowCountState] = useState(0);
  const filterSearch = searchParams.get("filter");
  const [usersType, setUsersType] = useState(filterSearch || "verified");
  const [refresher, setRefresher] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [activeType, setActiveType] = useState("true");
  const capitalized = (word) => word.charAt(0).toUpperCase() + word.slice(1);

  const kycDetailModalHandler = (params) => {
    setKycItem(params.row);
    setOpenKycDetailModal(true);
  };
  const generateColumns = (userType) => {
    let columns = [
      {
        field: "_id",
        headerName: "S.No.",
        type: "number",
        headerAlign: "center",
        align: "center",
        width: 100,
      },
      {
        field: "fullname",
        headerName: "Full Name",
        type: "string",
        flex: 2,
      },
      {
        field: "websiteDomain",
        headerName: "Website",
        type: "string",
        flex: 2,
        valueFormatter: (params) => (params.value ? params.value : "-"),
      },
    ];

    // KYC column if userType is KYC
    if (userType === "KYC") {
      columns.push({
        field: "status",
        headerName: "KYC Status",
        type: "string",
        flex: 2,
        valueFormatter: (params) => params.value && capitalized(params.value),
      });
    } else if (userType === "new-merchant") {
      columns.push({
        field: "registrationStatus",
        headerName: "Status",
        type: "string",
        flex: 2,
        valueFormatter: (params) =>
          params.value === "accepted" || params.value === "rejected"
            ? capitalized(params.value)
            : "Blocked",
      });
    } else {
      columns.push({
        field: "isActive",
        headerName: "Status",
        type: "string",
        flex: 2,
        valueFormatter: (params) =>
          params.value === true ? "Unblock" : "Block",
      });
    }

    columns.push({
      field: "actions",
      headerName: "Action",
      type: "actions",
      width: 150,
      renderCell: (params) => {
        return userType === "KYC" ? (
          <>
            <span
              className="text-[20px] cursor-pointer"
              onClick={() => kycDetailModalHandler(params)}
            >
              {reactIcons.eyeVisible}
            </span>
          </>
        ) : usersType === "verified" ? (
          <ActionButtonforBlock
            data={params?.row}
            isEditUserOpen={isEditUserOpen}
            setIsEditUserOpen={setIsEditUserOpen}
            getMerchantsInfo={getMerchantsInfo}
            setUserData={setUserData}
            isKycMechant={isKycMechant}
            usersType={usersType}
          />
        ) : (
          <ActionButton
            data={params?.row}
            isEditUserOpen={isEditUserOpen}
            setIsEditUserOpen={setIsEditUserOpen}
            getMerchantsInfo={getMerchantsInfo}
            setUserData={setUserData}
            isKycMechant={isKycMechant}
            usersType={usersType}
          />
        );
      },
    });
    return columns;
  };

  const [rows, setRows] = useState([]);
  const [filterCol, setFilterCol] = useState(generateColumns(usersType));

  const getMerchantsInfo = () => {
    try {
      if (usersType === "verified") {
        getVerifiedMerchantsInfo();
      } else if (usersType === "new-merchant") {
        getNewMerchantsInfo();
      } else if (usersType === "KYC") {
        getKYCMerchantInfo();
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  useEffect(() => {
    const updatedColumns = generateColumns(usersType);
    setFilterCol(updatedColumns);
    getMerchantsInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersType, paginationModel, refresher]);

  const getVerifiedMerchantsInfo = async () => {
    setIsLoading(true);
    const skip = paginationModel.page * 8 || 0;
    const res = await api.getAllMerchantsInfo(
      paginationModel.page * 8,
      searchText,
      activeType
    );
    const { status, data } = res;

    if (status >= 200 && status < 300) {
      setIsLoading(false);
      setRows(
        data?.merchant.map((item, index) => {
          return {
            ...item,
            _id: skip + index + 1,
          };
        })
      );
      setRowCountState(data?.merchantCount || 0);
    } else {
      setIsLoading(false);
      toast.error(data.message);
    }
  };
  const getNewMerchantsInfo = async () => {
    setIsLoading(true);
    const skip = paginationModel.page * 8 || 0;
    const res = await api.getNewMerchants(skip);
    const { status, data } = res;
    if (status >= 200 && status < 300) {
      setIsLoading(false);
      setRows(
        data?.merchant.map((item, index) => {
          return {
            ...item,
            _id: skip + index + 1,
          };
        })
      );
      setRowCountState(data?.merchantCount || 0);
    } else {
      setIsLoading(false);
      toast.error(data.message);
    }
  };

  const getKYCMerchantInfo = async () => {
    setIsLoading(true);
    const skip = paginationModel.page * 8 || 0;
    const res = await api.getKycCompletedMerchants(skip);
    const { status, data } = res;

    if (status >= 200 && status < 300) {
      setIsLoading(false);
      setRows(
        data?.merchant.map((item, index) => {
          return {
            ...item,
            ...item.merchant,
            _id: skip + index + 1,
          };
        })
      );
      setRowCountState(data?.merchantCount || 0);
    } else {
      setIsLoading(false);
      toast.error(data.message);
    }
  };

  // const selectboxHandler = (e) => {
  //   setSelectVal(e.target.value);

  //   if (e.target.value == "Active") {
  //     console.log("Active select");
  //   } else if (e.target.value == "Inactive") {
  //     console.log("Inactive select");
  //   } else {
  //     console.log("none select");
  //   }
  // };

  useEffect(() => {
    getVerifiedMerchantsInfo();
  }, [searchText, activeType]);

  return (
    <>
      <div className="w-full mt-10 data-grid">
        <div className="flex justify-between mb-6 items-center">
          <h4>Merchants</h4>
        </div>
        <div className="flex items-center justify-between gap-2">
          <div className="flex gap-6 items-center mb-4">
            <button
              className={`btn ${isAll ? "btn-primary" : "btn-outline-primary"}`}
              onClick={() => {
                setSearchParams({});
                setUsersType("verified");
              }}
            >
              All Merchants
            </button>
            <button
              className={`btn ${
                isNewMerchant ? "btn-primary" : "btn-outline-primary"
              }`}
              onClick={() => {
                setSearchParams({ filter: "new-merchant" });
                setUsersType("new-merchant");
              }}
            >
              New Merchants
            </button>
            <button
              className={`btn ${
                isKycMechant ? "btn-primary" : "btn-outline-primary"
              }`}
              onClick={() => {
                setSearchParams({ filter: "KYC" });
                setUsersType("KYC");
              }}
            >
              KYC Merchant
            </button>
          </div>
          {/* {
          usersType == "new-merchant" ? '' : usersType == "KYC" ? '' : 
        <div className='max-w-[200px] flex items-center gap-3'>
          <label>Filter</label>
          <select value={selectVal} onChange={selectboxHandler}>
            <option value="None">None</option>
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
          </select>
        </div>
        } */}
        </div>
        {usersType === "verified" && (
          <div className="flex justify-between items-center gap-6 ">
            {/* <h4>Level Bonus</h4> */}
            <div className="flex-1 max-w-[800px] flex justify-center items-center">
              <input
                className="w-full"
                type="text"
                value={searchText}
                placeholder="search by name"
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
            <div className="form-select-custom">
              <select
                className="min-w-[200px] w-full"
                name="usersType"
                id="usersType"
                value={activeType}
                onChange={(e) => setActiveType(e.target.value)}
              >
                <option value="true">Unblock</option>
                <option value="false">Block</option>
              </select>
              <span className="ay-center right-2 text-zinc-500 text-[28px] pointer-events-none ">
                {reactIcons.arrowDown}
              </span>
            </div>
          </div>
        )}

        <div className="h-[600px] w-full mt-4">
          <DataGrid
            loading={isLoading}
            rows={rows}
            getRowId={(row) => {
              return row._id;
            }}
            getEstimatedRowHeight={() => 52}
            columns={filterCol}
            initialState={{
              paginationModel: {
                pageSize: 8,
              },
            }}
            rowCount={rowCountState}
            pageSizeOptions={[8]}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
            slots={{
              loadingOverlay: LinearProgress,
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            disableColumnMenu
            disableRowSelectionOnClick
          />
        </div>
      </div>
      <EditMerchant
        open={isEditUserOpen}
        handleClose={() => setIsEditUserOpen(false)}
        data={userData}
        getMerchantsInfo={getMerchantsInfo}
        isKycMechant={isKycMechant}
      />
      <MerchantKycDetails
        open={openKycDetailModal}
        handleClose={() => setOpenKycDetailModal(false)}
        data={userData}
        getMerchantsInfo={getMerchantsInfo}
        isKycMechant={isKycMechant}
        kycItem={kycItem}
        refresher={() => setRefresher(!refresher)}
      />
    </>
  );
};

export default Merchants;
