/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { LinearProgress } from "@mui/material";
import CustomNoRowsOverlay from "./../utils/styles";
import * as api from "../redux/api";

import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { reactIcons } from "./../utils/icons";
import EditUser from "../components/modals/EditUser";
const Users = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchText, setSearchText] = useState("");
  const [userData, setUserData] = useState(null);
  const [isEditUserOpen, setIsEditUserOpen] = useState(false);
  const isActive = searchParams.get("filter") === "Active";
  const isInActive = searchParams.get("filter") === "InActive";
  const isAll = !isActive && !isInActive;
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 8,
  });
  const [rowCountState, setRowCountState] = useState(0);
  const [usersType, setUsersType] = useState("active");

  const handleBlockUser = async (id) => {
    try {
      const res = await api.blockUser(id);
      if (res.data.status === "success") {
        toast.success("Blocked Successfully");
        getUserData();
      } else {
        toast.error("Error Occurred");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleUnBlockUser = async (id) => {
    try {
      const res = await api.unblockUser(id);

      if (res.data.status === "success") {
        toast.success("UnBlocked Successfully");
        getUserData();
      } else {
        toast.error("Error Occurred");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const columns = [
    {
      field: "_id",
      headerName: "S.No.",
      type: "number",
      headerAlign: "center",
      align: "center",
      width: 100,
    },

    {
      field: "fullname",
      headerName: "Full Name",
      type: "string",
      flex: 2,
    },
    {
      field: "mobile",
      headerName: "Mobile",
      type: "string",
      flex: 2,
    },
    {
      field: "walletAddress",
      headerName: "Wallet Address",
      type: "string",
      flex: 2,
    },
    {
      field: "country",
      headerName: "Country",
      type: "string",
      flex: 2,
    },

    {
      field: "email",
      headerName: "Email",
      type: "string",
      flex: 3,
    },

    {
      field: "type",
      headerName: "Type",
      type: "string",
      width: 120,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="flex items-center gap-4 justify-center">
            <button
              onClick={() => {
                setIsEditUserOpen(true);
                setUserData(params.row);
              }}
              className="w-10 h-10 z-10 cursor-pointer text-[18px] bg-zinc-200 hover:bg-zinc-300 rounded-md flex-center"
            >
              {reactIcons.edit}
            </button>
            {params.row.status === "Active" ? (
              <button
                onClick={() => handleBlockUser(params.row.id)}
                className="px-4 text-xs lg:text-sm xl:text-base py-2 rounded-md bg-zinc-200 hover:bg-zinc-300"
              >
                Block
              </button>
            ) : (
              <button
                onClick={() => handleUnBlockUser(params.row.id)}
                className="px-4 text-xs lg:text-sm xl:text-base py-2 rounded-md bg-zinc-200 hover:bg-zinc-300"
              >
                UnBlock
              </button>
            )}
          </div>
        );
      },
    },
  ];
  const [rows, setRows] = useState([]);
  const [filterCol, setFilterCol] = useState(columns);

  const getUserData = async () => {
    setIsLoading(true);
    const response = await api.getAdminUsers(paginationModel.page);
    const { data, status } = response;
    if (status >= 200 && status < 300) {
      setIsLoading(false);
      setRowCountState(data.count);
      setRows(
        data.users.map((item, index) => {
          return {
            ...item,
            walletAddress: item?.wallets[0]?.address,
            _id: 8 * paginationModel.page + index + 1,
          };
        })
      );
    } else {
      setIsLoading(false);
      toast.error(data.message);
    }
  };
  const getInActiveUserData = async () => {
    setIsLoading(true);
    const response = await api.getAdminInActiveUsers(paginationModel.page);
    const { data, status } = response;
    if (status >= 200 && status < 300) {
      setIsLoading(false);
      setRowCountState(data.count);
      setRows(
        data.users.map((item, index) => {
          return {
            ...item,
            walletAddress: item?.wallets[0]?.address,
            _id: 8 * paginationModel.page + index + 1,
          };
        })
      );
    } else {
      setIsLoading(false);
      toast.error(data.message);
    }
  };
  const getBlockedUserData = async () => {
    setIsLoading(true);
    const response = await api.getAdminBlockedUsers(paginationModel.page);
    const { data, status } = response;
    if (status >= 200 && status < 300) {
      setIsLoading(false);
      setRowCountState(data.count);
      setRows(
        data.users.map((item, index) => {
          return {
            ...item,
            walletAddress: item?.wallets[0]?.address,
            _id: 8 * paginationModel.page + index + 1,
          };
        })
      );
    } else {
      setIsLoading(false);
      toast.error(data.message);
    }
  };
  const getAdminActiveLastUsersData = async () => {
    setIsLoading(true);
    const response = await api.getAdminActiveLastUsers(paginationModel.page);
    const { data, status } = response;
    if (status >= 200 && status < 300) {
      setIsLoading(false);
      setRowCountState(data.count);
      setRows(
        data.users.map((item, index) => {
          return {
            ...item.user,
            walletAddress: item?.address,
            _id: 8 * paginationModel.page + index + 1,
          };
        })
      );
    } else {
      setIsLoading(false);
      toast.error(data.message);
    }
  };
  const getAdminInActiveLastUsersData = async () => {
    setIsLoading(true);
    const response = await api.getAdminInActiveLastUsers(paginationModel.page);
    const { data, status } = response;
    if (status >= 200 && status < 300) {
      setIsLoading(false);
      setRowCountState(data.count);
      setRows(
        data.users.map((item, index) => {
          return {
            ...item.user,
            walletAddress: item?.address,
            _id: 8 * paginationModel.page + index + 1,
          };
        })
      );
    } else {
      setIsLoading(false);
      toast.error(data.message);
    }
  };
  const getUserDataBySearch = async (text) => {
    setIsLoading(true);
    const response = await api.getUserBySearch(paginationModel.page, text);
    const { data, status } = response;
    if (status >= 200 && status < 300) {
      setIsLoading(false);
      setRowCountState(data.count);
      setRows(
        data.users.map((item, index) => {
          return {
            ...item,
            walletAddress: item?.wallets[0]?.address,
            _id: 8 * paginationModel.page + index + 1,
          };
        })
      );
    } else {
      setIsLoading(false);
      toast.error(data.message);
    }
  };
  useEffect(() => {
    if (isAll) {
      try {
        if (searchText.length > 0) {
          getUserDataBySearch(searchText);
        } else {
          if (usersType === "active") getUserData();
          else if (usersType === "inactive") getInActiveUserData();
          else getBlockedUserData();
        }
      } catch (err) {
        setIsLoading(false);
        console.log(err);
      }
    } else if (isActive) {
      try {
        getAdminActiveLastUsersData();
      } catch (err) {
        setIsLoading(false);
        console.log(err);
      }
    } else if (isInActive) {
      try {
        getAdminInActiveLastUsersData();
      } catch (err) {
        setIsLoading(false);
        console.log(err);
      }
    }
    setRowCountState((prevRowCountState) =>
      rowCountState !== undefined ? rowCountState : prevRowCountState
    );
  }, [
    paginationModel,
    rowCountState,
    setRowCountState,
    usersType,
    isActive,
    isInActive,
    isAll,
    searchText,
    isEditUserOpen === false,
  ]);
  useEffect(() => {
    if (user) {
      if (!user.privileges.includes("WriteUsers")) {
        const filteredArr = columns.filter((item) => item.field !== "actions");
        setFilterCol(filteredArr);
      }
    }
  }, [user, columns]);

  return (
    <>
      <div className="w-full mt-10 data-grid">
        <div className="flex gap-6 items-center mb-4">
          <button
            className={`btn ${isAll ? "btn-primary" : "btn-outline-primary"}`}
            onClick={() => setSearchParams({})}
          >
            All Users
          </button>
          <button
            className={`btn ${
              isActive ? "btn-primary" : "btn-outline-primary"
            }`}
            onClick={() => setSearchParams({ filter: "Active" })}
          >
            Last Seven Days Active Users
          </button>
          <button
            className={`btn ${
              isInActive ? "btn-primary" : "btn-outline-primary"
            }`}
            onClick={() => setSearchParams({ filter: "InActive" })}
          >
            Last Seven Days InActive Users
          </button>
        </div>
        {isAll && (
          <div className="flex justify-between items-center gap-6 ">
            {/* <h4>Level Bonus</h4> */}
            <div className="flex-1 max-w-[800px] flex justify-center items-center">
              <input
                className="w-full"
                type="text"
                value={searchText}
                placeholder="search by name"
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
            <div className="form-select-custom">
              <select
                className="min-w-[200px] w-full"
                name="usersType"
                id="usersType"
                value={usersType}
                onChange={(e) => setUsersType(e.target.value)}
              >
                <option value="active">Active</option>
                <option value="inactive">InActive</option>
                <option value="blocked">Blocked</option>
              </select>
              <span className="ay-center right-2 text-zinc-500 text-[28px] pointer-events-none ">
                {reactIcons.arrowDown}
              </span>
            </div>
          </div>
        )}
        <div className=" h-[600px] w-full mt-4">
          <DataGrid
            loading={isLoading}
            rows={rows}
            getRowId={(row) => {
              return row._id;
            }}
            getEstimatedRowHeight={() => 52}
            columns={filterCol}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 8,
                },
              },
            }}
            rowCount={rowCountState}
            pageSizeOptions={[8]}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
            slots={{
              loadingOverlay: LinearProgress,
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            disableColumnMenu
            disableRowSelectionOnClick
          />
        </div>
      </div>
      <EditUser
        open={isEditUserOpen}
        handleClose={() => setIsEditUserOpen(false)}
        data={userData}
      />
    </>
  );
};

export default Users;
