import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { LinearProgress } from "@mui/material";
import CustomNoRowsOverlay from "../utils/styles";
import * as api from "../redux/api";

import { useParams } from "react-router-dom";
import TotalReferralUsers from "../components/modals/TotalReferralUsers";
import { toast } from "react-toastify";
const ReferralUsers = () => {
  const { userId, address } = useParams();
  const [referralUsers, setReferralUsers] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [userProfileData, setUserProfileData] = useState(null);

  const getUserData = async (userId) => {
    const { status, data } = await api.getUser(userId);
    if (status >= 200 && status < 300) {
      setUserProfileData(data);
    } else {
      toast.error(data.message);
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "S.No.",
      type: "number",
      headerAlign: "center",
      align: "center",
      width: 120,
    },

    {
      field: "users",
      headerName: "Users",
      type: "number",
      headerAlign: "center",
      align: "center",
      flex: 2,
    },
    {
      field: "level",
      headerName: "Level",
      type: "number",
      flex: 2,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "bonus",
      headerName: "Bonus",
      type: "number",
      flex: 2,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "referralUsers",
      headerName: "Referral Users",
      type: "string",
      flex: 3,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <span
            onClick={() => {
              setReferralUsers(params.row.referralUsers);
              setIsOpen(true);
            }}
            className="cursor-pointer text-xs lg:text-sm xl:text-base px-4 py-2 block mx-auto bg-cyan-500 font-medium rounded-md text-white"
          >
            {params.row.referralUsers.length}
          </span>
        );
      },
    },
  ];
  const [rows, setRows] = useState([]);

  const getReferralUsers = async (userId, address) => {
    const { status, data } = await api.getReferralStats({ userId, address });
    if (status >= 200 && status < 300) {
      const formatedData = data.levels.map((item, index) => {
        return { ...item, id: index + 1 };
      });
      setRows(formatedData);
    } else {
      toast.error(data.message);
    }
  };
  useEffect(() => {
    try {
      getReferralUsers(userId, address);
    } catch (err) {
      console.log(err);
    }
  }, [userId, address]);
  useEffect(() => {
    try {
      getUserData(userId);
    } catch (err) {
      console.log(err);
    }
  }, [userId]);
  return (
    <div className="w-full mt-10 data-grid">
      <div className="flex flex-col gap-2 mb-6">
        <h5>{userProfileData?.fullname}</h5>
        <div className="flex gap-4">
          <strong className="text-xs lg:text-sm xl:text-base">
            Referral Code:
          </strong>
          <div className="text-xs lg:text-sm xl:text-base">
            {process.env.NODE_ENV !== "production"
              ? process.env.REACT_APP_DEV_API
              : process.env.REACT_APP_REFERRAL}
            /{userProfileData?.meta?.referralCode}
          </div>
        </div>
      </div>
      <div className=" h-[600px] w-full">
        <DataGrid
          rows={rows}
          // getRowId={(row) => row.userId}
          getEstimatedRowHeight={() => 52}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 8,
              },
            },
          }}
          pageSizeOptions={[8, 10, 15]}
          slots={{
            loadingOverlay: LinearProgress,
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          disableColumnMenu
          disableRowSelectionOnClick
        />
      </div>
      <TotalReferralUsers
        referralUsers={referralUsers}
        open={isOpen}
        handleClose={() => setIsOpen(false)}
      />
    </div>
  );
};

export default ReferralUsers;
