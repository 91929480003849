import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { LinearProgress } from "@mui/material";
import CustomNoRowsOverlay from "./../utils/styles";
import * as api from "../redux/api";
import { toast } from "react-toastify";
import AssignPartner from "../components/modals/AssignPartner";
import { getTimeLeft } from "../utils/function";
import Countdown from "../components/forms/Countdown";
import moment from "moment";
const MechantsRequest = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [assign, setAssign] = useState({});
  const [rejectedData, setRejectedData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 8,
  });
  const [rowCountState, setRowCountState] = useState(0);

  const handleAssign = async (data) => {
    await getRejectedData(data.id);
    setAssign(data);
    setIsOpen(true);
  };

  const [rows, setRows] = useState([]);
  const handleRefreshData = () => {
    setRefresh(true);
  };
  const columns = [
    {
      field: "_id",
      headerName: "S.No.",
      type: "number",
      headerAlign: "center",
      align: "center",
      width: 90,
    },
    {
      field: "fullname",
      headerName: "Partner Name",
      type: "string",
      width: 140,
      renderCell: (params) => {
        return <div className="text-12">{params.row.merchant.fullname || "N/A"}</div>;
      },
    },

    {
      field: "Location",
      headerName: "Location",
      type: "string",
      width: 250,
      renderCell: (params) => {
        // return <div>{params.row.location || "N/A"}</div>;
        return (
          <div className="text-12" style={{ 
            whiteSpace: 'normal',  // Allows wrapping text onto multiple lines
            wordWrap: 'break-word',  // Breaks long words onto the next line
            overflow: 'hidden',  // Prevents overflow beyond the height of the row
            maxWidth: '250px',
          }}>
            {params.row.location || "N/A"}
          </div>
        );
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      type: "string",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "requestType",
      headerName: "Order Type",
      type: "string",
      width: 120,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: "Status",
      type: "string",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "createdAt",
      headerName: "Created At",
      type: "string",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <>
          {moment(params?.row?.createdAt).format("DD MMM YYYY hh:mm A")}
          </>
        );
      },
    },
    {
      field: "expirationTime",
      headerName: "Expire In",
      type: "string",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Countdown
            drawingOn={params?.row?.expirationTime}
            refreshComponent={handleRefreshData}
          />
        );
      },
    },
    
    {
      field: "actions",
      headerName: "Assign",
      type: "actions",
      width: 140,
      renderCell: (params) => {
        const pendingPartner =
          Array.isArray(params?.row?.AssignedPartner) &&
          params.row.AssignedPartner.filter(
            (item) => item.status === "Pending"
          );
        const acceptedPartner =
          Array.isArray(params?.row?.AssignedPartner) &&
          params.row.AssignedPartner.filter(
            (item) => item.status === "Accepted"
          );
        const isExpired =
          getTimeLeft(params?.row?.expirationTime) === "Expired";
        const getPartnerName = () => {
          if (pendingPartner.length > 0) {
            return pendingPartner[0]?.partner?.fullname; // Show pending partner name first
          } else if (acceptedPartner.length > 0) {
            return acceptedPartner[0]?.partner?.fullname; // Show accepted partner name if no pending partner
          }
          return null;
        };
        return (
          <>
            {isExpired ? (
              <button className="px-2 py-1 text-[10px] font-bold rounded-lg bg-zinc-200 min-w-[100px] max-w-[100px] text-center">
                Expired
              </button>
            ) : getPartnerName() ? (
              <button className="px-2 py-1 text-[10px] font-bold rounded-lg bg-zinc-200 min-w-[100px] max-w-[100px] text-center">
                {getPartnerName()}
              </button>
            ) : (
              <button
                type="button"
                onClick={() => handleAssign(params.row)}
                className="px-2 py-1 text-[10px] font-bold rounded-lg bg-zinc-200 min-w-[100px] max-w-[100px] text-center"
              >
                Assign
              </button>
            )}
          </>
        );
      },
    },
  ];
  const getMerchantRequestData = async () => {
    setIsLoading(true);
    const response = await api.getMerchantRequest(paginationModel.page);
    const { status, data } = response;
    if (status >= 200 && status < 300) {
      setIsLoading(false);
      setRowCountState(data?.count);
      setRows(
        data?.data?.map((item, index) => {
          return { ...item, _id: 8 * paginationModel.page + index + 1 };
        })
      );
    } else {
      setIsLoading(false);
      toast.error(data.message);
    }
  };
  useEffect(() => {
    try {
      getMerchantRequestData();
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }

    setRowCountState((prevRowCountState) =>
      rowCountState !== undefined ? rowCountState : prevRowCountState
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel, rowCountState, setRowCountState]);

  useEffect(() => {
    if (refresh === true) {
      try {
        getMerchantRequestData();
        setRefresh(false);
      } catch (err) {
        setIsLoading(false);
        console.log(err);
      }
      setRowCountState((prevRowCountState) =>
        rowCountState !== undefined ? rowCountState : prevRowCountState
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel, rowCountState, setRowCountState]);

  const getRejectedData = async (orderId) => {
    setIsLoading(true);
    const response = await api.getRejectedRequest(orderId);
    const { status, data } = response;
    if (status >= 200 && status < 300) {
      setRejectedData(data?.data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      toast.error(data.message);
    }
  };

  return (
    <>
      <div className="w-full mt-6 data-grid">
        <div className="flex justify-between mb-6 items-center">
          <h4>Merchant Request</h4>
          {/* <Link to="/create-subadmin">
          <button className="btn btn-primary">Create New SubAdmins</button>
        </Link> */}
        </div>

        <div className=" h-[600px] w-full">
          <DataGrid
            loading={isLoading}
            rows={rows}
            getEstimatedRowHeight={() => 52}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 8,
                },
              },
            }}
            rowCount={rowCountState}
            pageSizeOptions={[8]}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
            slots={{
              toolbar: GridToolbar,
              loadingOverlay: LinearProgress,
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            disableColumnMenu
            disableRowSelectionOnClick
          />
        </div>
      </div>
      <AssignPartner
        open={isOpen}
        handleClose={() => {
          setIsOpen(false);
          getMerchantRequestData(
            paginationModel,
            rowCountState,
            setRowCountState
          );
        }}
        data={assign}
        rejectedData={rejectedData}
      />
    </>
  );
};

export default MechantsRequest;
