import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { reactIcons } from "../../utils/icons";
import { toast } from "react-toastify";
import * as api from "../../redux/api";
import { QrCodeValidation } from "../../utils/validation";
import { isYupError, parseYupError } from "../../utils/Yup";
const initialState = {
  upi: "",
  image: "",
};
const AddQrCode = ({ handleClose, open, updateData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState(initialState);
  const [_image, _setImage] = useState("");
  const [formError, setFormError] = useState({});
  const handleCloseModal = () => {
    handleClose();
    setForm(initialState);
    _setImage("");
    setFormError({});
  };
  const onImageChange = async (event) => {
    const data = new FormData();
    data.append("file", event.target.files[0]);

    const image = await api.getFileUrl(data);
    if (image.status) {
      _setImage(image.data.url);
      setForm({ ...form, image: image?.data?.meta?.filename || "" });
    }
  };
  const handleUpdate = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      setFormError({});
      await QrCodeValidation.validate(form, {
        abortEarly: false,
      });
      const res = await api.updateQrCode(updateData.id, form);
      const { status, data } = res;
      if (status >= 200 && status < 300) {
        setIsLoading(false);
        handleCloseModal();
        toast.success("Updated Successfully");
        _setImage("");
      } else {
        setIsLoading(false);
        toast.error(data.message);
      }
    } catch (error) {
      if (isYupError(error)) {
        setFormError(parseYupError(error));
      } else {
        console.log(error, "error");
        toast.error(error.data.message);
      }
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      setFormError({});
      await QrCodeValidation.validate(form, {
        abortEarly: false,
      });
      const res = await api.addQrCode(form);
      if (res.data.status === "success") {
        setIsLoading(false);
        toast.success("Added Successfully");
        _setImage("");
        handleCloseModal();
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      if (isYupError(error)) {
        setFormError(parseYupError(error));
      } else {
        console.log(error, "error");
        toast.error(error.data.message);
      }

      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (updateData) {
      setForm({
        upi: updateData.upi,
        image: updateData.image,
      });
      _setImage(updateData.imagePath);
    }
  }, [updateData]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseModal}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitle id="scroll-dialog-title">
          {" "}
          <h4 className=" text-center">
            {updateData ? "Update QR Code" : "Add QR Code"}
          </h4>
        </DialogTitle>
        <DialogContent dividers={true}>
          <div className="w-full ">
            <div className="flex flex-col gap-2 py-6 px-6 ">
              <div className="relative w-full">
                <div className="w-full shadow-xl h-32 rounded-xl  overflow-hidden">
                  <img
                    className="w-full h-full object-cover"
                    src={_image ? _image : "/images/logo/logo.png"}
                    alt="user"
                  />

                  <input
                    type="file"
                    onChange={onImageChange}
                    name="profile"
                    id="profile"
                    hidden
                  />
                </div>
                <label
                  htmlFor="profile"
                  className="w-10 h-10 flex-center text-18 absolute cursor-pointer -right-2 -bottom-1 rounded-full bg-white shadow-lg"
                >
                  {" "}
                  {reactIcons.upload}
                </label>
              </div>
              {formError.image && (
                <div className="form-eror">{formError.image}</div>
              )}
              <div className="input_field mt-5">
                <label htmlFor="">Upi Id</label>
                <input
                  type="text"
                  placeholder="Please enter upi"
                  id="filter"
                  onChange={(e) => setForm({ ...form, upi: e.target.value })}
                  name="upi"
                  value={form.upi}
                />
              </div>
              {formError.upi && (
                <div className="form-eror">{formError.upi}</div>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          {updateData ? (
            <button
              type="button"
              onClick={handleUpdate}
              disabled={isLoading}
              className={`btn btn-primary min-w-[200px]  ${
                isLoading
                  ? "bg-red-500 border-red-500 opacity-50 disabled:pointer-events-none"
                  : undefined
              }`}
            >
              {isLoading ? "Loading" : "Update"}
            </button>
          ) : (
            <button
              type="button"
              onClick={handleSubmit}
              disabled={isLoading}
              className={`btn btn-primary min-w-[200px]  ${
                isLoading
                  ? "bg-red-500 border-red-500 opacity-50 disabled:pointer-events-none"
                  : undefined
              }`}
            >
              {isLoading ? "Loading" : "Submit"}
            </button>
          )}

          <button
            onClick={handleCloseModal}
            type="button"
            className="btn btn-danger min-w-[200px]"
          >
            Close
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddQrCode;
