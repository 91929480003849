import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import * as api from "../../redux/api";

const PartnerDetails = ({ handleClose, open, id }) => {
  const [partnerData, setPartnerData] = useState({});
  const handleCloseModal = () => {
    handleClose();
  };
  const getUserData = async (userId) => {
    try {
      const { status, data } = await api.getUser(userId);
      if (status >= 200 && status < 300) {
        setPartnerData(data);
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const blockUser = async (id, type) => {
    try {
      const { status, data } = await api.blockPartnerUser(id);
      if (status >= 200 && status < 300) {
        toast.success(
          type === "block" ? "Blocked Successfully" : "Unblocked Successfully"
        );
        handleCloseModal();
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (id) getUserData(id);
  }, [id]);
  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseModal}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitle id="scroll-dialog-title">
          {" "}
          <h4 className=" text-center">Partner Detail</h4>
        </DialogTitle>
        <DialogContent dividers={"paper"}>
          <div className="w-full ">
            <div className=" flex flex-col gap-1 py-6 px-6 ">
              <div className="flex items-center">
                <strong className="font-semibold min-w-[140px]">Name:</strong>
                <p>{partnerData?.partner?.fullname}</p>
              </div>
              <div className="flex items-center">
                <strong className="font-semibold min-w-[140px]">Email:</strong>
                <p>{partnerData?.partner?.email}</p>
              </div>
              <div className="flex items-center">
                <strong className="font-semibold min-w-[140px]">Mobile:</strong>
                <p>{partnerData?.partner?.mobile}</p>
              </div>
              <div className="flex items-center">
                <strong className="font-semibold min-w-[140px]">City:</strong>
                <p>{partnerData?.partner?.city || "N/A"}</p>
              </div>
              <div className="flex items-center">
                <strong className="font-semibold min-w-[140px]">State:</strong>
                <p>{partnerData?.partner?.state || "N/A"}</p>
              </div>
              <div className="flex items-center">
                <strong className="font-semibold min-w-[140px]">
                  Address:
                </strong>
                <p>{partnerData?.partner?.address || "N/A"}</p>
              </div>
              <div className="flex items-center">
                <strong className="font-semibold min-w-[140px]">
                  Whatsapp:
                </strong>
                <p>{partnerData?.partner?.whatsapp || "N/A"}</p>
              </div>
              <div className="flex items-center">
                <strong className="font-semibold min-w-[140px]">
                  Telegram:
                </strong>
                <p>{partnerData?.partner?.telegram || "N/A"}</p>
              </div>
              <div className="flex items-center">
                <strong className="font-semibold min-w-[140px]">
                  Wallet Address:
                </strong>
                {partnerData?.partnerWalletAddress !== "" ? (
                  <div className="flex gap-1 items-center">
                    <span className="text-xs lg:text-sm font-semibold">
                      {" "}
                      {partnerData?.partnerWalletAddress?.slice(0, 4) +
                        "..." +
                        partnerData?.partnerWalletAddress?.slice(-4)}
                    </span>
                    <a
                      className="underline hover:text-blue-500 text-blue-400 font-semibold text-xs  "
                      href={`https://polygonscan.com/address/${partnerData?.partnerWalletAddress}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View On Explorer
                    </a>
                  </div>
                ) : (
                  <p>N/A</p>
                )}
              </div>
              <div className="mt-4">
                {partnerData.bulkBuyStatus === "Active" ? (
                  <button
                    onClick={() => blockUser(partnerData.id, "block")}
                    className="btn text-white bg-red-500 "
                  >
                    Block
                  </button>
                ) : (
                  <button
                    onClick={() => blockUser(partnerData.id, "unblock")}
                    className="btn text-white bg-green-500 "
                  >
                    UnBlock
                  </button>
                )}
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            type="button"
            onClick={handleCloseModal}
            className={`btn btn-danger `}
          >
            Close
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PartnerDetails;
