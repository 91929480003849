import React, { useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { LinearProgress } from "@mui/material";
import CustomNoRowsOverlay from "../utils/styles";
import { useEffect } from "react";
import * as api from "../redux/api";
import { toast } from "react-toastify";
import { reactIcons } from "../utils/icons";
import AddCurrency from "../components/modals/AddCurrency";
import Spinner from "../components/loaders/Spinner";
import { chain } from "../utils/env";
const Configuration = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [isAddCurrencyOpen, setIsAddCurrencyOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [updateData, setUpdateData] = useState(null);
  const columns = [
    {
      field: "_id",
      headerName: "S.No.",
      type: "number",
      headerAlign: "center",
      align: "center",
      width: 80,
    },
    {
      field: "buy",
      headerName: "Buy",
      type: "number",
      flex: 2,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "sell",
      headerName: "Sell",
      type: "number",
      flex: 2,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "currency",
      headerName: "Currency",
      type: "number",
      flex: 2,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "currentRate",
      headerName: "Current Rate",
      type: "number",
      flex: 2,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "enhancer",
      headerName: "Enhancer",
      type: "number",
      flex: 2,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div className="flex gap-2 justify-center items-center">
            <div className="bg-transparent flex justify-center items-center outline-none border-none w-10 h-10 ">
              {params.row.enhancer}
            </div>
          </div>
        );
      },
    },
    {
      field: "platformFee",
      headerName: "Platform Fees",
      type: "number",
      flex: 3,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "agentCommission",
      headerName: "Agent Commission",
      type: "number",
      flex: 3,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "portalRate",
      headerName: "Portal Rate",
      type: "number",
      flex: 3,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      flex: 3,
      renderCell: (params) => {
        return (
          <div className="flex gap-2 justify-center items-center">
            <>
              <span
                role="button"
                onClick={() => {
                  setUpdateData(params.row);
                  setIsAddCurrencyOpen(true);
                }}
                className="w-10 h-10 flex-shrink-0 flex justify-center items-center rounded-md text-[18px] bg-gray-200 hover:bg-gray-300"
              >
                {reactIcons.edit}
              </span>
              <span
                role="button"
                onClick={() => handleDeleteCurrency(params.row.id)}
                className="w-10 h-10 flex-shrink-0 flex justify-center items-center rounded-md text-[18px] bg-red-200 text-red-500"
              >
                {reactIcons.delete}
              </span>
            </>
          </div>
        );
      },
    },
  ];
  const handleDeleteCurrency = async (id) => {
    setSpinnerLoading(true);
    try {
      const res = await api.deleteCurrency(id, { chain: chain });
      if (res.data.status === "success") {
        toast.success("Deleted Successfully");
        getCurrencyInfo();
        setSpinnerLoading(false);
      } else {
        setSpinnerLoading(false);
        toast.error("Error Occurred");
      }
    } catch (err) {
      setSpinnerLoading(false);
      console.log(err);
    }
  };
  const getCurrencyInfo = async () => {
    setIsLoading(true);
    const res = await api.getCurrency(0);
    const { status, data } = res;
    if (status >= 200 && status < 300) {
      setIsLoading(false);
      setRows(
        data.map((item, index) => {
          return {
            ...item,
            _id: index + 1,
          };
        })
      );
    } else {
      setIsLoading(false);
      toast.error(data.message);
    }
  };
  useEffect(() => {
    try {
      getCurrencyInfo();
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddCurrencyOpen === false]);

  return (
    <>
      {spinnerLoading && <Spinner />}
      <div>
        {/* tables */}

        <div className="w-full mt-10 data-grid">
          <div className="flex justify-between items-center mb-2">
            <h4 className=" ">Configuration</h4>
            <button
              onClick={() => setIsAddCurrencyOpen(true)}
              className="btn btn-primary"
            >
              Add Currency
            </button>
          </div>
          <div className=" h-[600px] w-full">
            <DataGrid
              loading={isLoading}
              rows={rows}
              getEstimatedRowHeight={() => 52}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 8,
                  },
                },
              }}
              pageSizeOptions={[8, 10, 15]}
              slots={{
                toolbar: GridToolbar,
                loadingOverlay: LinearProgress,
                noRowsOverlay: CustomNoRowsOverlay,
              }}
              disableColumnMenu
              disableRowSelectionOnClick
            />
          </div>
        </div>
      </div>
      <AddCurrency
        updateData={updateData}
        open={isAddCurrencyOpen}
        handleClose={() => {
          setIsAddCurrencyOpen(false);
          setUpdateData(null);
        }}
      />
    </>
  );
};

export default Configuration;
