import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { LinearProgress } from "@mui/material";
import CustomNoRowsOverlay from "../utils/styles";
import * as api from "../redux/api";

import { useNavigate, useParams } from "react-router-dom";
const LevelBonus = () => {
  const { level } = useParams();
  const navigate = useNavigate();
  const columns = [
    {
      field: "id",
      headerName: "S.No.",
      type: "number",
      headerAlign: "center",
      align: "center",
      width: 120,
    },

    {
      field: "name",
      headerName: "Full Name",
      type: "string",
      flex: 3,
      renderCell: (params) => {
        return (
          <span
            onClick={() =>
              navigate(
                `/referral-users/${level}/${params.row.userId}/${params.row.address}`
              )
            }
            className="cursor-pointer text-blue-500 text-xs lg:text-sm xl:text-base font-medium underline"
          >
            {params.row.name}
          </span>
        );
      },
    },
    {
      field: "noOfReferrals",
      headerName: "No Of Referrals",
      type: "number",
      flex: 3,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "totalEarning",
      headerName: "Total Earning",
      type: "number",
      flex: 2,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "address",
      headerName: "Address",
      type: "string",
      flex: 3,
    },
  ];
  const [rows, setRows] = useState([]);

  const getLevelBonusData = async (level) => {
    const response = await api.getLevelBonus(level);
    const formatedData = response.data.map((item, index) => {
      return { ...item, id: index + 1, userId: item.id };
    });
    setRows(formatedData);
  };
  useEffect(() => {
    try {
      getLevelBonusData(level);
    } catch (err) {
      console.log(err);
    }
  }, [level]);

  return (
    <div className="w-full mt-10 data-grid">
      <h4 className="flex items-center gap-2 mb-4">Level Bonus</h4>
      <div className=" h-[600px] w-full">
        <DataGrid
          rows={rows}
          // getRowId={(row) => row.userId}
          getEstimatedRowHeight={() => 52}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 8,
              },
            },
          }}
          pageSizeOptions={[8, 10, 15]}
          slots={{
            loadingOverlay: LinearProgress,
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          disableColumnMenu
          disableRowSelectionOnClick
        />
      </div>
    </div>
  );
};

export default LevelBonus;
