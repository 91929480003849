/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { LinearProgress, Tooltip } from "@mui/material";
import CustomNoRowsOverlay from "../utils/styles";
import { useEffect } from "react";
import * as api from "../redux/api";
import { toast } from "react-toastify";
import { reactIcons } from "../utils/icons";
import PurchaseRequestStatusChange from "../components/modals/PurchaseRequestStatusChange";
import TimeLine from "../components/modals/TimeLine";
import UserDetail from "../components/modals/UserDetail";
const RetailPurchaseRequest = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState("");
  const [isPurchaseRequestModal, setIsPurchaseRequestModal] = useState(false);
  const [statusType, setStatusType] = useState("Pending");
  const [isTimelineModalOpen, setIsTimelineModalOpen] = useState(false);
  const [timeLineData, setTimeLineData] = useState(null);
  const [id, setId] = useState(null);
  const [rows, setRows] = useState([]);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [userData, setUserData] = useState({});
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 8,
  });
  const [rowCountState, setRowCountState] = useState(0);
  const handleStatusChange = (e, id) => {
    setStatusType(e.target.value);
    setId(id);
    setIsPurchaseRequestModal(true);
  };
  const columns = [
    {
      field: "_id",
      headerName: "S.No.",
      type: "number",
      headerAlign: "center",
      align: "center",
      width: 80,
    },
    {
      field: "fullname",
      headerName: "Name",
      type: "string",
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "transactionDate",
      headerName: "Date",
      type: "dateTime",
      width: 200,
      valueGetter: ({ value }) => value && new Date(value),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "email",
      headerName: "Email",
      type: "string",
      flex: 3,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "mobile",
      headerName: "Mobile",
      type: "number",
      width: 120,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "currency",
      headerName: "Currency",
      type: "string",
      width: 80,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "fxf",
      headerName: "USDFc",
      type: "number",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "amount",
      headerName: "Amount",
      type: "number",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "country",
      headerName: "Country",
      type: "string",
      flex: 3,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "state",
      headerName: "State",
      type: "string",
      flex: 3,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "city",
      headerName: "City",
      type: "string",
      flex: 3,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: "Status",
      type: "string",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div className="flex gap-2 justify-center items-center">
            <>
              <div className="form-select-custom h-[40px]">
                <select
                  defaultValue={params.row.status}
                  name={params.row.partnerId}
                  id={params.row.partnerId}
                  value={params.row.status}
                  disabled={params.row.status === "Completed"}
                  className="h-[40px]"
                  onChange={(e) => handleStatusChange(e, params.row.id)}
                >
                  <option value={"Pending"} disabled={true}>
                    Pending
                  </option>
                  {["Processed", "Rejected", "Completed"].map((item) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
                </select>
                <span className="ay-center right-2 text-zinc-500 text-[22px] pointer-events-none ">
                  {reactIcons.arrowDown}
                </span>
              </div>
            </>
          </div>
        );
      },
    },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      width: 100,
      renderCell: (params) => {
        return (
          <div className="flex gap-2 justify-center items-center">
            <Tooltip
              title={<div className="tooltip-box">TimeLine</div>}
              enterTouchDelay={0}
            >
              <span
                onClick={() => {
                  setIsTimelineModalOpen(true);
                  setTimeLineData(params.row);
                }}
                role="button"
                className="w-10 h-10 flex-shrink-0 flex justify-center items-center rounded-md text-[18px] bg-gray-200 hover:bg-gray-300"
              >
                {reactIcons.timeline}
              </span>
            </Tooltip>
            <Tooltip
              title={<div className="tooltip-box">User Details</div>}
              enterTouchDelay={0}
            >
              <span
                onClick={() => {
                  setIsUserModalOpen(true);
                  setUserData(params.row);
                }}
                role="button"
                className="w-10 h-10 flex-shrink-0 flex justify-center items-center rounded-md text-[18px] bg-gray-200 hover:bg-gray-300"
              >
                {reactIcons.eyeVisible}
              </span>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const getPurchaseRequestInfo = async () => {
    setIsLoading(true);
    try {
      const res = await api.getPurchaseRequest(paginationModel.page, filter);
      const { status, data } = res;
      if (status >= 200 && status < 300) {
        setIsLoading(false);
        setRowCountState(data.count);
        setRows(
          data.data.map((item, index) => {
            return {
              ...item,
              fullname: item.user.fullname,
              email: item.user.email,
              _id: 8 * paginationModel.page + index + 1,
            };
          })
        );
      } else {
        setIsLoading(false);
        toast.error(data.message);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  useEffect(() => {
    getPurchaseRequestInfo();
    setRowCountState((prevRowCountState) =>
      rowCountState !== undefined ? rowCountState : prevRowCountState
    );
  }, [
    paginationModel,
    rowCountState,
    setRowCountState,
    isPurchaseRequestModal,
    filter,
  ]);

  return (
    <>
      <div>
        <div className="w-full mt-10 data-grid">
          <div className="flex justify-between items-center mb-2">
            <h4 className=" ">User Purchase Requests</h4>
            <div className="form-select-custom w-[250px] ml-auto ">
              <select
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              >
                <option value={""}>All</option>
                {["Pending", "Processed", "Rejected", "Completed"]?.map(
                  (item) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  )
                )}
              </select>
              <span className="ay-center right-2 text-zinc-500 text-[22px] pointer-events-none ">
                {reactIcons.arrowDown}
              </span>
            </div>
          </div>
          <div className="h-[600px] w-full overflow-auto newTable">
            <DataGrid
              loading={isLoading}
              rows={rows}
              getEstimatedRowHeight={() => 52}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 8,
                  },
                },
              }}
              rowCount={rowCountState}
              pageSizeOptions={[8]}
              paginationModel={paginationModel}
              paginationMode="server"
              onPaginationModelChange={setPaginationModel}
              slots={{
                toolbar: GridToolbar,
                loadingOverlay: LinearProgress,
                noRowsOverlay: CustomNoRowsOverlay,
              }}
              scroll={{ x: true }}
              disableColumnMenu
              disableRowSelectionOnClick
            />
          </div>
        </div>
      </div>
      <PurchaseRequestStatusChange
        open={isPurchaseRequestModal}
        statusType={statusType}
        handleClose={() => setIsPurchaseRequestModal(false)}
        id={id}
      />
      <TimeLine
        open={isTimelineModalOpen}
        handleClose={() => {
          setIsTimelineModalOpen(false);
        }}
        data={timeLineData}
        type={statusType}
      />
      <UserDetail
        open={isUserModalOpen}
        handleClose={() => setIsUserModalOpen(false)}
        data={userData}
      />
    </>
  );
};

export default RetailPurchaseRequest;
