import React, { useState } from "react";
import { FiAward } from "react-icons/fi";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { LinearProgress } from "@mui/material";
import CustomNoRowsOverlay from "./../utils/styles";
import { useEffect } from "react";
import * as api from "../redux/api";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getAuth } from "./../redux/features/authSlice";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";
import { reactIcons } from "../utils/icons";
const DashboardLanding = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [dashboardData, setDashboardData] = useState({});
  const user = useSelector((state) => state.auth.user);

  const columns = [
    {
      field: "id",
      headerName: "S.No.",
      type: "number",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "amount",
      headerName: "Amount",
      type: "number",
      headerAlign: "center",
      align: "center",
      flex: 2,
    },
    {
      field: "bonus",
      headerName: "IsAirDrop",
      type: "boolean",
      flex: 2,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "from",
      headerName: "From",
      flex: 3,
      type: "string",
    },
    {
      field: "to",
      headerName: "To",
      type: "string",
      flex: 3,
    },
    {
      field: "timestamp",
      headerName: "Date",
      type: "dateTime",
      flex: 3,
      valueGetter: ({ value }) => value && new Date(value),
    },
    {
      field: "status",
      headerName: "Status",
      type: "string",
      flex: 2,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "level",
      headerName: "Level",
      type: "number",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
  ];
  useEffect(() => {
    const getTransactionData = async () => {
      setIsLoading(true);
      const res = await api.getTransactions(0);
      const { status, data } = res;
      if (status >= 200 && status < 300) {
        setIsLoading(false);
        setDashboardData({
          totalAirdrop: data.totalAirdrop,
          totalSoldOutCoins: data.totalSoldOutCoins,
        });
        setRows(
          data.transactions
            .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
            .map((item, index) => {
              return { ...item, id: index + 1 };
            })
        );
      } else {
        setIsLoading(false);
        toast.error(data.message);
      }
    };
    try {
      getTransactionData();
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
    dispatch(getAuth());
  }, [dispatch]);

  const [selected, setSelected] = React.useState([]);
  // const [position, setPosition] = React.useState(0);

  const isItemSelected = (id) => !!selected.find((el) => el === id);

  const handleClick =
    (id) =>
    ({ getItemById, scrollToItem }) => {
      const itemSelected = isItemSelected(id);

      setSelected((currentSelected) =>
        itemSelected
          ? currentSelected.filter((el) => el !== id)
          : currentSelected.concat(id)
      );
    };
  function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } =
      React.useContext(VisibilityContext);

    return (
      <button
        className="w-10 h-10 z-10 cursor-pointer text-[22px] ay-center -left-4 bg-gray-200 hover:bg-gray-400 rounded-full flex-center"
        disabled={isFirstItemVisible}
        onClick={() => scrollPrev()}
      >
        {reactIcons.arrowleft}
      </button>
    );
  }

  function RightArrow() {
    const { isLastItemVisible, scrollNext } =
      React.useContext(VisibilityContext);

    return (
      <button
        className="w-10 h-10 z-10 cursor-pointer text-[22px] ay-center -right-4 bg-gray-200 hover:bg-gray-400 rounded-full flex-center"
        disabled={isLastItemVisible}
        onClick={() => scrollNext()}
      >
        {reactIcons.arrowright}
      </button>
    );
  }

  return (
    <div>
      <div className="upper flex flex-col gap-4">
        <h4 className="flex items-center gap-2">
          Dashboard <FiAward className="text-[22px] text-theme-darker" />{" "}
        </h4>
      </div>
      <div className=" mt-6 relative px-4">
        {/* <div className="p-3 xl:p-4 w-[250px] mr-4  shadow-lg border-2 border-grey-30 border-l-[6px] border-l-blue-dark rounded-lg flex items-center justify-between">
          <div className="space-y-1">
            <h6 className="text-theme-text2 mb-2 text-sm xl:text-[16px]">
              Coins Soldout
            </h6>
            <div className="flex gap-2">
              <span className="xl:text-base text-sm font-bold">
                {dashboardData.totalSoldOutCoins
                  ? Number(dashboardData.totalSoldOutCoins).toFixed(2)
                  : 0}
              </span>{" "}
            </div>
          </div>
        </div> */}
        <ScrollMenu
          scrollContainerClassName="overflow-x-hidden "
          LeftArrow={LeftArrow}
          RightArrow={RightArrow}
        >
          <div
            onClick={handleClick(0)}
            selected={isItemSelected(0)}
            className="p-3 xl:p-4 w-[250px] mr-4  shadow-lg border-2 border-grey-30 border-l-[6px] border-l-theme-darker rounded-lg flex items-center justify-between gap-2"
          >
            <div className="space-y-1 flex-1">
              <h6 className="text-theme-text2 mb-2 text-sm xl:text-[16px]">
                Coins Airdrop Bonus
              </h6>
              <div className="flex ">
                <span className="xl:text-base text-sm font-bold">
                  {" "}
                  {dashboardData.totalAirdrop || 0}
                </span>{" "}
              </div>
            </div>
          </div>
          <div
            onClick={handleClick(1)}
            selected={isItemSelected(1)}
            className="p-3 xl:p-4 w-[250px] mr-4  shadow-lg border-2 border-grey-30 border-l-[6px] border-l-yellow-dark rounded-lg flex items-center justify-between gap-2"
          >
            <div className="space-y-1 flex-1">
              <h6 className="text-theme-text2 mb-2 text-sm xl:text-[16px]">
                Market cap
              </h6>
              <div className="flex ">
                <span className="xl:text-base text-sm font-bold">N/A</span>{" "}
              </div>
            </div>
          </div>
          <div
            onClick={handleClick(2)}
            selected={isItemSelected(2)}
            className="p-3 xl:p-4 w-[250px] mr-4  shadow-lg border-2 border-grey-30 border-l-[6px] border-l-green-dark rounded-lg flex items-center justify-between gap-2"
          >
            <div className="space-y-1 flex-1">
              <h6 className="text-theme-text2 mb-2 text-sm xl:text-[16px]">
                Volume (24h)
              </h6>
              <div className="flex ">
                <span className="xl:text-base text-sm font-bold">N/A</span>{" "}
              </div>
            </div>
          </div>
          <div
            onClick={handleClick(3)}
            selected={isItemSelected(3)}
            className="p-3 xl:p-4 w-[250px] mr-4  shadow-lg border-2 border-grey-30 border-l-[6px] border-l-pink-500 rounded-lg flex items-center justify-between gap-2"
          >
            <div className="space-y-1 flex-1">
              <h6 className="text-theme-text2 mb-2 text-sm xl:text-[16px]">
                Circulating supply
              </h6>
              <div className="flex ">
                <span className="xl:text-base text-sm font-bold">N/A</span>{" "}
              </div>
            </div>
          </div>
          <div
            onClick={handleClick(4)}
            selected={isItemSelected(4)}
            className="p-3 xl:p-4 w-[250px] mr-4  shadow-lg border-2 border-grey-30 border-l-[6px] border-l-gray-500 rounded-lg flex items-center justify-between gap-2"
          >
            <div className="space-y-1 flex-1">
              <h6 className="text-theme-text2 mb-2 text-sm xl:text-[16px]">
                Total supply
              </h6>
              <div className="flex ">
                <span className="xl:text-base text-sm font-bold">N/A</span>{" "}
              </div>
            </div>
          </div>
          <div
            onClick={handleClick(5)}
            selected={isItemSelected(5)}
            className="p-3 xl:p-4 w-[250px] mr-4  shadow-lg border-2 border-grey-30 border-l-[6px] border-l-fuchsia-500 rounded-lg flex items-center justify-between gap-2"
          >
            <div className="space-y-1 flex-1">
              <h6 className="text-theme-text2 mb-2 text-sm xl:text-[16px]">
                Fully diluted market cap
              </h6>
              <div className="flex ">
                <span className="xl:text-base text-sm font-bold">N/A</span>{" "}
              </div>
            </div>
          </div>
        </ScrollMenu>
      </div>

      {/* tables */}
      {user?.privileges?.includes("ReadTransactions") && (
        <div className="w-full mt-10 data-grid">
          <h4 className="flex items-center gap-2 mb-2">Transactions</h4>
          <div className=" h-[600px] w-full">
            <DataGrid
              loading={isLoading}
              rows={rows}
              getEstimatedRowHeight={() => 52}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 8,
                  },
                },
              }}
              pageSizeOptions={[8, 10, 15]}
              slots={{
                toolbar: GridToolbar,
                loadingOverlay: LinearProgress,
                noRowsOverlay: CustomNoRowsOverlay,
              }}
              disableColumnMenu
              disableRowSelectionOnClick
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardLanding;
