import moment from "moment";

export const renderSidebar = (sideBarLinks, user) => {
  let tempSidebar = sideBarLinks.filter(
    ({ key }) =>
      user?.privileges.includes(key) || key === "" || key === user.type
  );
  return tempSidebar;
};
export const getTimeLeft = (expirationTime) => {
  const futureDate = moment(expirationTime);
  const currentTime = moment();
  const duration = moment.duration(futureDate.diff(currentTime));

  const daysLeft = Math.floor(duration.asDays());
  const hoursLeft = Math.floor(duration.asHours());
  const minutesLeft = Math.floor(duration.asMinutes());
  const secondsLeft = Math.floor(duration.asSeconds());

  if (secondsLeft <= 0) {
    return "Expired";
  } else if (daysLeft > 0) {
    return `${daysLeft} days left`;
  } else if (hoursLeft > 0) {
    return `${hoursLeft} hours left`;
  } else if (minutesLeft > 0) {
    return `${minutesLeft} minutes left`;
  } else {
    return `${secondsLeft} seconds left`;
  }
};
