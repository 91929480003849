import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { isYupError, parseYupError } from "../../utils/Yup";
import { currencyValidation } from "../../utils/validation";
import { toast } from "react-toastify";
import * as api from "./../../redux/api";
import { chain } from "../../utils/env";
const initialState = {
  currency: "",
  agentCommission: "",
  currentRate: "",
  enhancer: "",
  platformFee: "",
  chain: chain,
};
const AddCurrency = ({ handleClose, open, updateData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState(initialState);
  const handleCloseModal = () => {
    handleClose();
    setForm(initialState);
    setFormError({});
  };
  const [formError, setFormError] = useState({});
  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      setFormError({});
      await currencyValidation.validate(form, {
        abortEarly: false,
      });
      let formData = {
        ...form,
        enhancer: Number(form.enhancer),
        platformFee: Number(form.platformFee),
      };

      if (updateData) {
        const res = await api.updateEnhancer(updateData.id, {
          enhancer: Number(form.enhancer),
          platformFee: Number(form.platformFee),
          currentRate: form.currentRate,
          agentCommission: Number(form.agentCommission),
          chain: chain,
        });
        const { status, data } = res;
        if (status >= 200 && status < 300) {
          setIsLoading(false);
          handleCloseModal();
          toast.success("Updated Successfully");
        } else {
          setIsLoading(false);
          toast.error(data.message);
        }
      } else {
        const res = await api.addCurrency(formData);
        if (res.data.status === "success") {
          setIsLoading(false);
          toast.success("Added Successfully");
          handleCloseModal();
        } else {
          setIsLoading(false);
        }
      }
    } catch (error) {
      if (isYupError(error)) {
        setFormError(parseYupError(error));
      } else {
        console.log(error, "error");
        toast.error(error.response.data.message);
      }
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm({
      ...form,
      [name]: value,
    });
    setFormError({
      ...formError,
      [name]: "",
    });
  };
  const handleCurrencyChange = async (e) => {
    const { name, value } = e.target;
    if (value === "") {
      setForm({
        ...form,
        [name]: "",
        currentRate: "",
      });
      return;
    }
    setForm({
      ...form,
      [name]: value.toUpperCase(),
    });
    setFormError({
      ...formError,
      [name]: "",
    });
    if (e.keyCode === 13 || e.type === "blur") {
      setIsLoading(true);
      try {
        const res = await api.getCurrencyPrice(value);
        const { status, data } = res;
        if (status >= 200 && status < 300) {
          Object.entries(data).forEach(([key, newvalue]) => {
            setForm((prev) => ({
              ...prev,
              currentRate: newvalue,
            }));
            setFormError((prev) => ({
              ...prev,
              currentRate: "",
            }));
          });
          setIsLoading(false);
        } else {
          toast.error(data.message);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        toast.error(error.response.data.message);
      }
    }
  };

  useEffect(() => {
    if (updateData) {
      setForm({
        currency: updateData.currency,
        currentRate: updateData.currentRate,
        enhancer: updateData.enhancer,
        platformFee: updateData.platformFee,
        agentCommission: updateData.agentCommission,
      });
    }
  }, [updateData]);
  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseModal}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitle id="scroll-dialog-title">
          {" "}
          <h4 className=" text-center">Add new currency</h4>
        </DialogTitle>
        <DialogContent dividers={"paper"}>
          <div className="   w-full ">
            <div className=" flex flex-col gap-2 py-6 px-6 ">
              <div className="input_field">
                <label htmlFor="">Currency Name</label>
                <input
                  readOnly={updateData ? true : false}
                  type="text"
                  placeholder="valid currency name eg. INR, USD, EUR"
                  name="currency"
                  onChange={handleCurrencyChange}
                  value={form.currency}
                  onKeyDown={handleCurrencyChange}
                  onBlur={handleCurrencyChange}
                />
                {formError.currency && (
                  <div className="form-eror">{formError.currency}</div>
                )}
              </div>
              <div className="input_field">
                <label htmlFor="">Current Rate</label>
                <input
                  type="text"
                  placeholder="current Rate in decimal eg. 10, 11 "
                  name="currentRate"
                  onChange={handleChange}
                  value={form.currentRate}
                />
                {formError.currentRate && (
                  <div className="form-eror">{formError.currentRate}</div>
                )}
              </div>
              <div className="input_field">
                <label htmlFor="">Enhancer</label>
                <input
                  type="number"
                  placeholder="enhancer in decimal eg. 1, 2.5"
                  name="enhancer"
                  onChange={handleChange}
                  value={form.enhancer}
                />
                {formError.enhancer && (
                  <div className="form-eror">{formError.enhancer}</div>
                )}
              </div>
              <div className="input_field">
                <label htmlFor="">Platform Fee (%)</label>
                <input
                  type="number"
                  placeholder="platform fees in decimal eg. 3, 4.5"
                  name="platformFee"
                  onChange={handleChange}
                  value={form.platformFee}
                />
                {formError.platformFee && (
                  <div className="form-eror">{formError.platformFee}</div>
                )}
              </div>
              <div className="input_field">
                <label htmlFor="">Agent Commission (%)</label>
                <input
                  type="number"
                  placeholder="
              commission in decimal eg. 3, 4.5"
                  name="agentCommission"
                  onChange={handleChange}
                  value={form.agentCommission}
                />
                {formError.agentCommission && (
                  <div className="form-eror">{formError.agentCommission}</div>
                )}
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            type="button"
            onClick={handleSubmit}
            disabled={isLoading}
            className={`btn btn-primary min-w-[200px]  ${
              isLoading
                ? "bg-red-500 border-red-500 opacity-50 disabled:pointer-events-none"
                : undefined
            }`}
          >
            {isLoading ? "Loading" : "Submit"}
          </button>
          <button
            onClick={handleCloseModal}
            type="button"
            className="btn btn-danger min-w-[200px]"
          >
            Close
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddCurrency;
