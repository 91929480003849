import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";

const TransactionAmountDetails = ({
  handleClose,
  open,
  data,
  setIsTimelineModalOpen,
}) => {
  const handleCloseModal = () => {
    handleClose();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseModal}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitle id="scroll-dialog-title">
          {" "}
          <h4 className=" text-center">User Details </h4>
        </DialogTitle>
        <DialogContent dividers={"paper"}>
          <div className="w-full flex flex-col gap-2  px-6">
            <div className="flex items-center">
              <strong className="font-semibold min-w-[140px]">Amount:</strong>{" "}
              <p>{data?.amount}</p>
            </div>

            <div className="flex items-center">
              <strong className="font-semibold min-w-[140px]">
                Conversion:
              </strong>{" "}
              <p>{data?.fxf}</p>
            </div>
            <div className="flex items-center">
              <strong className="font-semibold min-w-[140px]">
                Current Rate:
              </strong>{" "}
              <p>{data?.currentRate}</p>
            </div>

            {data?.currency !== "USDT" && (
              <div>
                <div className="flex items-center">
                  <strong className="font-semibold min-w-[140px]">
                    UTR Number:
                  </strong>{" "}
                  <p>{data?.utrCode ? data?.utrCode : "N/A"}</p>
                </div>

                <div className="flex items-center">
                  <strong className="font-semibold min-w-[140px]">
                    Currrent UPI:
                  </strong>{" "}
                  <p>{data?.platformUpiId ? data?.platformUpiId : "N/A"}</p>
                </div>

                <div className="flex items-center">
                  <strong className="font-semibold min-w-[140px]">
                    Inserted Amount:&nbsp;
                  </strong>
                  <p>
                    {data?.transactionAmount ? data?.transactionAmount : "N/A"}
                  </p>
                </div>
                <div className="flex items-center">
                  <strong className="font-semibold min-w-[140px]">
                    Account Number:&nbsp;
                  </strong>
                  <p>{data?.accountNumber ? data?.accountNumber : "N/A"}</p>
                </div>
                <div className="flex items-center">
                  <strong className="font-semibold min-w-[140px]">
                    Account Holder:
                  </strong>{" "}
                  <p>{data?.accountHolder ? data?.accountHolder : "N/A"}</p>
                </div>
                <div className="flex items-center">
                  <strong className="font-semibold min-w-[140px]">
                    Transaction Id:
                  </strong>{" "}
                  <p>{data?.transactionId ? data?.transactionId : "N/A"}</p>
                </div>
                <div className="flex items-center">
                  <strong className="font-semibold min-w-[140px]">
                    Screenshot:
                  </strong>{" "}
                  <a
                    href={data?.screenshotUrl}
                    target="_blank"
                    rel="noreferrer"
                    className="underline text-[#0000FF]"
                  >
                    {data?.screenshotUrl}
                  </a>
                </div>
              </div>
            )}

            <div className="flex items-center">
              <strong
                onClick={() => setIsTimelineModalOpen(true)}
                className="font-semibold min-w-[140px] underline cursor-pointer text-blue-500"
              >
                Notes:
              </strong>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            type="button"
            onClick={handleCloseModal}
            className={`btn btn-danger `}
          >
            Close
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TransactionAmountDetails;
