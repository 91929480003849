import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as api from "../../redux/api";
import { AssignPartnerValidation } from "../../utils/validation";
import { isYupError, parseYupError } from "../../utils/Yup";
const initialState = {
  requestId: "",
  partnerId: "",
};

const AssignPartner = ({ handleClose, open, data, rejectedData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [partnersList, setPartnerList] = useState([]);
  const [form, setForm] = useState(initialState);
  const [formError, setFormError] = useState({});
  const handleCloseModal = () => {
    handleClose();
    setForm(initialState);
    setFormError({});
  };

  const getPartnersData = async (latitude, longitude) => {
    setIsLoading(true);
    const response = await api.getPartnersList(latitude, longitude);
    const { status, data } = response;
    if (status >= 200 && status < 300) {
      setPartnerList(data?.data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      toast.error(data.message);
    }
  };
  console.log(data, "data");
  useEffect(() => {
    if (data.id) {
      setForm((prevForm) => ({
        ...prevForm,
        requestId: data.id,
      }));
    }
  }, [data.id]);

  useEffect(() => {
    if (data?.latitude && data?.longitude) {
      try {
        getPartnersData(data?.latitude, data?.longitude);
      } catch (err) {
        setIsLoading(false);
        console.log(err);
      }
    }
  }, [data?.latitude, data?.longitude]);

  const handleSubmit = async (e) => {
    console.log("call");
    setIsLoading(true);
    e.preventDefault();
    try {
      setFormError({});
      await AssignPartnerValidation.validate(form, {
        abortEarly: false,
      });
      const res = await api.addtoPartner(form);
      console.log(res, "res");
      if (res.data.status === "success") {
        setIsLoading(false);
        toast.success("Assign Successfully");
        handleCloseModal();
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      if (isYupError(error)) {
        setFormError(parseYupError(error));
        setIsLoading(false);
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        console.log(error, "error");
        toast.error(error.message);
        setIsLoading(false);
      }

      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      partnerId: e.target.value,
    });
  };
  console.log(form, "form");
  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseModal}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitle id="scroll-dialog-title">
          {" "}
          <h4 className=" text-center">
            {/* {updateData ? "Update QR Code" : "Add QR Code"} */}
            Assign partner
          </h4>
        </DialogTitle>
        <DialogContent dividers={true}>
          <div className="w-full ">
            <div className="flex flex-col gap-2 py-6 px-6 ">
              <div className="input_field mt-5">
                <label htmlFor="">Assign Partner</label>
                <select onChange={handleChange} name="partnerId">
                  <option value={""}>Select to assign</option>
                  {partnersList.map((item, index) => (
                    <option className="text-black" key={index} value={item.id}>
                      {item.fullname}
                    </option>
                  ))}
                </select>
              </div>
              {formError.partnerId && (
                <div className="form-eror">{formError.partnerId}</div>
              )}
            </div>

            <div className="relative overflow-x-auto">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                <caption className="py-3 px-0 text-lg font-semibold text-left text-gray-900 bg-white">
                  Rejected partner's
                </caption>
                <thead className="text-12 text-gray-700 bg-gray-50">
                  <tr>
                    <th scope="col" className="px-3 py-1 text-12">
                      Name
                    </th>
                    <th scope="col" className="px-3 py-1 text-12">
                      Number
                    </th>
                    <th scope="col" className="px-3 py-1 text-12">
                      Address
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rejectedData?.length > 0 ? (
                    rejectedData.map((item, index) => (
                      <tr className="bg-white border-b text-12" key={index}>
                        <th
                          scope="row"
                          className="px-3 py-1 text-12 font-medium text-gray-900 whitespace-nowrap"
                        >
                          {item.partner.fullname}
                        </th>
                        <td className="px-3 py-1 text-12">
                          {item.partner.mobile}
                        </td>
                        <td className="px-3 py-1 text-12">
                          {item.partner.address}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="bg-white">
                      <td className="px-3 py-1 text-center text-12" colSpan={3}>
                        No Data Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            type="button"
            onClick={handleSubmit}
            disabled={isLoading}
            className={`btn btn-primary min-w-[200px]  ${
              isLoading
                ? "bg-red-500 border-red-500 opacity-50 disabled:pointer-events-none"
                : undefined
            }`}
          >
            {isLoading ? "Loading" : "Submit"}
          </button>

          <button
            onClick={handleCloseModal}
            type="button"
            className="btn btn-danger min-w-[200px]"
          >
            Close
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AssignPartner;
