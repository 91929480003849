import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import moment from "moment/moment";
import React from "react";

const TimeLine = ({ handleClose, open, data, type }) => {
  const handleCloseModal = () => {
    handleClose();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseModal}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitle id="scroll-dialog-title">
          {" "}
          <h4 className=" text-center">TimeLine </h4>
        </DialogTitle>
        <DialogContent dividers={"paper"}>
          <div className="w-full flex flex-col  px-6">
            {(type ? data?.timeline : data?.bulkPurchaseTimeline)?.map(
              (item) => (
                <div className="flex flex-col gap-2 relative pl-2 ">
                  <div className="absolute top-0 -left-[15px] w-4 h-4 bg-red-400 rounded-full"></div>
                  <div className="absolute top-0 -left-2 w-[2px] h-full bg-red-400 rounded-full"></div>
                  <div className="flex relative items-center gap-4 ">
                    <div className="leading-[1] text-sm">
                      {moment(item?.createdAt).format("DD MMM YYYY hh:mm A")}
                    </div>
                    <div className="leading-[1] font-semibold">
                      {item?.status}
                    </div>
                  </div>
                  <div className="text-sm mb-6">{item?.narration}</div>
                </div>
              )
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <button
            type="button"
            onClick={handleCloseModal}
            className={`btn btn-danger `}
          >
            Close
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TimeLine;
