import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React from 'react';
import { reactIcons } from '../../utils/icons';
import { handleAcceptKYCRequest, handleRejectKYCRequest } from '../../helpers/request';

const MerchantKycDetails = ({
  handleClose,
  open,
  kycItem,
  refresher
}) => {

  const handleCloseModal = () => {
    handleClose();
  };

  const handleApproved = async () => {
    console.log(kycItem.id, 'kycItem app')
    handleAcceptKYCRequest(kycItem.id)
    await refresher()
    handleClose()
  }

  const handleReject = async() => {
    console.log(kycItem.id)
    handleRejectKYCRequest(kycItem.id)
    await refresher()
    handleClose()
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseModal}
        scroll={'paper'}
        fullWidth={true}
        maxWidth={'md'}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitle id="scroll-dialog-title" className='relative'>
          {' '}
          <h4 className="text-center">Merchant Details</h4>
          <span className='absolute top-3 right-3 text-[20px] cursor-pointer' onClick={handleClose}>{reactIcons.close}</span>
        </DialogTitle>
        <DialogContent dividers={'paper'}>
          <div className="w-full">
            <div className='mb-5'>
                <div className='flex items-center gap-3 mb-3 '>
                    <span>{reactIcons.user}</span>
                    <h4 className='text-[18] md:text-[20px] font-medium'>Personal Details</h4>
                </div>
                <ul className='details-holder list-inside list-image-[url(/images/correct.png)]'>
                  <li className='flex pl-7 mb-2'>
                        <p className='text-[16px] min-w-[200px]'>Profile Image</p>
                        <img src={kycItem.profileImage ?? 'https://images.app.goo.gl/aLFNKV6mFWpcugRV8'} className='w-[75px] h-[75px] border' alt='Profile' />
                    </li>
                    <li className='flex pl-7 mb-2'>
                        <p className='text-[16px] min-w-[200px]'>Full Name</p>
                        <p className='text-[16px]'>{kycItem.fullname}</p>
                    </li>
                    <li className='flex pl-7 mb-2'>
                        <p className='text-[16px] min-w-[200px]'>Date Of Birth</p>
                        <p className='text-[16px]'>{kycItem.dateOfBirth}</p>
                    </li>
                    <li className='flex pl-7 mb-2'>
                        <p className='text-[16px] min-w-[200px]'>Email</p>
                        <p className='text-[16px]'>{kycItem.email}</p>
                    </li>
                    <li className='flex pl-7 mb-2'>
                        <p className='text-[16px] min-w-[200px]'>Platform Name</p>
                        <p className='text-[16px]'>{kycItem.platformName}</p>
                    </li>
                    <li className='flex pl-7 mb-2'>
                        <p className='text-[16px] min-w-[200px]'>Purpose</p>
                        <p className='text-[16px]'>{kycItem.purpose ?? 'N/A'}</p>
                    </li>
                    <li className='flex pl-7'>
                        <p className='text-[16px] min-w-[200px]'>Role</p>
                        <p className='text-[16px]'>{kycItem.role ?? 'N/A'}</p>
                    </li>
                </ul>
            </div>

            <div className='mb-5'>
                <div className='flex items-center gap-3 mb-3 '>
                    <span>{reactIcons.location}</span>
                    <h4 className='text-[18] md:text-[20px] font-medium'>Address Details</h4>
                </div>
                <ul className='details-holder list-inside list-image-[url(/images/correct.png)]'>
                    <li className='flex pl-7 mb-2'>
                        <p className='text-[16px] min-w-[200px]'>Country</p>
                        <p className='text-[16px]'>{kycItem.country}</p>
                    </li>
                    <li className='flex pl-7 mb-2'>
                        <p className='text-[16px] min-w-[200px]'>State</p>
                        <p className='text-[16px]'>{kycItem.state}</p>
                    </li>
                    <li className='flex pl-7 mb-2'>
                        <p className='text-[16px] min-w-[200px]'>City</p>
                        <p className='text-[16px]'>{kycItem.city}</p>
                    </li>
                    <li className='flex pl-7 mb-2'>
                        <p className='text-[16px] min-w-[200px]'>Street</p>
                        <p className='text-[16px]'>{kycItem.street}</p>
                    </li>
                </ul>
            </div>

            <div className='mb-5'>
                <div className='flex items-center gap-3 mb-3 '>
                    <span>{reactIcons.location}</span>
                    <h4 className='text-[18] md:text-[20px] font-medium'>Documents Details</h4>
                </div>
                <ul className='details-holder list-inside list-image-[url(/images/correct.png)]'>
                    <li className='flex pl-7 mb-2'>
                        <p className='text-[16px] min-w-[200px]'>Address Proof</p>
                        <img src={kycItem.addressProofImage ?? 'https://images.app.goo.gl/dyhWkLaceHgz9MSY8'} className='w-[75px] h-[75px] border' alt='address proof' />
                    </li>
                    <li className='flex pl-7 mb-2'>
                        <p className='text-[16px] min-w-[200px]'>Identity Proof (Front)</p>
                        <img src={kycItem.idFrontImage ?? 'https://images.app.goo.gl/dyhWkLaceHgz9MSY8'} className='w-[75px] h-[75px] border' alt='address proof' />
                    </li>
                    <li className='flex pl-7 mb-2'>
                        <p className='text-[16px] min-w-[200px]'>Identity Proof (Back)</p>
                        <img src={kycItem.idBackImage ?? 'https://images.app.goo.gl/dyhWkLaceHgz9MSY8'} className='w-[75px] h-[75px] border' alt='address proof' />
                    </li>
                </ul>
            </div>

          </div>
        </DialogContent>
        {
          kycItem.status == "Pending" &&
        <DialogActions>
          <button
            type="button"
            onClick={handleApproved}
            className='btn btn-primary min-w-[200]'
          >
            Approved
          </button>
          <button
            onClick={handleReject}
            type="button"
            className="btn btn-danger"
          >
            Reject
          </button>
        </DialogActions>
        }
      </Dialog>
    </>
  );
};

export default MerchantKycDetails;
