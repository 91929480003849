import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { isYupError, parseYupError } from "../../utils/Yup";
import { partnerValidation } from "../../utils/validation";
import { toast } from "react-toastify";
import * as api from "../../redux/api";
import { reactIcons } from "../../utils/icons";
import { Country, State, City } from "country-state-city";
const initialState = {
  email: "",
  fullname: "",
  mobile: "",
  city: "",
  country: "",
  state: "",
  timeZone: "",
  telegram: "",
  whatsapp: "",
};
const EditPartner = ({ handleClose, open, data }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState(initialState);
  const handleCloseModal = () => {
    handleClose();
    setForm(initialState);
    setFormError({});
  };
  const [formError, setFormError] = useState({});
  const handleSubmit = async (e) => {
    setIsLoading(true);
    try {
      setFormError({});
      await partnerValidation.validate(form, {
        abortEarly: false,
      });

      const res = await api.updatePartnerDetails(data?.partner.id, form);
      console.log(res, "res");
      if (res.data.status === "success") {
        setIsLoading(false);
        toast.success("Updated Successfully");
        handleCloseModal();
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      if (isYupError(error)) {
        setFormError(parseYupError(error));
      } else {
        console.log(error, "error");
        toast.error(error.response.data.message);
      }
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
    setFormError({
      ...formError,
      [name]: "",
    });
  };

  useEffect(() => {
    if (data) {
      setForm({
        city: data?.partner?.city || "",
        country: data?.partner?.country || "",
        state: data?.partner?.state || "",
        timeZone: data?.partner?.timeZone || "",
        email: data?.partner?.email || "",
        fullname: data?.partner?.fullname || "",
        mobile: data?.partner?.mobile || "",
        telegram: data?.partner?.telegram || "",
        whatsapp: data?.partner?.whatsapp || "",
      });
    }
  }, [data]);
  const allCountries = Country.getAllCountries();
  const countryCode = form.country
    ? allCountries.find((item) => item.name === form.country)?.isoCode
    : "";
  const allStates = countryCode ? State.getStatesOfCountry(countryCode) : [];
  const stateCode = form.state
    ? allStates.find((item) => item.name === form.state)?.isoCode
    : "";
  const allCities = stateCode
    ? City.getCitiesOfState(countryCode, stateCode)
    : [];
  let allTimeZones = form.country
    ? allCountries.find((item) => item.name === form.country)?.timezones
    : [];
  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseModal}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitle id="scroll-dialog-title">
          {" "}
          <h4 className=" text-center">Edit Partner Details</h4>
        </DialogTitle>
        <DialogContent dividers={"paper"}>
          <div className="   w-full ">
            <div className=" flex flex-col gap-2 py-6 px-6 ">
              <div className="input_field">
                <label htmlFor="">Full Name</label>
                <input
                  type="text"
                  placeholder="Full Name "
                  name="fullname"
                  onChange={handleChange}
                  value={form.fullname}
                />
                {formError.fullname && (
                  <div className="form-eror">{formError.fullname}</div>
                )}
              </div>
              <div className="input_field">
                <label htmlFor="">Email</label>
                <input
                  type="text"
                  placeholder="Email"
                  name="email"
                  onChange={handleChange}
                  value={form.email}
                />
                {formError.email && (
                  <div className="form-eror">{formError.email}</div>
                )}
              </div>
              <div className="input_field">
                <label htmlFor="">Mobile</label>
                <input
                  type="number"
                  placeholder="Mobile No."
                  name="mobile"
                  onChange={handleChange}
                  value={form.mobile}
                />
                {formError.mobile && (
                  <div className="form-eror">{formError.mobile}</div>
                )}
              </div>
              <div className="input_field">
                <label htmlFor="">Country</label>
                <div className="form-select-custom">
                  <select
                    className=" w-full"
                    name="country"
                    value={form?.country}
                    onChange={handleChange}
                  >
                    <option value="">Select Country</option>
                    {allCountries.map((option) => {
                      return (
                        <option key={option.name} value={option.name}>
                          {option.name}
                        </option>
                      );
                    })}
                  </select>
                  <span className="ay-center right-2 text-zinc-500 text-[28px] pointer-events-none ">
                    {reactIcons.arrowDown}
                  </span>
                </div>
                {formError.country && (
                  <div className="form-eror">{formError.country}</div>
                )}
              </div>
              <div className="input_field">
                <label htmlFor="">State</label>
                <div className="form-select-custom">
                  <select
                    className=" w-full"
                    name="state"
                    value={form?.state}
                    onChange={handleChange}
                  >
                    <option value="">Select state</option>
                    {allStates.map((option, index) => {
                      return (
                        <option key={index} value={option.name}>
                          {option.name}
                        </option>
                      );
                    })}
                  </select>
                  <span className="ay-center right-2 text-zinc-500 text-[28px] pointer-events-none ">
                    {reactIcons.arrowDown}
                  </span>
                </div>
                {formError.state && (
                  <div className="form-eror">{formError.state}</div>
                )}
              </div>
              <div className="input_field">
                <label htmlFor="">City</label>
                <div className="form-select-custom">
                  <select
                    className=" w-full"
                    name="city"
                    value={form?.city}
                    onChange={handleChange}
                  >
                    <option value="">Select city</option>
                    {allCities.map((option, index) => {
                      return (
                        <option key={index} value={option.name}>
                          {option.name}
                        </option>
                      );
                    })}
                  </select>
                  <span className="ay-center right-2 text-zinc-500 text-[28px] pointer-events-none ">
                    {reactIcons.arrowDown}
                  </span>
                </div>
                {formError.city && (
                  <div className="form-eror">{formError.city}</div>
                )}
              </div>
              <div className="input_field">
                <label htmlFor="">TimeZones</label>
                <div className="form-select-custom">
                  <select
                    className=" w-full"
                    name="timeZone"
                    value={form?.timeZone}
                    onChange={handleChange}
                    defaultValue={form?.timeZone}
                  >
                    <option value="">Select timeZone</option>
                    {allTimeZones.map((option, index) => {
                      return (
                        <option key={index} value={option.gmtOffsetName}>
                          {option.zoneName} {option.gmtOffsetName}
                        </option>
                      );
                    })}
                  </select>
                  <span className="ay-center right-2 text-zinc-500 text-[28px] pointer-events-none ">
                    {reactIcons.arrowDown}
                  </span>
                </div>
                {formError.timeZone && (
                  <div className="form-eror">{formError.timeZone}</div>
                )}
              </div>
              <div className="input_field">
                <label htmlFor="">Whatsapp</label>
                <input
                  type="number"
                  placeholder="Whatsapp No."
                  name="whatsapp"
                  onChange={handleChange}
                  value={form.whatsapp}
                />
                {formError.whatsapp && (
                  <div className="form-eror">{formError.whatsapp}</div>
                )}
              </div>
              <div className="input_field">
                <label htmlFor="">Telegram</label>
                <input
                  type="text"
                  placeholder="Telegram Id"
                  name="telegram"
                  onChange={handleChange}
                  value={form.telegram}
                />
                {formError.telegram && (
                  <div className="form-eror">{formError.telegram}</div>
                )}
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            type="button"
            onClick={handleSubmit}
            disabled={isLoading}
            className={`btn btn-primary min-w-[200px]  ${
              isLoading
                ? "bg-red-500 border-red-500 opacity-50 disabled:pointer-events-none"
                : undefined
            }`}
          >
            {isLoading ? "Loading" : "Submit"}
          </button>
          <button
            onClick={handleCloseModal}
            type="button"
            className="btn btn-danger min-w-[200px]"
          >
            Close
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditPartner;
