import { Routes, Route, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import "animate.css";
import { Fragment, useEffect, useState } from "react";

import PrivateRoute from "./PrivateRoute";
import CreateSubAdmin from "./pages/CreateSubAdmin";
import { getAuth } from "./redux/features/authSlice";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import Privilege from "./pages/Privilege";
import LevelBonus from "./pages/LevelBonus";
import ReferralUsers from "./pages/ReferralUsers";
import Profile from "./pages/Profile";
import { sideBarLinks } from "./utils/constants";
import { renderSidebar } from "./utils/function";
import NotFound from "./pages/NotFound";
import DashboardLanding from "./pages/DashboardLanding";
function App() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [protectedPath, setProtectedPath] = useState([]);
  const user = useSelector((state) => state.auth.user);
  useEffect(() => {
    if (user) {
      setProtectedPath(renderSidebar(sideBarLinks, user));
    }
  }, [user]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const setAuth = async (e) => {
      try {
        dispatch(getAuth());
      } catch (error) {}
    };
    if (
      Cookies.get(`${process.env.REACT_APP_NETWORK}AdminIsLoggedIn`) === "true"
    ) {
      setAuth();
    }
  }, [dispatch]);
  return (
    <>
      <ToastContainer />
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        >
          <Route index path="dashboard" element={<DashboardLanding />}></Route>
          {protectedPath.map(
            (item, index) =>
              item.title !== "Dashboard" && (
                <Route
                  key={index}
                  path={item.path}
                  element={<Fragment>{item.component}</Fragment>}
                ></Route>
              )
          )}

          <Route path="bonus/:level" element={<LevelBonus />}></Route>
          <Route path="profile/:userId" element={<Profile />}></Route>
          <Route path="privileges/:subAdminId" element={<Privilege />}></Route>
          <Route path="create-subadmin" element={<CreateSubAdmin />}></Route>
          <Route
            path="referral-users/:level/:userId/:address"
            element={<ReferralUsers />}
          ></Route>
          <Route path="*" element={<NotFound />}></Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
