import React, { useRef, useState, useEffect } from "react";
import moment from "moment";
import { PropTypes } from "prop-types";
import { useNavigate } from "react-router-dom";
const Countdown = ({ drawingOn, refreshComponent = null }) => {
  const countdownRef = useRef(null);
  const [timeLeft, setTimeLeft] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const updateCountdown = () => {
      const drawingTime = moment(drawingOn);
      const now = moment();

      const duration = moment.duration(drawingTime.diff(now));

      if (duration.asMilliseconds() <= 0) {
        const data = {
          hour: "0",
          minute: "0",
          sec: "0",
        };
        refreshComponent();
        setTimeLeft(data);
        return;
      }
      const hours = Math.floor(duration.asHours());
      const minutes = Math.floor(duration.asMinutes()) % 60;
      const seconds = Math.floor(duration.asSeconds()) % 60;
      const data = {
        hour: hours,
        minute: minutes,
        sec: seconds,
      };
      setTimeLeft(data);
    };

    const intervalId = setInterval(updateCountdown, 1000);
    return () => clearInterval(intervalId);
  }, [drawingOn, navigate, refreshComponent]);

  return (
    <div ref={countdownRef}>
      {timeLeft?.hour === undefined
        ? "00"
        : timeLeft?.hour > 9
        ? timeLeft?.hour
        : `0${timeLeft?.hour}`}{" "}
      :{" "}
      {timeLeft?.minute === undefined
        ? "00"
        : timeLeft?.minute > 9
        ? timeLeft?.minute
        : `0${timeLeft?.minute}`}{" "}
      :{" "}
      {timeLeft?.sec === undefined
        ? "00"
        : timeLeft?.sec > 9
        ? timeLeft?.sec
        : `0${timeLeft?.sec}`}
    </div>
  );
};
Countdown.propTypes = {
  drawingOn: PropTypes.string,
  refreshComponent: PropTypes.func,
};
export default Countdown;
