import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as api from "../redux/api";
import { toast } from "react-toastify";
const Privilege = () => {
  const { subAdminId } = useParams();
  const [state, setState] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [checkBox, setCheckBox] = useState({
    tread: false,
    oread: false,
    owrite: false,
    uread: false,
    uwrite: false,
    bread: false,
  });
  const handleCheckBox = (e) => {
    const { name, checked } = e.target;
    if (name === "owrite") {
      if (checked === true) {
        setCheckBox({ ...checkBox, oread: checked, [name]: checked });
      } else {
        setCheckBox({ ...checkBox, [name]: checked });
      }
    } else if (name === "uwrite") {
      if (checked === true) {
        setCheckBox({ ...checkBox, uread: checked, [name]: checked });
      } else {
        setCheckBox({ ...checkBox, [name]: checked });
      }
    } else {
      setCheckBox({ ...checkBox, [name]: checked });
    }
  };
  const setPrivileges = () => {
    let privileges = [];
    if (checkBox.tread) {
      privileges.push("ReadTransactions");
    }
    if (checkBox.bread) {
      privileges.push("ReadBonus");
    }
    if (checkBox.owrite) {
      privileges.push("ReadOrders", "WriteOrders");
    } else if (checkBox.oread) {
      privileges.push("ReadOrders");
    }
    if (checkBox.uwrite) {
      privileges.push("ReadUsers", "WriteUsers");
    } else if (checkBox.uread) {
      privileges.push("ReadUsers");
    }

    return privileges;
  };
  const handleUpdateSubAdmin = async (id) => {
    let privileges = setPrivileges();
    try {
      const res = await api.updateSubAdmin({
        id: state.id,
        formData: { privileges: privileges },
      });

      if (res.data.status === "success") {
        toast.success("Updated Successfully");
      } else {
        toast.error(res.data.message);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    const getSubAdminData = async (subAdminId) => {
      setIsLoading(true);
      const res = await api.getSubAdmin(subAdminId);
      const { status, data } = res;
      if (status >= 200 && status < 300) {
        setIsLoading(false);

        setState(data);
      } else {
        setIsLoading(false);
        toast.error(data.message);
      }
    };
    try {
      getSubAdminData(subAdminId);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  }, []);
  useEffect(() => {
    if (state) {
      setCheckBox({
        tread: state?.privileges?.includes("ReadTransactions"),
        oread: state?.privileges?.includes("ReadOrders"),
        owrite: state?.privileges?.includes("WriteOrders"),
        uread: state?.privileges?.includes("ReadUsers"),
        uwrite: state?.privileges?.includes("WriteUsers"),
        bread: state?.privileges?.includes("ReadBonus"),
      });
    }
  }, [state]);
  return (
    <div>
      <div className="flex flex-col gap-4">
        <div className="flex gap-6 items-center">
          <div>
            <b>Name:</b>
          </div>
          <div>{state?.fullname}</div>
        </div>
        <div className="flex gap-6 items-center">
          <div>
            <b>Email:</b>
          </div>
          <div>{state?.email}</div>
        </div>
        <div className="flex gap-6 items-center">
          <div>
            <b>Type:</b>
          </div>
          <div>{state?.type}</div>
        </div>
        <div className="flex gap-6 items-start">
          <div>
            <b>Privileges:</b>
          </div>
          <div className="flex flex-col gap-4">
            <div className="input_field col-span-2">
              <div className="flex items-center gap-8">
                <div className="min-w-[120px] font-semibold">Transactions</div>
                <div className="flex items-center gap-2 flex-row-reverse">
                  <label htmlFor="tread" className="font-medium text-base">
                    Read
                  </label>
                  <input
                    type="checkbox"
                    checked={checkBox.tread}
                    onChange={handleCheckBox}
                    name="tread"
                    value="tread"
                    id="tread"
                    className="w-6 h-6 accent-green-600"
                  />
                </div>
              </div>
            </div>
            <div className="input_field col-span-2">
              <div className="flex items-center gap-8">
                <div className="min-w-[120px] font-semibold">Orders</div>
                <div className="flex items-center gap-2 flex-row-reverse">
                  <label htmlFor="oread" className="font-medium text-base">
                    Read
                  </label>
                  <input
                    type="checkbox"
                    checked={checkBox.oread}
                    onChange={!checkBox.owrite ? handleCheckBox : undefined}
                    name="oread"
                    value="oread"
                    id="oread"
                    className="w-6 h-6 accent-green-600"
                  />
                </div>
                <div className="flex items-center gap-2 flex-row-reverse">
                  <label htmlFor="owrite" className="font-medium text-base">
                    Write
                  </label>
                  <input
                    type="checkbox"
                    checked={checkBox.owrite}
                    onChange={handleCheckBox}
                    name="owrite"
                    value="owrite"
                    id="owrite"
                    className="w-6 h-6 accent-green-600"
                  />
                </div>
              </div>
            </div>
            <div className="input_field col-span-2">
              <div className="flex items-center gap-8">
                <div className="min-w-[120px] font-semibold">Users</div>
                <div className="flex items-center gap-2 flex-row-reverse">
                  <label htmlFor="uread" className="font-medium text-base">
                    Read
                  </label>
                  <input
                    type="checkbox"
                    checked={checkBox.uread}
                    onChange={!checkBox.uwrite ? handleCheckBox : undefined}
                    name="uread"
                    value="uread"
                    id="uread"
                    className="w-6 h-6 accent-green-600"
                  />
                </div>
                <div className="flex items-center gap-2 flex-row-reverse">
                  <label htmlFor="uwrite" className="font-medium text-base">
                    Write
                  </label>
                  <input
                    type="checkbox"
                    checked={checkBox.uwrite}
                    onChange={handleCheckBox}
                    name="uwrite"
                    value="uwrite"
                    id="uwrite"
                    className="w-6 h-6 accent-green-600"
                  />
                </div>
              </div>
            </div>
            <div className="input_field col-span-2">
              <div className="flex items-center gap-8">
                <div className="min-w-[120px] font-semibold">Bonus</div>
                <div className="flex items-center gap-2 flex-row-reverse">
                  <label htmlFor="bread" className="font-medium text-base">
                    Read
                  </label>
                  <input
                    type="checkbox"
                    checked={checkBox.bread}
                    onChange={handleCheckBox}
                    name="bread"
                    value="bread"
                    id="bread"
                    className="w-6 h-6 accent-green-600"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex gap-6">
          <button
            className="btn btn-primary max-w-fit"
            onClick={() => navigate(-1)}
          >
            Go Back
          </button>
          <button
            className="btn btn-primary max-w-fit"
            onClick={() => handleUpdateSubAdmin(state.id)}
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default Privilege;
