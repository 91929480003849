import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";

import { toast } from "react-toastify";
import * as api from "../../redux/api";
import Spinner from "../loaders/Spinner";

const PurchaseRequestStatusChange = ({ handleClose, open, statusType, id }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [text, setText] = useState("");
  const handleSubmit = async () => {
    if (text.length < 1) {
      toast.error("Input field should not be empty");
      return;
    }
    try {
      const res = await api.changePurchaseRequestStatus(id, {
        status: statusType,
        narration: text,
      });
      const { status, data } = res;
      if (status >= 200 && status < 300) {
        setIsLoading(false);
        toast.success("Changed Successfully");
        handleClose();
        setText("");
      } else {
        setIsLoading(false);
        toast.error(data.message);
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  const renderLabel = (type) => {
    if (type === "Processed") {
      return "Assigned To:";
    } else if (type === "Rejected") {
      return "Reason:";
    } else if (type === "Completed") {
      return "Transaction Hash";
    }
  };
  const renderPlaceholder = (type) => {
    if (type === "Processed") {
      return "Enter name";
    } else if (type === "Rejected") {
      return "Enter specific reason";
    } else if (type === "Completed") {
      return "Enter hash";
    }
  };
  return (
    <>
      {isLoading && <Spinner />}
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitle id="scroll-dialog-title">
          {" "}
          <h4 className=" text-center">Change Status </h4>
        </DialogTitle>
        <DialogContent dividers={"paper"}>
          <div className="w-full min-h-[250px] ">
            <div className="">
              <div className="flex items-center gap-4">
                <strong>{renderLabel(statusType)}</strong>
                <input
                  type="text"
                  placeholder={renderPlaceholder(statusType)}
                  className="flex-1"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            type="button"
            onClick={handleSubmit}
            className={`btn btn-primary `}
          >
            Submit
          </button>
          <button
            type="button"
            onClick={handleClose}
            className={`btn btn-danger `}
          >
            Close
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PurchaseRequestStatusChange;
