import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as api from "../../redux/api";
import { AccountValidation } from "../../utils/validation";
import { isYupError, parseYupError } from "../../utils/Yup";
const initialState = {
  accountHolderName: "",
  accountNumber: "",
  bankName: "",
  ifscCode: "",
};
const AddBankAccount = ({ handleClose, open, updateData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState(initialState);
  const [formError, setFormError] = useState({});
  const handleCloseModal = () => {
    handleClose();
    setForm(initialState);
    setFormError({});
  };

  const handleUpdate = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      setFormError({});
      await AccountValidation.validate(form, {
        abortEarly: false,
      });
      const res = await api.updateAccount(updateData.id, form);
      const { status, data } = res;
      if (status >= 200 && status < 300) {
        setIsLoading(false);
        handleCloseModal();
        toast.success("Updated Successfully");
      } else {
        setIsLoading(false);
        toast.error(data.message);
      }
    } catch (error) {
      if (isYupError(error)) {
        setFormError(parseYupError(error));
      } else {
        console.log(error, "error");
        toast.error(error.data.message);
      }
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      setFormError({});
      await AccountValidation.validate(form, {
        abortEarly: false,
      });
      const res = await api.addAccount(form);
      if (res.data.status === "success") {
        setIsLoading(false);
        toast.success("Added Successfully");
        handleCloseModal();
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      if (isYupError(error)) {
        setFormError(parseYupError(error));
      } else {
        console.log(error, "error");
        toast.error(error.data.message);
      }

      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (updateData) {
      setForm({
        accountHolderName: updateData.accountHolderName,
        accountNumber: updateData.accountNumber,
        bankName: updateData.bankName,
        ifscCode: updateData.ifscCode,
      });
    }
  }, [updateData]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseModal}
        scroll={"paper"}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitle id="scroll-dialog-title">
          {" "}
          <h4 className="text-center">
            {updateData ? "Update Bank Balance" : "Add Bank Balance"}
          </h4>
        </DialogTitle>
        <DialogContent dividers={true}>
          <div className="w-full ">
            <div className="flex flex-col gap-2 py-6 px-6 ">
              <div className="input_field">
                <label htmlFor="">Account holder name</label>
                <input
                  type="text"
                  placeholder="Please enter account holder name"
                  onChange={(e) =>
                    setForm({ ...form, accountHolderName: e.target.value })
                  }
                  name="accountHolderName"
                  value={form.accountHolderName}
                />
              </div>
              {formError.accountHolderName && (
                <div className="form-eror">{formError.accountHolderName}</div>
              )}
              <div className="input_field">
                <label htmlFor="">Account Number</label>
                <input
                  type="text"
                  placeholder="Please enter account number"
                  onChange={(e) =>
                    setForm({ ...form, accountNumber: e.target.value })
                  }
                  name="accountNumber"
                  value={form.accountNumber}
                />
              </div>
              {formError.accountNumber && (
                <div className="form-eror">{formError.accountNumber}</div>
              )}
              <div className="input_field">
                <label htmlFor="">Bank Name</label>
                <input
                  type="text"
                  placeholder="Please enter bank name"
                  onChange={(e) =>
                    setForm({ ...form, bankName: e.target.value })
                  }
                  name="bankName"
                  value={form.bankName}
                />
              </div>
              {formError.bankName && (
                <div className="form-eror">{formError.bankName}</div>
              )}
              <div className="input_field">
                <label htmlFor="">IFSC Code</label>
                <input
                  type="text"
                  placeholder="Please enter IFSC code"
                  onChange={(e) =>
                    setForm({ ...form, ifscCode: e.target.value })
                  }
                  name="ifscCode"
                  value={form.ifscCode}
                />
              </div>
              {formError.ifscCode && (
                <div className="form-eror">{formError.ifscCode}</div>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          {updateData ? (
            <button
              type="button"
              onClick={handleUpdate}
              disabled={isLoading}
              className={`btn btn-primary min-w-[200px]  ${
                isLoading
                  ? "bg-red-500 border-red-500 opacity-50 disabled:pointer-events-none"
                  : undefined
              }`}
            >
              {isLoading ? "Loading" : "Update"}
            </button>
          ) : (
            <button
              type="button"
              onClick={handleSubmit}
              disabled={isLoading}
              className={`btn btn-primary min-w-[200px]  ${
                isLoading
                  ? "bg-red-500 border-red-500 opacity-50 disabled:pointer-events-none"
                  : undefined
              }`}
            >
              {isLoading ? "Loading" : "Submit"}
            </button>
          )}

          <button
            onClick={handleCloseModal}
            type="button"
            className="btn btn-danger min-w-[200px]"
          >
            Close
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddBankAccount;
