import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { isYupError, parseYupError } from '../../utils/Yup';
import { toast } from 'react-toastify';
import * as api from '../../redux/api';
import { merchantValidationSchema } from '../../utils/validation';
import {
  changeCurrentMerchnatStatus,
  handleAcceptKYCRequest,
  handleRejectKYCRequest,
} from '../../helpers/request';
import { kycSelectOption, selectOption } from '../../utils/constants';
import { reactIcons } from '../../utils/icons';

const EditMerchant = ({
  handleClose,
  open,
  data,
  getMerchantsInfo,
  isKycMechant,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState(data);
  const [formError, setFormError] = useState({});

  const handleCloseModal = () => {
    handleClose();
    setForm({});
    setFormError({});
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    try {
      let _temp = {
        ...form,
      };
      await merchantValidationSchema.validate(form, {
        abortEarly: false,
      });
      const res = await api.updateMerchnatDetails(data.id, _temp);
      if (res.data.status === 'success') {
        setIsLoading(false);
        toast.success('Updated Successfully');
        setForm({});
        setFormError({});
        getMerchantsInfo();
        handleCloseModal();
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      if (isYupError(error)) {
        setFormError(parseYupError(error));
      } else {
        toast.error(error.response.data.message);
      }
      setIsLoading(false);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
    setFormError({
      ...formError,
      [name]: '',
    });
  };

  useEffect(() => {
    if (data) {
      setForm(data);
    }
  }, [data, open]);

  const handleRegistationChange = (e, id) => {
    const { name, value } = e.target;
    console.log(name)
    switch (value) {
      case 'accepted':
        changeCurrentMerchnatStatus(
          data?.id,
          'accept-merchant-registration',
          'Activated',
          getMerchantsInfo,
        );
        break;
      case 'rejected':
        changeCurrentMerchnatStatus(
          data?.id,
          'reject-merchant-registration',
          'Inactivated',
          getMerchantsInfo,
        );
        break;
      default:
        changeCurrentMerchnatStatus(
          data?.id,
          'block-unblock-merchant',
          'Blocked',
          getMerchantsInfo,
        );
    }
  };

  const handleKycChange = async (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    value === 'Accepted'
      ? handleAcceptKYCRequest(data?.id, getMerchantsInfo)
      : handleRejectKYCRequest(data?.id, getMerchantsInfo);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseModal}
        scroll={'paper'}
        fullWidth={true}
        maxWidth={'sm'}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitle id="scroll-dialog-title">
          {' '}
          <h4 className=" text-center">Edit Merchant Details</h4>
        </DialogTitle>
        <DialogContent dividers={'paper'}>
          <div className="w-full ">
            <div className=" flex flex-col gap-2 py-6 px-6 ">
              <div className="input_field my-2">
                <label htmlFor="">Full Name</label>
                <input
                  type="text"
                  placeholder="First Name"
                  name="fullname"
                  onChange={handleChange}
                  value={form?.fullname}
                />
                {formError.fullname && (
                  <div className="form-eror">{formError.fullname}</div>
                )}
              </div>
              <div className="input_field my-2">
                <label htmlFor="">Website Domain</label>
                <input
                  type="text"
                  placeholder="Website Domain"
                  name="websiteDomain"
                  onChange={handleChange}
                  value={form?.websiteDomain}
                />
                {formError.websiteDomain && (
                  <div className="form-eror">{formError.websiteDomain}</div>
                )}
              </div>
              <div className="input_field my-2">
                <label htmlFor="">Email</label>
                <input
                  type="text"
                  placeholder="Email"
                  name="email"
                  onChange={handleChange}
                  value={form?.email}
                />
                {formError.email && (
                  <div className="form-eror">{formError.email}</div>
                )}
              </div>
              <div className="input_field my-2">
                <label htmlFor="">Platform Name</label>
                <input
                  type="text"
                  placeholder="Platform Name"
                  name="platformName"
                  onChange={handleChange}
                  value={form?.platformName}
                />
                {formError.platformName && (
                  <div className="form-eror">{formError.platformName}</div>
                )}
              </div>
              <div className="input_field my-2">
                <label htmlFor="">Callback URL</label>
                <input
                  type="text"
                  placeholder="Call Back Url"
                  name="callbackUrl"
                  onChange={handleChange}
                  value={form?.callbackUrl}
                />
                {formError.callbackUrl && (
                  <div className="form-eror">{formError.callbackUrl}</div>
                )}
              </div>
              <div className="input_field my-2">
                <label htmlFor="">UIN</label>
                <input
                  type="text"
                  placeholder="UIN"
                  name="uin"
                  onChange={handleChange}
                  value={form?.uin}
                />
                {formError.uin && (
                  <div className="form-eror">{formError.uin}</div>
                )}
              </div>
              <div className="input_field my-2">
                <label htmlFor="">Wallet Address</label>
                <input
                  type="text"
                  placeholder="Wallet Address"
                  name="walletAddress"
                  onChange={handleChange}
                  value={form?.walletAddress}
                />
                {formError.walletAddress && (
                  <div className="form-eror">{formError.walletAddress}</div>
                )}
              </div>
              <div className="input_field my-2">
                <label htmlFor="howToFind">How To Find</label>
                <textarea
                  type="text"
                  placeholder="How To Find"
                  name="howToFind"
                  onChange={handleChange}
                  value={form?.howToFind}
                  className='min-h-[100px] resize-none'
                />
                {formError.howToFind && (
                  <div className="form-eror">{formError.howToFind}</div>
                )}
              </div>
              <div className="input_field">
                <label htmlFor="">Status</label>
                <div className="form-select-custom flex relative">
                  <select
                    name="registrationStatus"
                    value={form?.registrationStatus || ''}
                    onChange={(e) => {
                      handleChange(e);
                      handleRegistationChange(e, form?.id);
                    }}
                  >
                    {selectOption.map((option, i) => (
                      <option key={i} value={option.value}>
                        {option.name}
                      </option>
                    ))}
                  </select>{' '}

                  <span className='text-[22px] absolute top-4 right-3'>{reactIcons.arrowDown}</span>
                </div>
              </div>
              {isKycMechant && (
                <div className="input_field">
                  <label htmlFor="">KYC Status</label>
                  <div className="form-select-custom flex relative">
                    <select
                      name="status"
                      value={String(form?.status) || ''}
                      onChange={(e) => {
                        handleChange(e);
                        handleKycChange(e, form?.id);
                      }}
                    >
                      {kycSelectOption.map((option, i) => (
                        <option
                          key={i}
                          value={option.value}
                          disabled={option.disabled}
                          className={`${option.disabled ? 'cursor-not-allowed' : ''}`}
                        >
                          {option.name}
                        </option>
                      ))}
                    </select>{' '}
                    <span className='text-[22px] absolute top-4 right-3'>{reactIcons.arrowDown}</span>
                  
                  </div>
                </div>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            type="button"
            onClick={handleSubmit}
            disabled={isLoading}
            className={`btn btn-primary min-w-[200px]  ${
              isLoading
                ? 'bg-red-500 border-red-500 opacity-50 disabled:pointer-events-none'
                : undefined
            }`}
          >
            {isLoading ? 'Loading' : 'Submit'}
          </button>
          <button
            onClick={handleCloseModal}
            type="button"
            className="btn btn-danger min-w-[200px]"
          >
            Close
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditMerchant;
