import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { reactIcons } from "../../utils/icons";
import {
  activateMerchantSatatus,
  deAtivateMerchantSatatus,
  handleAcceptKYCRequest,
  handleRejectKYCRequest,
} from "../../helpers/request";

const ITEM_HEIGHT = 48;

export default function ActionButton({
  data,
  setIsEditUserOpen,
  getMerchantsInfo,
  setUserData,
  isKycMechant,
  usersType,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const options = [
    <div className="flex items-center gap-4 justify-center bg-zinc-200 hover:bg-zinc-300 rounded-md w-full">
      {usersType !== "new-merchant" && (
        <button
          onClick={() => {
            setIsEditUserOpen(true);
            setUserData(data);
          }}
          className="w-full h-10 z-10 cursor-pointer text-[18px]  flex-center"
        >
          Edit
        </button>
      )}
    </div>,
    !isKycMechant ? (
      <>
        {" "}
        <div className="w-full">
          {data?.registrationStatus === "accepted" ? (
            <button
              onClick={() => {
                deAtivateMerchantSatatus(data.id, getMerchantsInfo);
              }}
              className="px-4 py-2 text-xs lg:text-sm xl:text-base  rounded-md bg-[#dc2626] hover:bg-danger text-white w-full"
            >
              Inactive
            </button>
          ) : (
            <button
              onClick={() => {
                activateMerchantSatatus(data.id, getMerchantsInfo);
              }}
              className="px-4 text-xs lg:text-sm xl:text-base py-2 rounded-md bg-[#166534] hover:bg-[#14532d]v text-white w-full"
            >
              Active
            </button>
          )}
        </div>
      </>
    ) : (
      data?.status && (
        <>
          <div className="w-full">
            {data?.status === "Pending" || data?.status === "Rejected" ? (
              <button
                onClick={() => {
                  handleAcceptKYCRequest(data.id, getMerchantsInfo);
                }}
                className=" text-xs lg:text-sm xl:text-base py-2 rounded-md bg-[#166534] hover:bg-[#14532d]v text-white w-full"
              >
                Accept KYC
              </button>
            ) : (
              <button
                onClick={() => {
                  handleRejectKYCRequest(data.id, getMerchantsInfo);
                }}
                className="px-4 text-xs lg:text-sm xl:text-base py-2 rounded-md bg-[#dc2626] hover:bg-[#14532d]v text-white"
              >
                Reject KYC
              </button>
            )}
          </div>
        </>
      )
    ),
  ];

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        {reactIcons.more}
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "14ch",
          },
        }}
      >
        {options.map((option, i) => (
          <MenuItem key={i} selected={option === "Pyxis"} onClick={handleClose}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
