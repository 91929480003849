import React, { useEffect, useState } from "react";
import {
  DataGrid,
  // getGridBooleanOperators,
  getGridStringOperators,
  // GridActionsCellItem,
  // GridToolbar,
  // useGridApiRef,
} from "@mui/x-data-grid";
import { LinearProgress } from "@mui/material";
import { reactIcons } from "../utils/icons";
import CustomNoRowsOverlay from "../utils/styles";
import * as api from "../redux/api";
import { toast } from "react-toastify";
const Order = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [typeOfOrder, setTypeOfOrders] = useState("AllOrders");
  // const apiRef = useGridApiRef();

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 8,
  });
  const [rowCountState, setRowCountState] = useState(0);

  const [rows, setRows] = useState([]);
  const filterOperators = getGridStringOperators().filter(({ value }) =>
    ["contains"].includes(value)
  );
  const columns = [
    {
      field: "_id",
      headerName: "S.No.",
      type: "number",
      headerAlign: "center",
      align: "center",
      flex: 1,
      filterable: false,
    },
    {
      field: "baseTokenAmount",
      headerName: "Base Amount",
      type: "number",
      headerAlign: "center",
      align: "center",
      flex: 3,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }

        const valueFormatted = Number(params.value);
        return `${valueFormatted} USDFc`;
      },
      filterable: false,
    },
    {
      field: "exchangeTokenAmount",
      headerName: "Exchange Amount",
      type: "string",
      flex: 3,
      headerAlign: "center",
      align: "center",
      valueFormatter: (params) => {
        console.log(params, "params");
        if (params.value == null) {
          return "";
        }

        const valueFormatted = Number(params.value);
        return `${valueFormatted} `;
      },
      renderCell: (params) => {
        return (
          <div className="data-grid-fs">
            {params.row.exchangeTokenAmount} {params.row.currency}
          </div>
        );
      },
      filterable: false,
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      type: "string",
      filterOperators: filterOperators,
    },
    {
      field: "status",
      headerName: "Status",
      type: "string",
      flex: 2,
      filterOperators: filterOperators,
    },
    {
      field: "expireOn",
      headerName: "Expire On",
      type: "dateTime",
      flex: 3,
      valueGetter: ({ value }) => value && new Date(value),
      filterable: false,
    },

    {
      field: "offers",
      headerName: "Offers",
      type: "number",
      flex: 1,
      headerAlign: "center",
      align: "center",
      filterable: false,
    },
  ];
  const getFilteredOrderData = async (type, value) => {
    setIsLoading(true);
    const response = await api.getFilteredOrders(
      paginationModel.page,
      type,
      value
    );
    const { data, status } = response;
    if (status >= 200 && status < 300) {
      setIsLoading(false);
      setRowCountState(data.count);
      setRows(
        data.orders.map((item, index) => {
          return {
            ...item,
            offers: item.offers.length,
            _id: 8 * paginationModel.page + index + 1,
          };
        })
      );
    } else {
      setIsLoading(false);
      toast.error(data.message);
    }
  };
  const getOrderData = async () => {
    setIsLoading(true);
    const response = await api.getOrders(paginationModel.page);
    const { status, data } = response;
    if (status >= 200 && status < 300) {
      setIsLoading(false);
      setRowCountState(data.count);
      setRows(
        data.orders.map((item, index) => {
          return {
            ...item,
            offers: item.offers.length,
            _id: 8 * paginationModel.page + index + 1,
          };
        })
      );
    } else {
      setIsLoading(false);
      toast.error(data.message);
    }
  };
  useEffect(() => {
    try {
      if (typeOfOrder === "AllOrders") getOrderData();
      else if (typeOfOrder === "Active")
        getFilteredOrderData("status", "Active");
      else if (typeOfOrder === "Cancelled")
        getFilteredOrderData("status", "Cancelled");
      else if (typeOfOrder === "Settled")
        getFilteredOrderData("status", "Settled");
      else if (typeOfOrder === "Expired")
        getFilteredOrderData("status", "Expired");
      else if (typeOfOrder === "Buy") getFilteredOrderData("type", "Buy");
      else if (typeOfOrder === "Sell") getFilteredOrderData("type", "Sell");
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
    setRowCountState((prevRowCountState) =>
      rowCountState !== undefined ? rowCountState : prevRowCountState
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel, typeOfOrder, rowCountState, setRowCountState]);

  return (
    <div className="w-full mt-10 data-grid">
      <div className="grid grid-cols-4 gap-4  mt-6">
        <div className="p-3 xl:p-4  shadow-lg border-2 border-grey-30 border-l-[6px] border-l-yellow-dark rounded-lg flex items-center justify-between gap-2">
          <div className="space-y-1 flex-1">
            <h6 className="text-theme-text2 mb-2 text-sm xl:text-[16px]">
              Active Orders
            </h6>
            <div className="flex ">
              <span className="xl:text-base text-sm font-bold">N/A</span>{" "}
            </div>
          </div>
        </div>
        <div className="p-3 xl:p-4  shadow-lg border-2 border-grey-30 border-l-[6px] border-l-green-dark rounded-lg flex items-center justify-between gap-2">
          <div className="space-y-1 flex-1">
            <h6 className="text-theme-text2 mb-2 text-sm xl:text-[16px]">
              Settled Orders
            </h6>
            <div className="flex ">
              <span className="xl:text-base text-sm font-bold">N/A</span>{" "}
            </div>
          </div>
        </div>
        <div className="p-3 xl:p-4  shadow-lg border-2 border-grey-30 border-l-[6px] border-l-pink-500 rounded-lg flex items-center justify-between gap-2">
          <div className="space-y-1 flex-1">
            <h6 className="text-theme-text2 mb-2 text-sm xl:text-[16px]">
              Dead Orders
            </h6>
            <div className="flex ">
              <span className="xl:text-base text-sm font-bold">N/A</span>{" "}
            </div>
          </div>
        </div>
        <div className="p-3 xl:p-4  shadow-lg border-2 border-grey-30 border-l-[6px] border-l-gray-500 rounded-lg flex items-center justify-between gap-2">
          <div className="space-y-1 flex-1">
            <h6 className="text-theme-text2 mb-2 text-sm xl:text-[16px]">
              Revenue Earn
            </h6>
            <div className="flex ">
              <span className="xl:text-base text-sm font-bold">N/A</span>{" "}
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-between gap-4 items-center mt-8 mb-4">
        <h4 className=" ">Orders</h4>
        <div className="form-select-custom">
          <select
            className="min-w-[250px] w-full"
            name="typeOfOrder"
            id="typeOfOrder"
            value={typeOfOrder}
            onChange={(e) => setTypeOfOrders(e.target.value)}
          >
            {[
              { label: "All Orders", value: "AllOrders" },
              { label: "Active", value: "Active" },
              { label: "Settled", value: "Settled" },
              { label: "Expired", value: "Expired" },
              { label: "Cancelled", value: "Cancelled" },
              { label: "Buy", value: "Buy" },
              { label: "Sell", value: "Sell" },
            ].map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          <span className="ay-center right-2 text-zinc-500 text-[28px] pointer-events-none ">
            {reactIcons.arrowDown}
          </span>
        </div>
      </div>

      <div className=" h-[600px] w-full mt">
        <DataGrid
          loading={isLoading}
          rows={rows}
          getEstimatedRowHeight={() => 52}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 8,
              },
            },
          }}
          rowCount={rowCountState}
          pageSizeOptions={[8]}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          slots={{
            loadingOverlay: LinearProgress,
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          disableColumnMenu
          disableRowSelectionOnClick
        />
      </div>
    </div>
  );
};

export default Order;
