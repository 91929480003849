import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbar,
  useGridApiRef,
} from "@mui/x-data-grid";
import { LinearProgress } from "@mui/material";
import { reactIcons } from "./../utils/icons";
import CustomNoRowsOverlay from "./../utils/styles";
import * as api from "../redux/api";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const SubAdmin = () => {
  const apiRef = useGridApiRef();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 8,
  });
  const [rowCountState, setRowCountState] = useState(0);

  const handleDeleteSubAdmin = async (id) => {
    try {
      const res = await api.deleteSubAdmin(id);
      if (res.data.status === "success") {
        toast.success("Deleted Successfully");
        getSubAdminsData();
      } else {
        toast.error("Error Occurred");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handlePrivilegeNavigate = (data) => {
    navigate(`/privileges/${data.id}`);
  };

  const [rows, setRows] = useState([]);

  const columns = [
    {
      field: "_id",
      headerName: "S.No.",
      type: "number",
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "fullname",
      headerName: "Full Name",
      type: "string",
      flex: 2,
    },

    {
      field: "email",
      headerName: "Email",
      type: "string",
      flex: 3,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      type: "dateTime",
      flex: 2,
      valueGetter: ({ value }) => value && new Date(value),
    },
    {
      field: "privileges",
      headerName: "View Privileges",
      type: "string",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div>
            <span className="text-sm xl:text-base">
              {params?.row?.privileges.length > 0
                ? params?.row?.privileges[0]?.substring(0, 10) + ".."
                : "No Privileges"}
            </span>
            <button
              onClick={() => handlePrivilegeNavigate(params.row)}
              className="px-2 py-1 text-[10px] font-bold rounded-lg bg-zinc-200"
            >
              View
            </button>
          </div>
        );
      },
    },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      flex: 1,
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={reactIcons.delete}
            label="delete"
            onClick={() => handleDeleteSubAdmin(params.id)}
          />,
          <GridActionsCellItem
            icon={reactIcons.edit}
            label="edit"
            onClick={() => handlePrivilegeNavigate(params.row)}
          />,
        ];
      },
    },
  ];
  const getSubAdminsData = async () => {
    setIsLoading(true);
    const response = await api.getSubAdmins(paginationModel.page);

    const { status, data } = response;
    if (status >= 200 && status < 300) {
      setIsLoading(false);
      setRowCountState(data.count);
      setRows(
        data.subAdmins.map((item, index) => {
          return { ...item, _id: 8 * paginationModel.page + index + 1 };
        })
      );
    } else {
      setIsLoading(false);
      toast.error(data.message);
    }
  };
  useEffect(() => {
    try {
      getSubAdminsData();
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }

    setRowCountState((prevRowCountState) =>
      rowCountState !== undefined ? rowCountState : prevRowCountState
    );
  }, [paginationModel, rowCountState, setRowCountState]);
  return (
    <div className="w-full mt-6 data-grid">
      <div className="flex justify-between mb-6 items-center">
        <h4>Sub Admins</h4>
        <Link to="/create-subadmin">
          <button className="btn btn-primary">Create New SubAdmins</button>
        </Link>
      </div>

      <div className=" h-[600px] w-full">
        <DataGrid
          loading={isLoading}
          rows={rows}
          getEstimatedRowHeight={() => 52}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 8,
              },
            },
          }}
          rowCount={rowCountState}
          pageSizeOptions={[8]}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          slots={{
            loadingOverlay: LinearProgress,
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          disableColumnMenu
          disableRowSelectionOnClick
        />
      </div>
    </div>
  );
};

export default SubAdmin;
