import React, { useState } from "react";
import { isYupError } from "../utils/Yup";
import { subAdminValidation } from "./../utils/validation";
import { parseYupError } from "./../utils/Yup";
import * as api from "../redux/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const CreateSubAdmin = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [emailCodeError, setEmailCodeError] = useState(null);
  const [emailVerificationCode, setemailVerificationCode] = useState("");
  const [form, setForm] = useState({
    email: "",
    fullname: "",
    password: "",
  });
  const [checkBox, setCheckBox] = useState({
    tread: false,
    oread: false,
    owrite: false,
    uread: false,
    uwrite: false,
    bread: false,
  });
  const [formError, setFormError] = useState({
    email: "",
    fullname: "",
    password: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
    setFormError({
      ...formError,
      [name]: "",
    });
  };
  const handleCheckBox = (e) => {
    const { name, checked } = e.target;
    if (name === "owrite") {
      if (checked === true) {
        setCheckBox({ ...checkBox, oread: checked, [name]: checked });
      } else {
        setCheckBox({ ...checkBox, [name]: checked });
      }
    } else if (name === "uwrite") {
      if (checked === true) {
        setCheckBox({ ...checkBox, uread: checked, [name]: checked });
      } else {
        setCheckBox({ ...checkBox, [name]: checked });
      }
    } else {
      setCheckBox({ ...checkBox, [name]: checked });
    }
  };
  const setPrivileges = () => {
    let privileges = [];
    if (checkBox.tread) {
      privileges.push("ReadTransactions");
    }
    if (checkBox.bread) {
      privileges.push("ReadBonus");
    }

    if (checkBox.owrite) {
      privileges.push("ReadOrders", "WriteOrders");
    } else if (checkBox.oread) {
      privileges.push("ReadOrders");
    }
    if (checkBox.uwrite) {
      privileges.push("ReadUsers", "WriteUsers");
    } else if (checkBox.uread) {
      privileges.push("ReadUsers");
    }

    return privileges;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setFormError({});
      await subAdminValidation.validate(form, {
        abortEarly: false,
      });

      const res = await api.sentVerficationCode({
        email: form.email,
      });
      const { status, data } = res;
      if (status >= 200 && status < 300) {
        toast.success("Verfication code sent successfully");
        setShow(true);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      if (isYupError(error)) {
        setFormError(parseYupError(error));
      }
    }
  };
  const handleVerificationCode = async (e) => {
    e.preventDefault();
    if (emailVerificationCode.length > 1) {
      try {
        let privileges = setPrivileges();
        const res = await api.adminRegister({
          ...form,
          emailVerificationCode: emailVerificationCode,
          privileges: privileges,
        });
        const { status, data } = res;
        if (status >= 200 && status < 300) {
          toast.success("Sub Admin Created Successfully");
          navigate(-1);
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        if (isYupError(error)) {
          setFormError(parseYupError(error));
        }
      }
    } else {
      setEmailCodeError("Please enter OTP");
    }
  };

  return (
    <div>
      {!show ? (
        <form onSubmit={handleSubmit} className="max-w-4xl ">
          <header>
            <h4>Create New SubAdmins</h4>
          </header>
          <div className="w-full grid grid-cols-2 gap-6 mt-4">
            <div className="input_field">
              <input
                type="text"
                placeholder="Email Address"
                name="email"
                onChange={handleChange}
              />
              {formError.email && (
                <div className="form-eror">{formError.email}</div>
              )}
            </div>
            <div className="input_field">
              <input
                type="text"
                placeholder="Full Name"
                name="fullname"
                onChange={handleChange}
              />
              {formError.fullname && (
                <div className="form-eror">{formError.fullname}</div>
              )}
            </div>
            <div className="input_field">
              <input
                type="password"
                placeholder="Password"
                name="password"
                onChange={handleChange}
              />
              {formError.password && (
                <div className="form-eror">{formError.password}</div>
              )}
            </div>
            <div className="input_field col-span-2">
              <div className="flex items-center gap-8">
                <div className="min-w-[120px] font-semibold">Transactions</div>
                <div className="flex items-center gap-2 flex-row-reverse">
                  <label htmlFor="tread" className="font-medium text-base">
                    Read
                  </label>
                  <input
                    type="checkbox"
                    checked={checkBox.tread}
                    onChange={handleCheckBox}
                    name="tread"
                    value="tread"
                    id="tread"
                    className="w-6 h-6 accent-green-600"
                  />
                </div>
              </div>
            </div>
            <div className="input_field col-span-2">
              <div className="flex items-center gap-8">
                <div className="min-w-[120px] font-semibold">Orders</div>
                <div className="flex items-center gap-2 flex-row-reverse">
                  <label htmlFor="oread" className="font-medium text-base">
                    Read
                  </label>
                  <input
                    type="checkbox"
                    checked={checkBox.oread}
                    onChange={!checkBox.owrite ? handleCheckBox : undefined}
                    name="oread"
                    value="oread"
                    id="oread"
                    className="w-6 h-6 accent-green-600"
                  />
                </div>
                <div className="flex items-center gap-2 flex-row-reverse">
                  <label htmlFor="owrite" className="font-medium text-base">
                    Write
                  </label>
                  <input
                    type="checkbox"
                    checked={checkBox.owrite}
                    onChange={handleCheckBox}
                    name="owrite"
                    value="owrite"
                    id="owrite"
                    className="w-6 h-6 accent-green-600"
                  />
                </div>
              </div>
            </div>
            <div className="input_field col-span-2">
              <div className="flex items-center gap-8">
                <div className="min-w-[120px] font-semibold">Users</div>
                <div className="flex items-center gap-2 flex-row-reverse">
                  <label htmlFor="uread" className="font-medium text-base">
                    Read
                  </label>
                  <input
                    type="checkbox"
                    checked={checkBox.uread}
                    onChange={!checkBox.uwrite ? handleCheckBox : undefined}
                    name="uread"
                    value="uread"
                    id="uread"
                    className="w-6 h-6 accent-green-600"
                  />
                </div>
                <div className="flex items-center gap-2 flex-row-reverse">
                  <label htmlFor="uwrite" className="font-medium text-base">
                    Write
                  </label>
                  <input
                    type="checkbox"
                    checked={checkBox.uwrite}
                    onChange={handleCheckBox}
                    name="uwrite"
                    value="uwrite"
                    id="uwrite"
                    className="w-6 h-6 accent-green-600"
                  />
                </div>
              </div>
            </div>
            <div className="input_field col-span-2">
              <div className="flex items-center gap-8">
                <div className="min-w-[120px] font-semibold">Bonus</div>
                <div className="flex items-center gap-2 flex-row-reverse">
                  <label htmlFor="bread" className="font-medium text-base">
                    Read
                  </label>
                  <input
                    type="checkbox"
                    checked={checkBox.bread}
                    onChange={handleCheckBox}
                    name="bread"
                    value="bread"
                    id="bread"
                    className="w-6 h-6 accent-green-600"
                  />
                </div>
              </div>
            </div>
            <div className="col-span-2">
              <button type="submit" className="btn btn-primary">
                {" "}
                Submit
              </button>
            </div>
          </div>
        </form>
      ) : (
        <form
          onSubmit={handleVerificationCode}
          className="max-w-md mt-6 space-y-6"
        >
          <div className="input_field">
            <input
              type="text"
              placeholder="Enter verfication code"
              name="emailVerificationCode"
              value={emailVerificationCode}
              onChange={(e) => {
                setemailVerificationCode(e.target.value);
                setEmailCodeError("");
              }}
            />
            {emailCodeError && (
              <div className="form-eror">{emailCodeError}</div>
            )}
          </div>
          <div>
            <button type="submit" className="btn btn-primary">
              Create SubAdmin
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default CreateSubAdmin;
