import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { LinearProgress } from "@mui/material";
import CustomNoRowsOverlay from "../utils/styles";
import { useEffect } from "react";
import * as api from "../redux/api";
import { toast } from "react-toastify";
import { reactIcons } from "../utils/icons";
import AddBankAccount from "../components/modals/AddBankAccount";
import Spinner from "../components/loaders/Spinner";

const AccountManagement = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [isAddAccountOpen, setAddAccountOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [updateData, setUpdateData] = useState(null);
  const columns = [
    {
      field: "_id",
      headerName: "S.No.",
      type: "number",
      headerAlign: "center",
      align: "center",
      width: 80,
    },
    {
      field: "accountHolderName",
      headerName: "Account Holder Name",
      type: "number",
      flex: 2,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "accountNumber",
      headerName: "Account Number",
      type: "number",
      flex: 2,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "bankName",
      headerName: "Bank Name",
      type: "number",
      flex: 2,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "ifscCode",
      headerName: "IFSC Code",
      type: "number",
      flex: 2,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "default",
      headerName: "Default",
      type: "number",
      flex: 2,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <input
            type="checkbox"
            checked={Boolean(params?.row.status)}
            onChange={() => updateDefaultAccount(params.row.id)}
            className="w-10 h-4 text-theme-primary bg-gray-100 border-gray-300 rounded focus:bg-theme-primary "
          />
        );
      },
    },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      flex: 3,
      renderCell: (params) => {
        return (
          <div className="flex gap-2 justify-center items-center">
            <>
              <span
                role="button"
                onClick={() => {
                  setUpdateData(params.row);
                  setAddAccountOpen(true);
                }}
                className="w-10 h-10 flex-shrink-0 flex justify-center items-center rounded-md text-[18px] bg-gray-200 hover:bg-gray-300"
              >
                {reactIcons.edit}
              </span>
              <span
                role="button"
                onClick={() => handleDeleteAccount(params.row.id)}
                className="w-10 h-10 flex-shrink-0 flex justify-center items-center rounded-md text-[18px] bg-red-200 text-red-500"
              >
                {reactIcons.delete}
              </span>
            </>
          </div>
        );
      },
    },
  ];
  const handleDeleteAccount = async (id) => {
    setSpinnerLoading(true);
    try {
      const res = await api.deleteAccount(id);
      if (res.data.status === "success") {
        toast.success("Deleted Successfully");
        getAccountInfo();
        setSpinnerLoading(false);
      } else {
        setSpinnerLoading(false);
        toast.error("Error Occurred");
      }
    } catch (err) {
      setSpinnerLoading(false);
      if (err.response && err.response.status === 500) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err);
      }
    }
  };
  const updateDefaultAccount = async (id) => {
    setSpinnerLoading(true);
    try {
      const res = await api.defaultAccount(id);
      if (res.data.status === "success") {
        toast.success("Default Account set Successfully");
        getAccountInfo();
        setSpinnerLoading(false);
      } else {
        setSpinnerLoading(false);
        toast.error("Error Occurred");
      }
    } catch (err) {
      setSpinnerLoading(false);
      console.log(err);
    }
  };
  const getAccountInfo = async () => {
    setIsLoading(true);
    const res = await api.getAccount(0);
    const { status, data } = res;
    if (status >= 200 && status < 300) {
      setIsLoading(false);
      setRows(
        data.map((item, index) => {
          return {
            ...item,
            _id: index + 1,
          };
        })
      );
    } else {
      setIsLoading(false);
      toast.error(data.message);
    }
  };
  useEffect(() => {
    try {
      getAccountInfo();
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddAccountOpen === false]);

  return (
    <>
      {spinnerLoading && <Spinner />}
      <div>
        {/* tables */}

        <div className="w-full mt-10 data-grid">
          <div className="flex justify-between items-center mb-2">
            <h4 className=" ">Account Management</h4>
            <button
              onClick={() => setAddAccountOpen(true)}
              className="btn btn-primary"
            >
              Add Account
            </button>
          </div>
          <div className=" h-[600px] w-full">
            <DataGrid
              loading={isLoading}
              rows={rows}
              getEstimatedRowHeight={() => 52}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 8,
                  },
                },
              }}
              pageSizeOptions={[8, 10, 15]}
              slots={{
                loadingOverlay: LinearProgress,
                noRowsOverlay: CustomNoRowsOverlay,
              }}
              disableColumnMenu
              disableRowSelectionOnClick
            />
          </div>
        </div>
      </div>
      <AddBankAccount
        updateData={updateData}
        open={isAddAccountOpen}
        handleClose={() => {
          setAddAccountOpen(false);
          setUpdateData(null);
        }}
      />
    </>
  );
};

export default AccountManagement;
