import React, { useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { LinearProgress, Tooltip } from "@mui/material";
import CustomNoRowsOverlay from "../utils/styles";
import { useEffect } from "react";
import * as api from "../redux/api";
import { toast } from "react-toastify";
import { reactIcons } from "../utils/icons";
import { useSearchParams } from "react-router-dom";
import PartnerDetails from "../components/modals/PartnerDetails";
import TimeLine from "../components/modals/TimeLine";
import TransactionAmountDetails from "../components/modals/TransactionAmountDetails";
import Spinner from "../components/loaders/Spinner";
import FxFortPartnerExchange from "../contracts/FxFortPartnerExchange.json";
import { contracts, chain } from "../utils/env";
import EditPartner from "../components/modals/EditPartner";

const Partners = () => {
  const [isEditPartnerOpen, setIsEditPartnerOpen] = useState(false);
  const [partnerData, setPartnerData] = useState(null);
  const [isTimelineModalOpen, setIsTimelineModalOpen] = useState(false);
  const [timeLineData, setTimeLineData] = useState(null);
  const [spinnerLoader, setIsSpinnerLoader] = useState(false);
  const [filterId, setFilterId] = useState("");
  const [partnersName, setPartnersName] = useState([]);
  const [isTransactionAmountModalOpen, setIsTransactionAmountModalOpen] =
    useState(false);
  const [amountData, setAmountData] = useState(null);
  const [isPartnerModalOpen, setIsPartnerModalOpen] = useState(false);
  const [partnerId, setPartnerId] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const isTransactions = searchParams.get("type") === "transactions";
  const isPartners = !isTransactions;
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 8,
  });
  const [rowCountState, setRowCountState] = useState(0);
  const partnerColumns = [
    {
      field: "_id",
      headerName: "S.No.",
      type: "number",
      headerAlign: "center",
      align: "center",
      width: 80,
    },
    {
      field: "fullname",
      headerName: "Name",
      type: "string",
      flex: 2,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "mobile",
      headerName: "Phone Number",
      type: "string",
      flex: 2,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "country",
      headerName: "Country",
      type: "string",
      flex: 2,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "amount",
      headerName: "Amount",
      type: "number",
      flex: 2,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "currency",
      headerName: "Currency",
      type: "string",
      flex: 2,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "pendingRequest",
      headerName: "Pending Request",
      type: "string",
      flex: 2,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: "Status",
      type: "string",
      width: 180,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div className="flex gap-2 justify-center items-center">
            <>
              <div className="form-select-custom h-[40px]">
                <select
                  defaultValue={params.row.status}
                  name={params.row.partnerId}
                  id={params.row.partnerId}
                  value={params.row.status}
                  onChange={(e) => handleStatusChange(e, params.row.partnerId)}
                  className="h-[40px]"
                  // disabled={params.row.status === "Approved" ? true : false}
                >
                  <option value={"Pending"} disabled={true}>
                    Pending
                  </option>
                  {["Approved", "Rejected"].map((item) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
                </select>
                <span className="ay-center right-2 text-zinc-500 text-[22px] pointer-events-none ">
                  {reactIcons.arrowDown}
                </span>
              </div>
            </>
          </div>
        );
      },
    },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="flex gap-2 justify-center items-center">
            <span
              role="button"
              onClick={() => {
                setPartnerData(params.row);
                setIsEditPartnerOpen(true);
              }}
              className="w-8 h-8 text-[16px] flex-shrink-0 flex justify-center items-center rounded-md  bg-gray-200 hover:bg-gray-300"
            >
              {reactIcons.edit}
            </span>
            <span
              role="button"
              onClick={() => {
                setPartnerId(params.row.userId);
                setIsPartnerModalOpen(true);
              }}
              className="w-8 h-8 text-[16px] flex-shrink-0 flex justify-center items-center rounded-md  bg-gray-200 hover:bg-gray-300"
            >
              {reactIcons.eyeVisible}
            </span>

            <span
              role="button"
              onClick={() => {
                setSearchParams({ type: "transactions" });
                setPaginationModel({
                  page: 0,
                  pageSize: 8,
                });
                setFilterId(params.row.partnerId);
              }}
              className="w-8 h-8 text-[16px] flex-shrink-0 flex justify-center items-center rounded-md  bg-gray-200 hover:bg-gray-300"
            >
              {reactIcons.transactions}
            </span>
          </div>
        );
      },
    },
  ];
  const transactionsColumns = [
    {
      field: "_id",
      headerName: "S.No.",
      type: "number",
      headerAlign: "center",
      align: "center",
      width: 80,
    },
    {
      field: "fullname",
      headerName: "Name",
      type: "string",
      flex: 2,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "createdAt",
      headerName: "Request Date",
      type: "dateTime",
      flex: 3,
      headerAlign: "center",
      align: "center",
      valueGetter: ({ value }) => value && new Date(value),
    },

    {
      field: "amount",
      headerName: "Amount",
      type: "number",
      flex: 2,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "currency",
      headerName: "Currency",
      type: "string",
      flex: 2,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "mode",
      headerName: "Mode",
      type: "string",
      flex: 2,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div className="flex gap-2 justify-center items-center">
            {params.row.currency === "USDT" ? "Online" : "Offline"}
          </div>
        );
      },
    },
    {
      field: "type",
      headerName: "Transaction Type",
      type: "string",
      flex: 2,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "transactionStatus",
      headerName: "Transaction Status",
      type: "string",
      flex: 2,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: "Status",
      type: "string",
      flex: 3,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div className="flex gap-2 justify-center items-center">
            <>
              <div className="form-select-custom h-[40px]">
                <select
                  defaultValue={params.row.status}
                  name={params.row.partnerId}
                  id={params.row.partnerId}
                  value={params.row.status}
                  disabled={params.row.status === "Completed"}
                  onChange={(e) =>
                    handlePurchaseStatusChange(
                      e,
                      params.row.id,
                      params.row.walletAddress,
                      params.row.fxf,
                      params.row.type,
                      params.row.currency
                    )
                  }
                  className="h-[40px]"
                >
                  <option value={"Pending"} disabled={true}>
                    Pending
                  </option>
                  {[
                    "Approved",
                    "UnderReview",
                    "Processed",
                    "Rejected",
                    "Declined",
                    "Completed",
                  ].map((item) => (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  ))}
                </select>
                <span className="ay-center right-2 text-zinc-500 text-[22px] pointer-events-none ">
                  {reactIcons.arrowDown}
                </span>
              </div>
            </>
          </div>
        );
      },
    },

    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      flex: 3,
      renderCell: (params) => {
        return (
          <div className="flex gap-2 justify-center items-center">
            <Tooltip
              title={<div className="tooltip-box">TimeLine</div>}
              enterTouchDelay={0}
            >
              <span
                onClick={() => {
                  setIsTimelineModalOpen(true);
                  setTimeLineData(params.row);
                }}
                role="button"
                className="w-10 h-10 flex-shrink-0 flex justify-center items-center rounded-md text-[18px] bg-gray-200 hover:bg-gray-300"
              >
                {reactIcons.timeline}
              </span>
            </Tooltip>
            <span
              role="button"
              onClick={() => {
                setIsTransactionAmountModalOpen(true);
                setTimeLineData(params.row);
                setAmountData(params.row);
              }}
              className="w-10 h-10 flex-shrink-0 flex justify-center items-center rounded-md text-[18px] bg-gray-200 hover:bg-gray-300"
            >
              {reactIcons.eyeVisible}
            </span>
          </div>
        );
      },
    },
  ];

  const enableEthereum = async () => {
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    window.web3 = new window.Web3(window.ethereum);
    return accounts[0];
  };

  const handleStatusChange = async (e, partnerId) => {
    setIsSpinnerLoader(true);
    try {
      const res = await api.changePartnerStatus(partnerId, {
        status: e.target.value,
      });
      const { status, data } = res;
      if (status >= 200 && status < 300) {
        toast.success("Changed Successfully");
        getPartnersInfo();
      } else {
        getPartnersInfo();
        toast.error(data.message);
      }
    } catch (err) {
      getPartnersInfo();
      toast.error(err.response.data.message);
    } finally {
      setIsSpinnerLoader(false);
    }
  };

  const handlePurchaseStatusChange = async (
    e,
    id,
    wallet,
    amountValue,
    type,
    currency
  ) => {
    setIsSpinnerLoader(true);
    try {
      let transactionType = 0n;
      if (type === "Buy") {
        transactionType = 0n;
      } else {
        transactionType = 1n;
      }

      if (e.target.value === "Completed" && currency === "USDT") {
        // const BN = window.web3.utils.BN;
        // let amount = amountValue;
        // amount = new BN(window.ethers.utils.parseUnits(amount, 6).toString());

        // console.log(amount,'amount')

        // if (Number(amountValue) > Number(balance)) {
        //   toast.error("You do not have enough  USDFx");
        //   setIsLoading(false);
        //   return;
        // }

        const account = await enableEthereum();

        const contract = new window.web3.eth.Contract(
          FxFortPartnerExchange,
          contracts[process.env.REACT_APP_NETWORK].partnerExchange
        );

        const transfer = await contract.methods
          .settleRequest(wallet, transactionType, true)
          .send({ from: account })
          .catch((error) => console.log(error, "error"));
        if (transfer?.status) {
          const res = await api.purchaseTransactionStatus(id, {
            status: "Completed",
            chain: chain,
          });
          const { status, data } = res;
          if (status >= 200 && status < 300) {
            toast.success("Changed Successfully");
            getPartnersTransactions();
          } else {
            getPartnersTransactions();
            toast.error(data.message);
          }
        } else {
          toast.error("Token Transaction Failed");
        }
      } else if (e.target.value === "Rejected" && currency === "USDT") {
        // const BN = window.web3.utils.BN;
        // let amount = amountValue;
        // amount = new BN(window.ethers.utils.parseUnits(amount, 6).toString());

        // console.log(amount,'amount')

        // if (Number(amountValue) > Number(balance)) {
        //   toast.error("You do not have enough  USDFx");
        //   setIsLoading(false);
        //   return;
        // }
        const account = await enableEthereum();
        const contract = new window.web3.eth.Contract(
          FxFortPartnerExchange,
          contracts[process.env.REACT_APP_NETWORK].partnerExchange
        );

        const transfer = await contract.methods
          .settleRequest(wallet, transactionType, false)
          .send({ from: account })
          .catch((error) => console.log(error, "error"));
        if (transfer?.status) {
          const res = await api.purchaseTransactionStatus(id, {
            status: "Rejected",
            chain: chain,
          });
          const { status, data } = res;
          if (status >= 200 && status < 300) {
            toast.success("Changed Successfully");
            getPartnersTransactions();
          } else {
            getPartnersTransactions();
            toast.error(data.message);
          }
        } else {
          toast.error("Token Transaction Failed");
        }
      } else {
        const res = await api.purchaseTransactionStatus(id, {
          status: e.target.value,
          chain: chain,
        });
        const { status, data } = res;
        if (status >= 200 && status < 300) {
          toast.success("Changed Successfully");
          getPartnersTransactions();
        } else {
          getPartnersTransactions();
          toast.error(data.message);
        }
      }
    } catch (err) {
      console.log(err, "err");
      toast.error(err.response?.data?.message);
      getPartnersTransactions();
    } finally {
      setIsSpinnerLoader(false);
    }
  };

  const getPartnersInfo = async () => {
    setIsLoading(true);
    const res = await api.getPartners(paginationModel.page);
    const { status, data } = res;
    if (status >= 200 && status < 300) {
      setIsLoading(false);
      setRowCountState(data.count);
      setRows(
        data.data.map((item, index) => {
          return {
            ...item,
            _id: index + 1,
            fullname: item.partner.fullname,
            email: item.partner.email,
            country: item.partner.country,
            mobile: item.partner.mobile,
            pendingRequest: item.pendingRequest,
            userId: item.partner.userId,
            partnerId: item.partnerId,
            status: item.partner.status,
            whatsapp: item.partner.whatsapp,
            telegram: item.partner.telegram,
          };
        })
      );
      setPartnersName(
        data.data.map((item, index) => {
          return {
            fullname: item.partner.fullname,
            partnerId: item.partnerId,
          };
        })
      );
    } else {
      setIsLoading(false);
      toast.error(data.message);
    }
  };
  const getPartnersInfoName = async () => {
    setIsLoading(true);
    const res = await api.getPartners(paginationModel.page);
    const { status, data } = res;
    if (status >= 200 && status < 300) {
      setIsLoading(false);
      setPartnersName(
        data.data.map((item, index) => {
          return {
            fullname: item.partner.fullname,
            partnerId: item.partnerId,
          };
        })
      );
    } else {
      setIsLoading(false);
      toast.error(data.message);
    }
  };
  const getPartnersTransactions = async () => {
    setIsLoading(true);
    const res = await api.getPartnersTransactions(
      paginationModel.page,
      filterId
    );
    const { status, data } = res;
    if (status >= 200 && status < 300) {
      setIsLoading(false);
      setRowCountState(data.count);
      setRows(
        data.data.map((item, index) => {
          return {
            ...item,
            _id: index + 1,
            fullname: item.partner.fullname,
            email: item.partner.email,
            country: item.partner.country,
            mobile: item.partner.mobile,
            pendingRequest: item.pendingRequest,
            userId: item.partner.userId,
            partnerId: item.partnerId,
            walletAddress: item.partner.user.wallets[0].address,
          };
        })
      );
    } else {
      setIsLoading(false);
      toast.error(data.message);
    }
  };

  useEffect(() => {
    if (isPartners) {
      setColumns(partnerColumns);
      getPartnersInfo();
    } else {
      setColumns(transactionsColumns);
      getPartnersTransactions();
    }
    setRowCountState((prevRowCountState) =>
      rowCountState !== undefined ? rowCountState : prevRowCountState
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isPartners,
    searchParams,
    paginationModel,
    rowCountState,
    setRowCountState,
    filterId,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    isEditPartnerOpen === false,
  ]);
  useEffect(() => {
    getPartnersInfoName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {spinnerLoader && <Spinner />}
      <div>
        <div className="w-full data-grid">
          <div className="flex justify-between items-center mb-2">
            <h4 className=" ">
              {isTransactions ? "Transactions" : "Partners"}
            </h4>
          </div>
          <div className="flex gap-6 items-center mb-4">
            <button
              className={`btn ${
                isPartners ? "btn-primary" : "btn-outline-primary"
              }`}
              onClick={() => {
                setSearchParams({});
                setPaginationModel({
                  page: 0,
                  pageSize: 8,
                });
              }}
            >
              Partners
            </button>
            <button
              className={`btn ${
                isTransactions ? "btn-primary" : "btn-outline-primary"
              }`}
              onClick={() => {
                setSearchParams({ type: "transactions" });
                setPaginationModel({
                  page: 0,
                  pageSize: 8,
                });
              }}
            >
              Partners Transactions
            </button>
            {isTransactions && (
              <div className="form-select-custom w-[250px] ml-auto ">
                <select
                  value={filterId}
                  onChange={(e) => setFilterId(e.target.value)}
                >
                  <option value={""}>All</option>
                  {partnersName?.map((item) => (
                    <option value={item.partnerId} key={item}>
                      {item?.fullname}
                    </option>
                  ))}
                </select>
                <span className="ay-center right-2 text-zinc-500 text-[22px] pointer-events-none ">
                  {reactIcons.arrowDown}
                </span>
              </div>
            )}
          </div>
          <div className=" h-[600px] w-full">
            <DataGrid
              loading={isLoading}
              rows={rows}
              getEstimatedRowHeight={() => 52}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 8,
                  },
                },
              }}
              rowCount={rowCountState}
              pageSizeOptions={[8]}
              paginationModel={paginationModel}
              paginationMode="server"
              onPaginationModelChange={setPaginationModel}
              slots={{
                toolbar: GridToolbar,
                loadingOverlay: LinearProgress,
                noRowsOverlay: CustomNoRowsOverlay,
              }}
              disableColumnMenu
              disableRowSelectionOnClick
            />
          </div>
        </div>
      </div>
      <PartnerDetails
        open={isPartnerModalOpen}
        handleClose={() => {
          setIsPartnerModalOpen(false);
          setPartnerId(null);
        }}
        id={partnerId}
      />
      <TimeLine
        open={isTimelineModalOpen}
        handleClose={() => {
          setIsTimelineModalOpen(false);
        }}
        data={timeLineData}
      />
      <TransactionAmountDetails
        open={isTransactionAmountModalOpen}
        setIsTimelineModalOpen={setIsTimelineModalOpen}
        handleClose={() => {
          setIsTransactionAmountModalOpen(false);
        }}
        data={amountData}
      />
      <EditPartner
        open={isEditPartnerOpen}
        handleClose={() => {
          setIsEditPartnerOpen(false);
          setPartnerData(null);
        }}
        data={partnerData}
      />
    </>
  );
};

export default Partners;
