import { Collapse } from "@mui/material";
import React, { useEffect, useState } from "react";
import Spinner from "../components/loaders/Spinner";
import Airdrop from "../contracts/FxFortAirdrop.json";
import FxFortTokenExchange from "../contracts/FxFortTokenExchange.json";
import FxFortPartnerExchange from "../contracts/FxFortPartnerExchange.json";
import ERC20 from "../contracts/ERC20.json";
import { contracts } from "../utils/env";
import { toast } from "react-toastify";
import { reactIcons } from "./../utils/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import * as api from "../redux/api";
let temp = true;
const initialShowExchange = {
  baseToken: false,
  changeExchangeRate: false,
  changeExchangeToken: false,
  changeInterestRate: false,
  withdrawBaseTokens: false,
  withdrawExchangeTokens: false,
};
const initialShowPartnerExchange = {
  baseToken: false,
  changeExchangeRate: false,
  changeExchangeToken: false,
  changeInterestRate: false,
  withdrawBaseTokens: false,
  withdrawExchangeTokens: false,
};
const Setting = () => {
  const [form, setForm] = useState({
    telegram: "",
    discord: "",
    facebook: "",
  });
  const [socialData, setSocialData] = useState(null);
  const handleSubmit = async () => {
    try {
      const res = await api.addSocial(form);
      const { status, data } = res;
      if (status >= 200 && status < 300) {
        setIsLoading(false);
        toast.success("Added Successfully");
      } else {
        setIsLoading(false);
        toast.error(data.message);
      }
    } catch (error) {
      console.log(error, "error");
      toast.error(error.data.message);
      setIsLoading(false);
    }
  };
  const getSocialData = async () => {
    try {
      const res = await api.getSocial();
      console.log(res, "res");
      const { status, data } = res;
      if (status >= 200 && status < 300) {
        setIsLoading(false);
        setSocialData(res?.data?.data);
      } else {
        setIsLoading(false);
        toast.error(data.message);
      }
    } catch (error) {
      console.log(error, "error");
      toast.error(error.data.message);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getSocialData();
  }, []);
  useEffect(() => {
    if (socialData !== null) {
      setForm({
        telegram: socialData?.telegram,
        discord: socialData?.discord,
        facebook: socialData?.facebook,
      });
    }
  }, [socialData]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };
  // for user
  const [eyesToggle, setEyesToggle] = useState({
    airdropBalFxf: false,
    exchangeBalFxf: false,
    exchangeFxfAdd: false,
    exchangeRate: false,
    exchangeBalUSDT: false,
    exchangeIntRate: false,
    exchangeMRA: false,
    exchangeUSDTAdd: false,
    partnersBal: false,
  });
  const [switchToggle, setSwitchToggle] = useState({
    airdropBalFxf: false,
    partnersBal: false,
    exchangeBalFxf: false,
    exchangeFxfAdd: false,
    exchangeRate: false,
    exchangeBalUSDT: false,
    exchangeIntRate: false,
    exchangeMRA: false,
    exchangeUSDTAdd: false,
  });
  // for partner
  const [eyesPartnerToggle, setEyesPartnerToggle] = useState({
    airdropBalFxf: false,
    exchangeBalFxf: false,
    exchangeFxfAdd: false,
    exchangeRate: false,
    exchangeBalUSDT: false,
    exchangeIntRate: false,
    exchangeMRA: false,
    exchangeUSDTAdd: false,
    partnersBal: false,
  });
  const [partnerSwitchToggle, setPartnerSwitchToggle] = useState({
    airdropBalFxf: false,
    partnersBal: false,
    exchangeBalFxf: false,
    exchangeFxfAdd: false,
    exchangeRate: false,
    exchangeBalUSDT: false,
    exchangeIntRate: false,
    exchangeMRA: false,
    exchangeUSDTAdd: false,
  });
  const handleSwitchChange = (name) => {
    setSwitchToggle({ ...switchToggle, [name]: !switchToggle[name] });
  };
  const handlePartnerSwitchChange = (name) => {
    setPartnerSwitchToggle({
      ...partnerSwitchToggle,
      [name]: !partnerSwitchToggle[name],
    });
  };
  const [isLoading, setIsLoading] = useState(false);
  const [partnersBal, setPartnersBal] = useState(null);
  const [showAddToken, setShowAddToken] = useState(false);
  const [showExchangeToken, setShowExchangeToken] =
    useState(initialShowExchange);
  const [tokenValue, setTokenValue] = useState("0");
  const [airDropDetails, setAirDropDetails] = useState({});
  const [exchangeDetails, setExchangeDetails] = useState({});
  // for partner
  const [partnerTokenValue, setPartnerTokenValue] = useState("0");
  const [showPartnerExchangeToken, setShowPartnerExchangeToken] = useState(
    initialShowPartnerExchange
  );
  const [partnerExchangeDetails, setPartnerExchangeDetails] = useState({});
  const web3 = window.web3;
  const ethers = window.ethers;
  const getAirDropDetails = async () => {
    const contract = new window.web3.eth.Contract(
      Airdrop,
      contracts[process.env.REACT_APP_NETWORK].tokenAirdrop
    );
    const balance = await contract.methods
      .balance()
      .call()
      .catch((error) => {
        console.log(error);
      });

    setAirDropDetails((prev) => {
      return {
        ...prev,
        airDropBalanceOfToken: Number(
          ethers.utils.formatUnits(balance, 6)
        ).toFixed(4),
      };
    });
    const address = await contract.methods
      .token()
      .call()
      .catch((error) => {
        console.log(error);
      });
    setAirDropDetails((prev) => {
      return {
        ...prev,
        airDropBaseToken: address,
      };
    });
  };

  const getPartnersInfoBal = async () => {
    const contract = new window.web3.eth.Contract(
      ERC20,
      contracts[process.env.REACT_APP_NETWORK].fxfToken
    );

    const balance = await contract.methods
      .balanceOf(contracts[process.env.REACT_APP_NETWORK].PlatformAdminAddress)
      .call()
      .catch((error) => {
        console.log(error);
      });
console.log(balance, 'balance')
    setPartnersBal(Number(web3.utils.fromWei(balance)).toFixed(2));
  };

  const getExchangeDetails = async () => {
    const contract = new window.web3.eth.Contract(
      FxFortTokenExchange,
      contracts[process.env.REACT_APP_NETWORK].tokenExchange
    );
    const exchangeRateValue = await contract.methods
      .exchangeRate()
      .call()
      .catch((error) => {
        console.log(error);
      });
    setExchangeDetails((prev) => {
      return {
        ...prev,
        exchangeRateValue: Number(
          ethers.utils.formatUnits(exchangeRateValue, 6)
        ).toFixed(4),
      };
    });
    const balance = await contract.methods
      .balanceOfBaseToken()
      .call()
      .catch((error) => {
        console.log(error);
      });
    setExchangeDetails((prev) => {
      return {
        ...prev,
        exchangeBalanceOfToken: Number(
          ethers.utils.formatUnits(balance, 6)
        ).toFixed(4),
      };
    });
    const address = await contract.methods
      .baseToken()
      .call()
      .catch((error) => {
        console.log(error);
      });
    setExchangeDetails((prev) => {
      return {
        ...prev,
        exchangeBaseToken: address,
      };
    });
    const balanceOfExchangeTokenAddress = await contract.methods
      .balanceOfExchangeToken()
      .call()
      .catch((error) => {
        console.log(error);
      });
    setExchangeDetails((prev) => {
      return {
        ...prev,
        balanceOfExchangeToken: Number(
          ethers.utils.formatUnits(balanceOfExchangeTokenAddress, 6)
        ).toFixed(4),
      };
    });
    const minExchangeAmount = await contract.methods
      .minExchangeAmount()
      .call()
      .catch((error) => {
        console.log(error);
      });
    setExchangeDetails((prev) => {
      return {
        ...prev,
        minExchangeAmount: Number(
          web3.utils.fromWei(minExchangeAmount)
        ).toFixed(4),
      };
    });
    const exchangeTokenAddress = await contract.methods
      .exchangeToken()
      .call()
      .catch((error) => {
        console.log(error);
      });
    setExchangeDetails((prev) => {
      return {
        ...prev,
        exchangeTokenAddress: exchangeTokenAddress,
      };
    });
    const exchangeInterestRate = await contract.methods
      .exchangeFees()
      .call()
      .catch((error) => {
        console.log(error);
      });
    setExchangeDetails((prev) => {
      return {
        ...prev,
        interestRate: Number(
          ethers.utils.formatUnits(exchangeInterestRate, 6)
        ).toFixed(4),
      };
    });
  };

  const getPartnerExchangeDetails = async () => {
    const contract = new window.web3.eth.Contract(
      FxFortPartnerExchange,
      contracts[process.env.REACT_APP_NETWORK].partnerExchange
    );

    const exchangeRateValue = await contract.methods
      .exchangeRate()
      .call()
      .catch((error) => {
        console.log(error);
      });
    setPartnerExchangeDetails((prev) => {
      return {
        ...prev,
        exchangeRateValue: Number(
          ethers.utils.formatUnits(exchangeRateValue, 6)
        ).toFixed(4),
      };
    });
    const balance = await contract.methods
      .balanceOfBaseToken()
      .call()
      .catch((error) => {
        console.log(error);
      });
    setPartnerExchangeDetails((prev) => {
      return {
        ...prev,
        exchangeBalanceOfToken: Number(
          ethers.utils.formatUnits(balance, 6)
        ).toFixed(4),
      };
    });
    const address = await contract.methods
      .baseToken()
      .call()
      .catch((error) => {
        console.log(error);
      });
    setPartnerExchangeDetails((prev) => {
      return {
        ...prev,
        exchangeBaseToken: address,
      };
    });
    const balanceOfExchangeTokenAddress = await contract.methods
      .balanceOfExchangeToken()
      .call()
      .catch((error) => {
        console.log(error);
      });
    setPartnerExchangeDetails((prev) => {
      return {
        ...prev,
        balanceOfExchangeToken: Number(
          ethers.utils.formatUnits(balanceOfExchangeTokenAddress, 6)
        ).toFixed(4),
      };
    });
    const minExchangeAmount = await contract.methods
      .minExchangeAmount()
      .call()
      .catch((error) => {
        console.log(error);
      });
    setPartnerExchangeDetails((prev) => {
      return {
        ...prev,
        minExchangeAmount: Number(
          web3.utils.fromWei(minExchangeAmount)
        ).toFixed(4),
      };
    });
    const exchangeTokenAddress = await contract.methods
      .exchangeToken()
      .call()
      .catch((error) => {
        console.log(error);
      });
    setPartnerExchangeDetails((prev) => {
      return {
        ...prev,
        exchangeTokenAddress: exchangeTokenAddress,
      };
    });
    const exchangeInterestRate = await contract.methods
      // old
      // .exchangeFees()
      // new
      .exchangeRate()
      .call()
      .catch((error) => {
        console.log(error);
      });
    setPartnerExchangeDetails((prev) => {
      return {
        ...prev,
        interestRate: Number(
          ethers.utils.formatUnits(exchangeInterestRate, 6)
        ).toFixed(4),
      };
    });
  };

  const enableEthereum = async () => {
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    window.web3 = new window.Web3(window.ethereum);
    return accounts[0];
  };

  useEffect(() => {
    if (window.ethereum && window.ethereum.isMetaMask) {
      getAirDropDetails();
      getExchangeDetails();
      getPartnersInfoBal();
      getPartnerExchangeDetails();
    } else if (temp) {
      toast.error("Please install Meta Mask");
      temp = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddAirToken = async () => {
    if (tokenValue < 1) {
      toast.error("Please enter token value");
      return false;
    }
    const account = await enableEthereum();
    if (
      window.web3.utils.toChecksumAddress(account) !==
      window.web3.utils.toChecksumAddress(
        contracts[process.env.REACT_APP_NETWORK].PlatformAdminAddress
      )
    ) {
      toast.error("please connect correct wallet");
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    const contract = new window.web3.eth.Contract(
      Airdrop,
      contracts[process.env.REACT_APP_NETWORK].tokenAirdrop
    );
    const res = await contract.methods
      .addBaseTokens(account, ethers.utils.parseUnits(tokenValue, 6))
      .send({ from: account })
      .catch((error) => error);
    if (res?.message) {
      toast.error(res.message);
    } else {
      toast.success("Air Token added succesfully");
      setShowAddToken(false);
      getAirDropDetails();
    }
    setIsLoading(false);
  };

  const handleAddExchangeToken = async (
    methodName,
    isTokenExchnage = false
  ) => {
    if (methodName === "withdrawExchangeTokens") {
      if (exchangeDetails.balanceOfExchangeToken < Number(tokenValue)) {
        toast.error("Insufficient Balance");
        return;
      }
    }
    if (methodName === "withdrawBaseTokens") {
      if (exchangeDetails.exchangeBalanceOfToken < Number(tokenValue)) {
        toast.error("Insufficient Balance");
        return;
      }
    }
    if (tokenValue < 1 && isTokenExchnage) {
      toast.error("Please enter token value");
      return false;
    }
    setIsLoading(true);
    const account = await enableEthereum();
    if (
      window.web3.utils.toChecksumAddress(account) !==
      window.web3.utils.toChecksumAddress(
        contracts[process.env.REACT_APP_NETWORK].PlatformAdminAddress
      )
    ) {
      toast.error("please connect correct wallet");
      setIsLoading(false);
      return;
    }
    const contract = new window.web3.eth.Contract(
      FxFortTokenExchange,
      contracts[process.env.REACT_APP_NETWORK].tokenExchange
    );

    //dynamic  methord call
    let res;
    if (isTokenExchnage) {
      res = await contract.methods[methodName](
        account,
        ethers.utils.parseUnits(tokenValue, 6)
      )
        .send({ from: account })
        .catch((error) => error);
    } else {
      res = await contract.methods[methodName](
        ethers.utils.parseUnits(tokenValue, 6)
      )
        .send({ from: account })
        .catch((error) => error);
    }

    if (res?.message) {
      toast.error(res.message);
    } else {
      toast.success("Exchange Token added succesfully");
      setShowExchangeToken(initialShowExchange);
      getExchangeDetails();
    }
    setIsLoading(false);
  };

  const handleAddPartnerExchangeToken = async (
    methodName,
    isTokenExchnage = false
  ) => {
    if (methodName === "withdrawExchangeTokens") {
      if (
        partnerExchangeDetails.balanceOfExchangeToken <
        Number(partnerTokenValue)
      ) {
        toast.error("Insufficient Balance");
        return;
      }
    }
    if (methodName === "withdrawBaseTokens") {
      if (
        partnerExchangeDetails.exchangeBalanceOfToken <
        Number(partnerTokenValue)
      ) {
        toast.error("Insufficient Balance");
        return;
      }
    }
    if (partnerTokenValue < 1 && isTokenExchnage) {
      toast.error("Please enter token value");
      return false;
    }
    setIsLoading(true);
    const account = await enableEthereum();
    if (
      window.web3.utils.toChecksumAddress(account) !==
      window.web3.utils.toChecksumAddress(
        contracts[process.env.REACT_APP_NETWORK].PlatformAdminAddress
      )
    ) {
      toast.error("please connect correct wallet");
      setIsLoading(false);
      return;
    }
    const contract = new window.web3.eth.Contract(
      FxFortPartnerExchange,
      contracts[process.env.REACT_APP_NETWORK].partnerExchange
    );

    //dynamic  methord call
    let res;
    if (isTokenExchnage) {
      res = await contract.methods[methodName](
        account,
        ethers.utils.parseUnits(partnerTokenValue, 6)
      )
        .send({ from: account })
        .catch((error) => error);
    } else {
      res = await contract.methods[methodName](
        ethers.utils.parseUnits(partnerTokenValue, 6)
      )
        .send({ from: account })
        .catch((error) => error);
    }

    if (res?.message) {
      toast.error(res.message);
    } else {
      toast.success("Exchange Token added succesfully");
      setShowPartnerExchangeToken(initialShowExchange);
      getPartnerExchangeDetails();
    }
    setIsLoading(false);
  };
  useEffect(() => {
    setTokenValue("0");
  }, [showAddToken, showExchangeToken]);

  useEffect(() => {
    setPartnerTokenValue("0");
  }, [showPartnerExchangeToken]);
  console.log(form, "form");
  return (
    <>
      {isLoading && <Spinner />}
      <div className="grid grid-cols-1 gap-8 settings">
        <div>
          <div className="flex gap-6 items-center mb-4">
            <h4>Airdrops</h4>
            <div className="flex gap-1 items-center">
              <span className="text-xs lg:text-sm font-semibold">
                {" "}
                {contracts[process.env.REACT_APP_NETWORK].tokenAirdrop.slice(
                  0,
                  4
                ) +
                  "..." +
                  contracts[process.env.REACT_APP_NETWORK].tokenAirdrop.slice(
                    -4
                  )}
              </span>
              <CopyToClipboard
                text={contracts[process.env.REACT_APP_NETWORK].tokenAirdrop}
                onCopy={() => toast.success("Copied SuccessFully")}
              >
                <span className="mx-1 text-xl text-zinc-400 cursor-pointer w-10 h-10 rounded-lg flex-center hover:bg-zinc-100">
                  {reactIcons.copy}
                </span>
              </CopyToClipboard>
              <a
                className="underline hover:text-blue-500 text-blue-400 font-semibold text-xs  "
                href={`https://polygonscan.com/address/${
                  contracts[process.env.REACT_APP_NETWORK].tokenAirdrop
                }`}
                target="_blank"
                rel="noopener noreferrer"
              >
                View On Explorer
              </a>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <div className="grid grid-cols-1 gap-4">
              <div className="p-3 py-5 rounded-lg border border-zinc-200 ">
                <div className="flex flex-col gap-2">
                  <div className="flex gap-4 justify-between items-center">
                    <div className="flex gap-6 items-center flex-1">
                      <div className="flex gap-2 items-start w-[180px] lg:w-[200px] xl:w-[230px] flex-shrink-0">
                        <span
                          onClick={() =>
                            setEyesToggle({
                              ...eyesToggle,
                              airdropBalFxf: !eyesToggle.airdropBalFxf,
                            })
                          }
                          role="button"
                          className="text-zinc-400 text-xl lg:text-[22px] "
                        >
                          {eyesToggle.airdropBalFxf
                            ? reactIcons.eyeVisible
                            : reactIcons.eyeInVisible}
                        </span>
                        <b className="text-xs lg:text-sm xl:text-base">
                          Current USDFc token balance.
                        </b>
                      </div>
                      <div className="min-w-[120px] lg:min-w-[160px] xl:min-w-[200px]">
                        {" "}
                        {eyesToggle.airdropBalFxf ? (
                          airDropDetails?.airDropBalanceOfToken ? (
                            airDropDetails?.airDropBalanceOfToken
                          ) : (
                            <div className="text-xs lg:text-sm xl:text-base">
                              Not Available
                            </div>
                          )
                        ) : (
                          <div className="h-4 bg-zinc-200 w-[120px] lg:w-[160px] xl:w-[200px]" />
                        )}
                      </div>
                      <div className="flex gap-2 items-center">
                        {showAddToken ? (
                          <div className="flex gap-3 items-center">
                            <input
                              type="number"
                              value={tokenValue}
                              onChange={(e) => setTokenValue(e.target.value)}
                              placeholder="0"
                            />
                            <button
                              onClick={handleAddAirToken}
                              className="btn btn-sm btn-sm-width btn-primary flex-shrink-0"
                            >
                              Add USDFc
                            </button>
                            <button
                              onClick={() => setShowAddToken(false)}
                              className="btn btn-sm-width btn-sm btn-danger"
                            >
                              Cancel
                            </button>
                          </div>
                        ) : (
                          <div className="flex gap-2">
                            <button
                              onClick={() => setShowAddToken(true)}
                              className="btn btn-sm btn-primary"
                            >
                              Add Base Token
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="flex gap-2 items-center">
                      <div
                        onClick={() => handleSwitchChange("airdropBalFxf")}
                        className="text-xs xl:text-sm font-semibold cursor-pointer gap-1 flex-center"
                      >
                        <span className="flex-shrink-0 text-black/80">
                          Read More{" "}
                        </span>
                        <span className="text-2xl">
                          {switchToggle.airdropBalFxf
                            ? reactIcons.arrowUp
                            : reactIcons.arrowDown}
                        </span>
                      </div>
                    </div>
                  </div>
                  <Collapse in={switchToggle.airdropBalFxf}>
                    <p className="text-sm font-semibold text-zinc-600">
                      USDFc Token balance of Admin
                    </p>
                  </Collapse>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div></div>
        <div>
          <div className="flex gap-6 items-center mb-4">
            <h4>User Exchange</h4>
            <div className="flex gap-1 items-center">
              <span className="text-xs lg:text-sm font-semibold">
                {" "}
                {contracts[process.env.REACT_APP_NETWORK].tokenExchange.slice(
                  0,
                  4
                ) +
                  "..." +
                  contracts[process.env.REACT_APP_NETWORK].tokenExchange.slice(
                    -4
                  )}
              </span>
              <CopyToClipboard
                text={contracts[process.env.REACT_APP_NETWORK].tokenExchange}
                onCopy={() => toast.success("Copied SuccessFully")}
              >
                <span className="mx-1 text-xl text-zinc-400 cursor-pointer w-10 h-10 rounded-lg flex-center hover:bg-zinc-100">
                  {reactIcons.copy}
                </span>
              </CopyToClipboard>
              <a
                className="underline hover:text-blue-500 text-blue-400 font-semibold text-xs  "
                href={`https://polygonscan.com/address/${
                  contracts[process.env.REACT_APP_NETWORK].tokenExchange
                }`}
                target="_blank"
                rel="noopener noreferrer"
              >
                View On Explorer
              </a>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-4">
            <div className="p-3 py-5 rounded-lg border border-zinc-200 ">
              <h5 className="mb-6 text-black/80">Token Addresses</h5>
              <div className="space-y-6">
                <div className="flex flex-col gap-2">
                  <div className="flex gap-4 justify-between items-center">
                    <div className="flex gap-6 items-center">
                      <div className="flex gap-2 items-center w-[180px] lg:w-[200px] xl:w-[230px] flex-shrink-0">
                        <span
                          onClick={() =>
                            setEyesToggle({
                              ...eyesToggle,
                              exchangeFxfAdd: !eyesToggle.exchangeFxfAdd,
                            })
                          }
                          role="button"
                          className="text-zinc-400 text-xl lg:text-[22px] "
                        >
                          {eyesToggle.exchangeFxfAdd
                            ? reactIcons.eyeVisible
                            : reactIcons.eyeInVisible}
                        </span>
                        <b className="text-xs lg:text-sm xl:text-base">
                          USDFc Address
                        </b>
                      </div>
                      <div>
                        {eyesToggle.exchangeFxfAdd ? (
                          exchangeDetails?.exchangeBaseToken ? (
                            <div className="flex gap-1 items-center">
                              <span className="text-xs lg:text-sm font-semibold">
                                {" "}
                                {exchangeDetails?.exchangeBaseToken.slice(
                                  0,
                                  4
                                ) +
                                  "..." +
                                  exchangeDetails?.exchangeBaseToken.slice(-4)}
                              </span>
                              <CopyToClipboard
                                text={exchangeDetails?.exchangeBaseToken}
                                onCopy={() =>
                                  toast.success("Copied SuccessFully")
                                }
                              >
                                <span className="mx-1 text-xl text-zinc-400 cursor-pointer w-10 h-10 rounded-lg flex-center hover:bg-zinc-100">
                                  {reactIcons.copy}
                                </span>
                              </CopyToClipboard>
                              <a
                                className="underline hover:text-blue-500 text-blue-400 font-semibold text-xs  "
                                href={`https://polygonscan.com/address/${exchangeDetails?.exchangeBaseToken}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                View On Explorer
                              </a>
                            </div>
                          ) : (
                            <div className="text-xs lg:text-sm xl:text-base">
                              Not Available
                            </div>
                          )
                        ) : (
                          <div className="h-4 bg-zinc-200 w-[120px] lg:w-[160px] xl:w-[200px]" />
                        )}
                      </div>
                    </div>
                    <div className="flex gap-2 items-center">
                      <div
                        onClick={() => handleSwitchChange("exchangeFxfAdd")}
                        className="text-xs xl:text-sm font-semibold cursor-pointer gap-1 flex-center"
                      >
                        <span className="flex-shrink-0 text-black/80">
                          Read More{" "}
                        </span>
                        <span className="text-2xl">
                          {switchToggle.exchangeFxfAdd
                            ? reactIcons.arrowUp
                            : reactIcons.arrowDown}
                        </span>
                      </div>
                    </div>
                  </div>

                  <Collapse in={switchToggle.exchangeFxfAdd}>
                    <p className="text-sm font-semibold text-zinc-600">
                      USDFc Exchange Address
                    </p>
                  </Collapse>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex gap-4 justify-between items-center">
                    <div className="flex gap-6 items-center">
                      <div className="flex gap-2 items-center w-[180px] lg:w-[200px] xl:w-[230px] flex-shrink-0">
                        <span
                          onClick={() =>
                            setEyesToggle({
                              ...eyesToggle,
                              exchangeUSDTAdd: !eyesToggle.exchangeUSDTAdd,
                            })
                          }
                          role="button"
                          className="text-zinc-400 text-xl lg:text-[22px] "
                        >
                          {eyesToggle.exchangeUSDTAdd
                            ? reactIcons.eyeVisible
                            : reactIcons.eyeInVisible}
                        </span>
                        <b className="text-xs lg:text-sm xl:text-base">
                          USDT Address
                        </b>
                      </div>

                      <div>
                        {" "}
                        {eyesToggle.exchangeUSDTAdd ? (
                          exchangeDetails?.exchangeTokenAddress ? (
                            <div className="flex gap-1 items-center">
                              <span className="text-xs lg:text-sm font-semibold">
                                {" "}
                                {exchangeDetails?.exchangeTokenAddress.slice(
                                  0,
                                  4
                                ) +
                                  "..." +
                                  exchangeDetails?.exchangeTokenAddress.slice(
                                    -4
                                  )}
                              </span>
                              <CopyToClipboard
                                text={exchangeDetails?.exchangeTokenAddress}
                                onCopy={() =>
                                  toast.success("Copied SuccessFully")
                                }
                              >
                                <span className="mx-1 text-xl text-zinc-400 cursor-pointer w-10 h-10 rounded-lg flex-center hover:bg-zinc-100">
                                  {reactIcons.copy}
                                </span>
                              </CopyToClipboard>
                              <a
                                className="underline hover:text-blue-500 text-blue-400 font-semibold text-xs  "
                                href={`https://polygonscan.com/address/${exchangeDetails?.exchangeTokenAddress}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                View On Explorer
                              </a>
                            </div>
                          ) : (
                            <div className="text-xs lg:text-sm xl:text-base">
                              Not Available
                            </div>
                          )
                        ) : (
                          <div className="h-4 bg-zinc-200 w-[120px] lg:w-[160px] xl:w-[200px]" />
                        )}
                      </div>
                      {showExchangeToken?.changeExchangeToken ? (
                        <div className="flex gap-6 items-center">
                          <input
                            type="number"
                            value={tokenValue}
                            onChange={(e) => setTokenValue(e.target.value)}
                            placeholder="0"
                          />
                          <button
                            onClick={() =>
                              handleAddExchangeToken(
                                "changeExchangeToken",
                                false
                              )
                            }
                            className="btn btn-sm-width btn-sm btn-primary flex-shrink-0"
                          >
                            Change
                          </button>
                          <button
                            onClick={() =>
                              setShowExchangeToken({
                                ...initialShowExchange,
                                changeExchangeToken: false,
                              })
                            }
                            className="btn btn-sm btn-danger"
                          >
                            Cancel
                          </button>
                        </div>
                      ) : (
                        <span
                          onClick={() =>
                            setShowExchangeToken({
                              ...initialShowExchange,
                              changeExchangeToken: true,
                            })
                          }
                          className="btn btn-sm btn-primary"
                        >
                          Change Exchange Token
                        </span>
                      )}
                    </div>
                    <div className="flex gap-2 items-center">
                      <div
                        onClick={() => handleSwitchChange("exchangeUSDTAdd")}
                        className="text-xs xl:text-sm font-semibold cursor-pointer gap-1 flex-center"
                      >
                        <span className="flex-shrink-0 text-black/80">
                          Read More{" "}
                        </span>
                        <span className="text-2xl">
                          {switchToggle.exchangeUSDTAdd
                            ? reactIcons.arrowUp
                            : reactIcons.arrowDown}
                        </span>
                      </div>
                    </div>
                  </div>
                  <Collapse in={switchToggle.exchangeUSDTAdd}>
                    {" "}
                    <p className="text-sm font-semibold text-zinc-600">
                      USDT Address
                    </p>
                  </Collapse>
                </div>
              </div>
            </div>
            <div className="p-3 py-5 rounded-lg border border-zinc-200 ">
              <h5 className="mb-6 text-black/80">Token Balances</h5>
              <div className="space-y-6">
                <div className="flex flex-col gap-2">
                  <div className="flex gap-4 justify-between items-center">
                    <div className="flex gap-6 items-center">
                      <div className="flex gap-2 items-center w-[180px] lg:w-[200px] xl:w-[230px] flex-shrink-0">
                        <span
                          onClick={() =>
                            setEyesToggle({
                              ...eyesToggle,
                              exchangeBalFxf: !eyesToggle.exchangeBalFxf,
                            })
                          }
                          role="button"
                          className="text-zinc-400 text-xl lg:text-[22px] "
                        >
                          {eyesToggle.exchangeBalFxf
                            ? reactIcons.eyeVisible
                            : reactIcons.eyeInVisible}
                        </span>
                        <b className="text-xs lg:text-sm xl:text-base">
                          Balance of USDFc
                        </b>
                      </div>
                      <div className="min-w-[120px] lg:min-w-[160px] xl:min-w-[200px]">
                        {" "}
                        {eyesToggle.exchangeBalFxf ? (
                          exchangeDetails?.exchangeBalanceOfToken ? (
                            exchangeDetails?.exchangeBalanceOfToken
                          ) : (
                            <div className="text-xs lg:text-sm xl:text-base">
                              Not Available
                            </div>
                          )
                        ) : (
                          <div className="h-4 bg-zinc-200 w-[120px] lg:w-[160px] xl:w-[200px]" />
                        )}
                      </div>
                      {showExchangeToken?.addBaseTokens ? (
                        <div className="flex gap-2 items-center">
                          <input
                            type="number"
                            value={tokenValue}
                            onChange={(e) => setTokenValue(e.target.value)}
                            placeholder="0"
                          />
                          <button
                            onClick={() =>
                              handleAddExchangeToken("addBaseTokens", true)
                            }
                            className="btn btn-sm-width btn-sm btn-primary flex-shrink-0"
                          >
                            Add
                          </button>
                          <button
                            onClick={() =>
                              setShowExchangeToken({
                                ...initialShowExchange,
                                showExchangeToken: false,
                              })
                            }
                            className="btn btn-sm-width btn-sm btn-danger"
                          >
                            Cancel
                          </button>
                        </div>
                      ) : (
                        <span
                          onClick={() =>
                            setShowExchangeToken({
                              ...initialShowExchange,
                              addBaseTokens: true,
                            })
                          }
                          className="btn btn-sm-width btn-sm btn-primary"
                        >
                          Add
                        </span>
                      )}
                      {showExchangeToken?.withdrawBaseTokens ? (
                        <div className="flex gap-6 items-center">
                          <input
                            type="number"
                            value={tokenValue}
                            onChange={(e) => setTokenValue(e.target.value)}
                            placeholder="0"
                          />
                          <button
                            onClick={() =>
                              handleAddExchangeToken("withdrawBaseTokens", true)
                            }
                            className="btn btn-sm-width btn-sm btn-primary flex-shrink-0"
                          >
                            Withdraw
                          </button>
                          <button
                            onClick={() =>
                              setShowExchangeToken({
                                ...initialShowExchange,
                                withdrawBaseTokens: false,
                              })
                            }
                            className="btn btn-sm-width btn-sm btn-danger"
                          >
                            Cancel
                          </button>
                        </div>
                      ) : (
                        <span
                          onClick={() =>
                            setShowExchangeToken({
                              ...initialShowExchange,
                              withdrawBaseTokens: true,
                            })
                          }
                          className="btn btn-sm-width btn-sm btn-primary"
                        >
                          Withdraw
                        </span>
                      )}
                    </div>
                    <div className="flex gap-2 items-center">
                      <div
                        onClick={() => handleSwitchChange("exchangeBalFxf")}
                        className="text-xs xl:text-sm font-semibold cursor-pointer gap-1 flex-center"
                      >
                        <span className="flex-shrink-0 text-black/80">
                          Read More{" "}
                        </span>
                        <span className="text-2xl">
                          {switchToggle.exchangeBalFxf
                            ? reactIcons.arrowUp
                            : reactIcons.arrowDown}
                        </span>
                      </div>
                    </div>
                  </div>
                  <Collapse in={switchToggle.exchangeBalFxf}>
                    <p className="text-sm font-semibold text-zinc-600">
                      Add USDFc Exchange Balance
                    </p>
                  </Collapse>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex gap-4 justify-between items-center">
                    <div className="flex gap-6 items-center">
                      <div className="flex gap-2 items-center w-[180px] lg:w-[200px] xl:w-[230px] flex-shrink-0">
                        <span
                          onClick={() =>
                            setEyesToggle({
                              ...eyesToggle,
                              exchangeBalUSDT: !eyesToggle.exchangeBalUSDT,
                            })
                          }
                          role="button"
                          className="text-zinc-400 text-xl lg:text-[22px] "
                        >
                          {eyesToggle.exchangeBalUSDT
                            ? reactIcons.eyeVisible
                            : reactIcons.eyeInVisible}
                        </span>
                        <b className="text-xs lg:text-sm xl:text-base">
                          Balance Of USDT
                        </b>
                      </div>
                      <div className="min-w-[120px] lg:min-w-[160px] xl:min-w-[200px]">
                        {" "}
                        {eyesToggle.exchangeBalUSDT ? (
                          exchangeDetails?.balanceOfExchangeToken ? (
                            exchangeDetails?.balanceOfExchangeToken
                          ) : (
                            <div className="text-xs lg:text-sm xl:text-base">
                              Not Available
                            </div>
                          )
                        ) : (
                          <div className="h-4 bg-zinc-200 w-[120px] lg:w-[160px] xl:w-[200px]" />
                        )}
                      </div>

                      {showExchangeToken?.withdrawExchangeTokens ? (
                        <div className="flex gap-6 items-center">
                          <input
                            type="number"
                            value={tokenValue}
                            onChange={(e) => setTokenValue(e.target.value)}
                            placeholder="0"
                          />
                          <button
                            onClick={() =>
                              handleAddExchangeToken(
                                "withdrawExchangeTokens",
                                true
                              )
                            }
                            className="btn btn-sm btn-primary flex-shrink-0"
                          >
                            Withdraw
                          </button>
                          <button
                            onClick={() =>
                              setShowExchangeToken({
                                ...initialShowExchange,
                                withdrawExchangeTokens: false,
                              })
                            }
                            className="btn btn-sm btn-danger"
                          >
                            Cancel
                          </button>
                        </div>
                      ) : (
                        <span
                          onClick={() =>
                            setShowExchangeToken({
                              ...initialShowExchange,
                              withdrawExchangeTokens: true,
                            })
                          }
                          className="btn btn-sm btn-primary"
                        >
                          Withdraw
                        </span>
                      )}
                    </div>
                    <div className="flex gap-2 items-center">
                      <div
                        onClick={() => handleSwitchChange("exchangeBalUSDT")}
                        className="text-xs xl:text-sm font-semibold cursor-pointer gap-1 flex-center"
                      >
                        <span className="flex-shrink-0 text-black/80">
                          Read More{" "}
                        </span>
                        <span className="text-2xl">
                          {switchToggle.exchangeBalUSDT
                            ? reactIcons.arrowUp
                            : reactIcons.arrowDown}
                        </span>
                      </div>
                    </div>
                  </div>
                  <Collapse in={switchToggle.exchangeBalUSDT}>
                    {" "}
                    <p className="text-sm font-semibold text-zinc-600">
                      USDT Balance
                    </p>
                  </Collapse>
                </div>
              </div>
            </div>
            <div className="p-3 py-5 rounded-lg border border-zinc-200 ">
              <h5 className="mb-6 text-black/80">Configuration</h5>
              <div className="space-y-6">
                <div className="flex flex-col gap-2">
                  <div className="flex gap-4 justify-between items-center">
                    <div className="flex gap-6 items-center">
                      <div className="flex gap-2 items-center w-[180px] lg:w-[200px] xl:w-[230px] flex-shrink-0">
                        <span
                          onClick={() =>
                            setEyesToggle({
                              ...eyesToggle,
                              exchangeRate: !eyesToggle.exchangeRate,
                            })
                          }
                          role="button"
                          className="text-zinc-400 text-xl lg:text-[22px] "
                        >
                          {eyesToggle.exchangeRate
                            ? reactIcons.eyeVisible
                            : reactIcons.eyeInVisible}
                        </span>
                        <b className="text-xs lg:text-sm xl:text-base">
                          Exchange Rate
                        </b>
                      </div>
                      <div className="min-w-[120px] lg:min-w-[160px] xl:min-w-[200px]">
                        {eyesToggle.exchangeRate ? (
                          exchangeDetails?.exchangeRateValue ? (
                            exchangeDetails?.exchangeRateValue
                          ) : (
                            <div className="text-xs lg:text-sm xl:text-base">
                              Not Available
                            </div>
                          )
                        ) : (
                          <div className="h-4 bg-zinc-200 w-[120px] lg:w-[160px] xl:w-[200px]" />
                        )}
                      </div>
                      {showExchangeToken?.changeExchangeRate ? (
                        <div className="flex gap-6 items-center">
                          <input
                            type="number"
                            value={tokenValue}
                            onChange={(e) => setTokenValue(e.target.value)}
                            placeholder="0"
                          />
                          <button
                            onClick={() =>
                              handleAddExchangeToken(
                                "changeExchangeRate",
                                false
                              )
                            }
                            className="btn btn-sm-width btn-sm btn-primary flex-shrink-0"
                          >
                            Change
                          </button>
                          <button
                            onClick={() =>
                              setShowExchangeToken({
                                ...initialShowExchange,
                                changeExchangeRate: false,
                              })
                            }
                            className="btn btn-sm btn-danger"
                          >
                            Cancel
                          </button>
                        </div>
                      ) : (
                        <span
                          onClick={() =>
                            setShowExchangeToken({
                              ...initialShowExchange,
                              changeExchangeRate: true,
                            })
                          }
                          className="btn btn-lg-width btn-sm btn-primary"
                        >
                          Change Exchange Rate
                        </span>
                      )}
                    </div>
                    <div className="flex gap-2 items-center">
                      <div
                        onClick={() => handleSwitchChange("exchangeRate")}
                        className="text-xs xl:text-sm font-semibold cursor-pointer gap-1 flex-center"
                      >
                        <span className="flex-shrink-0 text-black/80">
                          Read More{" "}
                        </span>
                        <span className="text-2xl">
                          {switchToggle.exchangeRate
                            ? reactIcons.arrowUp
                            : reactIcons.arrowDown}
                        </span>
                      </div>
                    </div>
                  </div>
                  <Collapse in={switchToggle.exchangeRate}>
                    {" "}
                    <p className="text-sm font-semibold text-zinc-600">
                      Exhange Rate 1 USDFc = exchange rate * USDT
                    </p>
                  </Collapse>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex gap-4 justify-between items-center">
                    <div className="flex gap-6 items-center">
                      <div className="flex gap-2 items-center w-[180px] lg:w-[200px] xl:w-[230px] flex-shrink-0">
                        <span
                          onClick={() =>
                            setEyesToggle({
                              ...eyesToggle,
                              exchangeIntRate: !eyesToggle.exchangeIntRate,
                            })
                          }
                          role="button"
                          className="text-zinc-400 text-xl lg:text-[22px] "
                        >
                          {eyesToggle.exchangeIntRate
                            ? reactIcons.eyeVisible
                            : reactIcons.eyeInVisible}
                        </span>
                        <b className="text-xs lg:text-sm xl:text-base">
                          Commission Rate
                        </b>
                      </div>
                      <div className="min-w-[120px] lg:min-w-[160px] xl:min-w-[200px]">
                        {" "}
                        {eyesToggle.exchangeIntRate ? (
                          exchangeDetails?.interestRate ? (
                            exchangeDetails?.interestRate
                          ) : (
                            <div className="text-xs lg:text-sm xl:text-base">
                              Not Available
                            </div>
                          )
                        ) : (
                          <div className="h-4 bg-zinc-200 w-[120px] lg:w-[160px] xl:w-[200px]" />
                        )}
                      </div>
                      {showExchangeToken?.changeInterestRate ? (
                        <div className="flex gap-6 items-center">
                          <input
                            type="number"
                            value={tokenValue}
                            onChange={(e) => setTokenValue(e.target.value)}
                            placeholder="0"
                          />
                          <button
                            onClick={() =>
                              handleAddExchangeToken(
                                "changeInterestRate",
                                false
                              )
                            }
                            className="btn btn-sm-width btn-sm btn-primary flex-shrink-0"
                          >
                            Change
                          </button>
                          <button
                            onClick={() =>
                              setShowExchangeToken({
                                ...initialShowExchange,
                                changeInterestRate: false,
                              })
                            }
                            className="btn btn-sm btn-danger"
                          >
                            Cancel
                          </button>
                        </div>
                      ) : (
                        <span
                          onClick={() =>
                            setShowExchangeToken({
                              ...initialShowExchange,
                              changeInterestRate: true,
                            })
                          }
                          className="btn btn-lg-width btn-sm btn-primary"
                        >
                          Change Commission Rate
                        </span>
                      )}
                    </div>
                    <div className="flex gap-2 items-center">
                      <div
                        onClick={() => handleSwitchChange("exchangeIntRate")}
                        className="text-xs xl:text-sm font-semibold cursor-pointer gap-1 flex-center"
                      >
                        <span className="flex-shrink-0 text-black/80">
                          Read More{" "}
                        </span>
                        <span className="text-2xl">
                          {switchToggle.exchangeIntRate
                            ? reactIcons.arrowUp
                            : reactIcons.arrowDown}
                        </span>
                      </div>
                    </div>
                  </div>
                  <Collapse in={switchToggle.exchangeIntRate}>
                    {" "}
                    <p className="text-sm font-semibold text-zinc-600">
                      Interest Rate
                    </p>
                  </Collapse>
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-6 items-center mb-4 mt-5">
            <h4>Partner Exchange</h4>
            <div className="flex gap-1 items-center">
              <span className="text-xs lg:text-sm font-semibold">
                {" "}
                {contracts[process.env.REACT_APP_NETWORK].partnerExchange.slice(
                  0,
                  4
                ) +
                  "..." +
                  contracts[
                    process.env.REACT_APP_NETWORK
                  ].partnerExchange.slice(-4)}
              </span>
              <CopyToClipboard
                text={contracts[process.env.REACT_APP_NETWORK].partnerExchange}
                onCopy={() => toast.success("Copied SuccessFully")}
              >
                <span className="mx-1 text-xl text-zinc-400 cursor-pointer w-10 h-10 rounded-lg flex-center hover:bg-zinc-100">
                  {reactIcons.copy}
                </span>
              </CopyToClipboard>
              <a
                className="underline hover:text-blue-500 text-blue-400 font-semibold text-xs  "
                href={`https://polygonscan.com/address/${
                  contracts[process.env.REACT_APP_NETWORK].partnerExchange
                }`}
                target="_blank"
                rel="noopener noreferrer"
              >
                View On Explorer
              </a>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-4">
            <div className="p-3 py-5 rounded-lg border border-zinc-200 ">
              <h5 className="mb-6 text-black/80">Token Addresses</h5>
              <div className="space-y-6">
                <div className="flex flex-col gap-2">
                  <div className="flex gap-4 justify-between items-center">
                    <div className="flex gap-6 items-center">
                      <div className="flex gap-2 items-center w-[180px] lg:w-[200px] xl:w-[230px] flex-shrink-0">
                        <span
                          onClick={() =>
                            setEyesPartnerToggle({
                              ...eyesPartnerToggle,
                              exchangeFxfAdd: !eyesPartnerToggle.exchangeFxfAdd,
                            })
                          }
                          role="button"
                          className="text-zinc-400 text-xl lg:text-[22px] "
                        >
                          {eyesPartnerToggle.exchangeFxfAdd
                            ? reactIcons.eyeVisible
                            : reactIcons.eyeInVisible}
                        </span>
                        <b className="text-xs lg:text-sm xl:text-base">
                          USDFc Address
                        </b>
                      </div>
                      <div>
                        {eyesPartnerToggle.exchangeFxfAdd ? (
                          partnerExchangeDetails?.exchangeBaseToken ? (
                            <div className="flex gap-1 items-center">
                              <span className="text-xs lg:text-sm font-semibold">
                                {" "}
                                {partnerExchangeDetails?.exchangeBaseToken.slice(
                                  0,
                                  4
                                ) +
                                  "..." +
                                  partnerExchangeDetails?.exchangeBaseToken.slice(
                                    -4
                                  )}
                              </span>
                              <CopyToClipboard
                                text={partnerExchangeDetails?.exchangeBaseToken}
                                onCopy={() =>
                                  toast.success("Copied SuccessFully")
                                }
                              >
                                <span className="mx-1 text-xl text-zinc-400 cursor-pointer w-10 h-10 rounded-lg flex-center hover:bg-zinc-100">
                                  {reactIcons.copy}
                                </span>
                              </CopyToClipboard>
                              <a
                                className="underline hover:text-blue-500 text-blue-400 font-semibold text-xs  "
                                href={`https://polygonscan.com/address/${partnerExchangeDetails?.exchangeBaseToken}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                View On Explorer
                              </a>
                            </div>
                          ) : (
                            <div className="text-xs lg:text-sm xl:text-base">
                              Not Available
                            </div>
                          )
                        ) : (
                          <div className="h-4 bg-zinc-200 w-[120px] lg:w-[160px] xl:w-[200px]" />
                        )}
                      </div>
                    </div>
                    <div className="flex gap-2 items-center">
                      <div
                        onClick={() =>
                          handlePartnerSwitchChange("exchangeFxfAdd")
                        }
                        className="text-xs xl:text-sm font-semibold cursor-pointer gap-1 flex-center"
                      >
                        <span className="flex-shrink-0 text-black/80">
                          Read More{" "}
                        </span>
                        <span className="text-2xl">
                          {partnerSwitchToggle.exchangeFxfAdd
                            ? reactIcons.arrowUp
                            : reactIcons.arrowDown}
                        </span>
                      </div>
                    </div>
                  </div>

                  <Collapse in={partnerSwitchToggle.exchangeFxfAdd}>
                    <p className="text-sm font-semibold text-zinc-600">
                      USDFc Exchange Address
                    </p>
                  </Collapse>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex gap-4 justify-between items-center">
                    <div className="flex gap-6 items-center">
                      <div className="flex gap-2 items-center w-[180px] lg:w-[200px] xl:w-[230px] flex-shrink-0">
                        <span
                          onClick={() =>
                            setEyesPartnerToggle({
                              ...eyesPartnerToggle,
                              exchangeUSDTAdd:
                                !eyesPartnerToggle.exchangeUSDTAdd,
                            })
                          }
                          role="button"
                          className="text-zinc-400 text-xl lg:text-[22px] "
                        >
                          {eyesPartnerToggle.exchangeUSDTAdd
                            ? reactIcons.eyeVisible
                            : reactIcons.eyeInVisible}
                        </span>
                        <b className="text-xs lg:text-sm xl:text-base">
                          USDT Address
                        </b>
                      </div>

                      <div>
                        {" "}
                        {eyesPartnerToggle.exchangeUSDTAdd ? (
                          partnerExchangeDetails?.exchangeTokenAddress ? (
                            <div className="flex gap-1 items-center">
                              <span className="text-xs lg:text-sm font-semibold">
                                {" "}
                                {partnerExchangeDetails?.exchangeTokenAddress.slice(
                                  0,
                                  4
                                ) +
                                  "..." +
                                  partnerExchangeDetails?.exchangeTokenAddress.slice(
                                    -4
                                  )}
                              </span>
                              <CopyToClipboard
                                text={
                                  partnerExchangeDetails?.exchangeTokenAddress
                                }
                                onCopy={() =>
                                  toast.success("Copied SuccessFully")
                                }
                              >
                                <span className="mx-1 text-xl text-zinc-400 cursor-pointer w-10 h-10 rounded-lg flex-center hover:bg-zinc-100">
                                  {reactIcons.copy}
                                </span>
                              </CopyToClipboard>
                              <a
                                className="underline hover:text-blue-500 text-blue-400 font-semibold text-xs  "
                                href={`https://polygonscan.com/address/${partnerExchangeDetails?.exchangeTokenAddress}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                View On Explorer
                              </a>
                            </div>
                          ) : (
                            <div className="text-xs lg:text-sm xl:text-base">
                              Not Available
                            </div>
                          )
                        ) : (
                          <div className="h-4 bg-zinc-200 w-[120px] lg:w-[160px] xl:w-[200px]" />
                        )}
                      </div>
                      {showPartnerExchangeToken?.changeExchangeToken ? (
                        <div className="flex gap-6 items-center">
                          <input
                            type="number"
                            value={partnerTokenValue}
                            onChange={(e) =>
                              setPartnerTokenValue(e.target.value)
                            }
                            placeholder="0"
                          />
                          <button
                            onClick={() =>
                              handleAddPartnerExchangeToken(
                                "changeExchangeToken",
                                false
                              )
                            }
                            className="btn btn-sm-width btn-sm btn-primary flex-shrink-0"
                          >
                            Change
                          </button>
                          <button
                            onClick={() =>
                              setShowPartnerExchangeToken({
                                ...initialShowExchange,
                                changeExchangeToken: false,
                              })
                            }
                            className="btn btn-sm btn-danger"
                          >
                            Cancel
                          </button>
                        </div>
                      ) : (
                        <span
                          onClick={() =>
                            setShowPartnerExchangeToken({
                              ...initialShowExchange,
                              changeExchangeToken: true,
                            })
                          }
                          className="btn btn-sm btn-primary"
                        >
                          Change Exchange Token
                        </span>
                      )}
                    </div>
                    <div className="flex gap-2 items-center">
                      <div
                        onClick={() =>
                          handlePartnerSwitchChange("exchangeUSDTAdd")
                        }
                        className="text-xs xl:text-sm font-semibold cursor-pointer gap-1 flex-center"
                      >
                        <span className="flex-shrink-0 text-black/80">
                          Read More{" "}
                        </span>
                        <span className="text-2xl">
                          {partnerSwitchToggle.exchangeUSDTAdd
                            ? reactIcons.arrowUp
                            : reactIcons.arrowDown}
                        </span>
                      </div>
                    </div>
                  </div>
                  <Collapse in={partnerSwitchToggle.exchangeUSDTAdd}>
                    {" "}
                    <p className="text-sm font-semibold text-zinc-600">
                      USDT Address
                    </p>
                  </Collapse>
                </div>
              </div>
            </div>
            <div className="p-3 py-5 rounded-lg border border-zinc-200 ">
              <h5 className="mb-6 text-black/80">Token Balances</h5>
              <div className="space-y-6">
                <div className="flex flex-col gap-2">
                  <div className="flex gap-4 justify-between items-center">
                    <div className="flex gap-6 items-center">
                      <div className="flex gap-2 items-center w-[180px] lg:w-[200px] xl:w-[230px] flex-shrink-0">
                        <span
                          onClick={() =>
                            setEyesPartnerToggle({
                              ...eyesPartnerToggle,
                              exchangeBalFxf: !eyesPartnerToggle.exchangeBalFxf,
                            })
                          }
                          role="button"
                          className="text-zinc-400 text-xl lg:text-[22px] "
                        >
                          {eyesPartnerToggle.exchangeBalFxf
                            ? reactIcons.eyeVisible
                            : reactIcons.eyeInVisible}
                        </span>
                        <b className="text-xs lg:text-sm xl:text-base">
                          Balance of USDFc
                        </b>
                      </div>
                      <div className="min-w-[120px] lg:min-w-[160px] xl:min-w-[200px]">
                        {" "}
                        {eyesPartnerToggle.exchangeBalFxf ? (
                          partnerExchangeDetails?.exchangeBalanceOfToken ? (
                            partnerExchangeDetails?.exchangeBalanceOfToken
                          ) : (
                            <div className="text-xs lg:text-sm xl:text-base">
                              Not Available
                            </div>
                          )
                        ) : (
                          <div className="h-4 bg-zinc-200 w-[120px] lg:w-[160px] xl:w-[200px]" />
                        )}
                      </div>
                      {showPartnerExchangeToken?.addBaseTokens ? (
                        <div className="flex gap-2 items-center">
                          <input
                            type="number"
                            value={partnerTokenValue}
                            onChange={(e) =>
                              setPartnerTokenValue(e.target.value)
                            }
                            placeholder="0"
                          />
                          <button
                            onClick={() =>
                              handleAddPartnerExchangeToken(
                                "addBaseTokens",
                                true
                              )
                            }
                            className="btn btn-sm-width btn-sm btn-primary flex-shrink-0"
                          >
                            Add
                          </button>
                          <button
                            onClick={() =>
                              setShowPartnerExchangeToken({
                                ...initialShowExchange,
                                showPartnerExchangeToken: false,
                              })
                            }
                            className="btn btn-sm-width btn-sm btn-danger"
                          >
                            Cancel
                          </button>
                        </div>
                      ) : (
                        <span
                          onClick={() =>
                            setShowPartnerExchangeToken({
                              ...initialShowExchange,
                              addBaseTokens: true,
                            })
                          }
                          className="btn btn-sm-width btn-sm btn-primary"
                        >
                          Add
                        </span>
                      )}
                      {showPartnerExchangeToken?.withdrawBaseTokens ? (
                        <div className="flex gap-6 items-center">
                          <input
                            type="number"
                            value={partnerTokenValue}
                            onChange={(e) =>
                              setPartnerTokenValue(e.target.value)
                            }
                            placeholder="0"
                          />
                          <button
                            onClick={() =>
                              handleAddPartnerExchangeToken(
                                "withdrawBaseTokens",
                                true
                              )
                            }
                            className="btn btn-sm-width btn-sm btn-primary flex-shrink-0"
                          >
                            Withdraw
                          </button>
                          <button
                            onClick={() =>
                              setShowPartnerExchangeToken({
                                ...initialShowExchange,
                                withdrawBaseTokens: false,
                              })
                            }
                            className="btn btn-sm-width btn-sm btn-danger"
                          >
                            Cancel
                          </button>
                        </div>
                      ) : (
                        <span
                          onClick={() =>
                            setShowPartnerExchangeToken({
                              ...initialShowExchange,
                              withdrawBaseTokens: true,
                            })
                          }
                          className="btn btn-sm-width btn-sm btn-primary"
                        >
                          Withdraw
                        </span>
                      )}
                    </div>
                    <div className="flex gap-2 items-center">
                      <div
                        onClick={() =>
                          handlePartnerSwitchChange("exchangeBalFxf")
                        }
                        className="text-xs xl:text-sm font-semibold cursor-pointer gap-1 flex-center"
                      >
                        <span className="flex-shrink-0 text-black/80">
                          Read More{" "}
                        </span>
                        <span className="text-2xl">
                          {partnerSwitchToggle.exchangeBalFxf
                            ? reactIcons.arrowUp
                            : reactIcons.arrowDown}
                        </span>
                      </div>
                    </div>
                  </div>
                  <Collapse in={partnerSwitchToggle.exchangeBalFxf}>
                    <p className="text-sm font-semibold text-zinc-600">
                      Add USDFc Exchange Balance
                    </p>
                  </Collapse>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex gap-4 justify-between items-center">
                    <div className="flex gap-6 items-center">
                      <div className="flex gap-2 items-center w-[180px] lg:w-[200px] xl:w-[230px] flex-shrink-0">
                        <span
                          onClick={() =>
                            setEyesPartnerToggle({
                              ...eyesPartnerToggle,
                              exchangeBalUSDT:
                                !eyesPartnerToggle.exchangeBalUSDT,
                            })
                          }
                          role="button"
                          className="text-zinc-400 text-xl lg:text-[22px] "
                        >
                          {eyesPartnerToggle.exchangeBalUSDT
                            ? reactIcons.eyeVisible
                            : reactIcons.eyeInVisible}
                        </span>
                        <b className="text-xs lg:text-sm xl:text-base">
                          Balance Of USDT
                        </b>
                      </div>
                      <div className="min-w-[120px] lg:min-w-[160px] xl:min-w-[200px]">
                        {" "}
                        {eyesPartnerToggle.exchangeBalUSDT ? (
                          partnerExchangeDetails?.balanceOfExchangeToken ? (
                            partnerExchangeDetails?.balanceOfExchangeToken
                          ) : (
                            <div className="text-xs lg:text-sm xl:text-base">
                              Not Available
                            </div>
                          )
                        ) : (
                          <div className="h-4 bg-zinc-200 w-[120px] lg:w-[160px] xl:w-[200px]" />
                        )}
                      </div>

                      {showPartnerExchangeToken?.withdrawExchangeTokens ? (
                        <div className="flex gap-6 items-center">
                          <input
                            type="number"
                            value={partnerTokenValue}
                            onChange={(e) =>
                              setPartnerTokenValue(e.target.value)
                            }
                            placeholder="0"
                          />
                          <button
                            onClick={() =>
                              handleAddPartnerExchangeToken(
                                "withdrawExchangeTokens",
                                true
                              )
                            }
                            className="btn btn-sm btn-primary flex-shrink-0"
                          >
                            Withdraw
                          </button>
                          <button
                            onClick={() =>
                              setShowPartnerExchangeToken({
                                ...initialShowExchange,
                                withdrawExchangeTokens: false,
                              })
                            }
                            className="btn btn-sm btn-danger"
                          >
                            Cancel
                          </button>
                        </div>
                      ) : (
                        <span
                          onClick={() =>
                            setShowPartnerExchangeToken({
                              ...initialShowExchange,
                              withdrawExchangeTokens: true,
                            })
                          }
                          className="btn btn-sm btn-primary"
                        >
                          Withdraw
                        </span>
                      )}
                    </div>
                    <div className="flex gap-2 items-center">
                      <div
                        onClick={() =>
                          handlePartnerSwitchChange("exchangeBalUSDT")
                        }
                        className="text-xs xl:text-sm font-semibold cursor-pointer gap-1 flex-center"
                      >
                        <span className="flex-shrink-0 text-black/80">
                          Read More{" "}
                        </span>
                        <span className="text-2xl">
                          {partnerSwitchToggle.exchangeBalUSDT
                            ? reactIcons.arrowUp
                            : reactIcons.arrowDown}
                        </span>
                      </div>
                    </div>
                  </div>
                  <Collapse in={partnerSwitchToggle.exchangeBalUSDT}>
                    {" "}
                    <p className="text-sm font-semibold text-zinc-600">
                      USDT Balance
                    </p>
                  </Collapse>
                </div>
              </div>
            </div>
            <div className="p-3 py-5 rounded-lg border border-zinc-200 ">
              <h5 className="mb-6 text-black/80">Configuration</h5>
              <div className="space-y-6">
                <div className="flex flex-col gap-2">
                  <div className="flex gap-4 justify-between items-center">
                    <div className="flex gap-6 items-center">
                      <div className="flex gap-2 items-center w-[180px] lg:w-[200px] xl:w-[230px] flex-shrink-0">
                        <span
                          onClick={() =>
                            setEyesPartnerToggle({
                              ...eyesPartnerToggle,
                              exchangeRate: !eyesPartnerToggle.exchangeRate,
                            })
                          }
                          role="button"
                          className="text-zinc-400 text-xl lg:text-[22px] "
                        >
                          {eyesPartnerToggle.exchangeRate
                            ? reactIcons.eyeVisible
                            : reactIcons.eyeInVisible}
                        </span>
                        <b className="text-xs lg:text-sm xl:text-base">
                          Exchange Rate
                        </b>
                      </div>
                      <div className="min-w-[120px] lg:min-w-[160px] xl:min-w-[200px]">
                        {eyesPartnerToggle.exchangeRate ? (
                          partnerExchangeDetails?.exchangeRateValue ? (
                            partnerExchangeDetails?.exchangeRateValue
                          ) : (
                            <div className="text-xs lg:text-sm xl:text-base">
                              Not Available
                            </div>
                          )
                        ) : (
                          <div className="h-4 bg-zinc-200 w-[120px] lg:w-[160px] xl:w-[200px]" />
                        )}
                      </div>
                      {showPartnerExchangeToken?.changeExchangeRate ? (
                        <div className="flex gap-6 items-center">
                          <input
                            type="number"
                            value={partnerTokenValue}
                            onChange={(e) =>
                              setPartnerTokenValue(e.target.value)
                            }
                            placeholder="0"
                          />
                          <button
                            onClick={() =>
                              handleAddPartnerExchangeToken(
                                "changeExchangeRate",
                                false
                              )
                            }
                            className="btn btn-sm-width btn-sm btn-primary flex-shrink-0"
                          >
                            Change
                          </button>
                          <button
                            onClick={() =>
                              setShowPartnerExchangeToken({
                                ...initialShowExchange,
                                changeExchangeRate: false,
                              })
                            }
                            className="btn btn-sm btn-danger"
                          >
                            Cancel
                          </button>
                        </div>
                      ) : (
                        <span
                          onClick={() =>
                            setShowPartnerExchangeToken({
                              ...initialShowExchange,
                              changeExchangeRate: true,
                            })
                          }
                          className="btn btn-lg-width btn-sm btn-primary"
                        >
                          Change Exchange Rate
                        </span>
                      )}
                    </div>
                    <div className="flex gap-2 items-center">
                      <div
                        onClick={() =>
                          handlePartnerSwitchChange("exchangeRate")
                        }
                        className="text-xs xl:text-sm font-semibold cursor-pointer gap-1 flex-center"
                      >
                        <span className="flex-shrink-0 text-black/80">
                          Read More{" "}
                        </span>
                        <span className="text-2xl">
                          {partnerSwitchToggle.exchangeRate
                            ? reactIcons.arrowUp
                            : reactIcons.arrowDown}
                        </span>
                      </div>
                    </div>
                  </div>
                  <Collapse in={partnerSwitchToggle.exchangeRate}>
                    {" "}
                    <p className="text-sm font-semibold text-zinc-600">
                      Exhange Rate 1 USDFc = exchange rate * USDT
                    </p>
                  </Collapse>
                </div>
                <div className="flex-col gap-2 hidden">
                  <div className="flex gap-4 justify-between items-center">
                    <div className="flex gap-6 items-center">
                      <div className="flex gap-2 items-center w-[180px] lg:w-[200px] xl:w-[230px] flex-shrink-0">
                        <span
                          onClick={() =>
                            setEyesPartnerToggle({
                              ...eyesPartnerToggle,
                              exchangeIntRate:
                                !eyesPartnerToggle.exchangeIntRate,
                            })
                          }
                          role="button"
                          className="text-zinc-400 text-xl lg:text-[22px] "
                        >
                          {eyesPartnerToggle.exchangeIntRate
                            ? reactIcons.eyeVisible
                            : reactIcons.eyeInVisible}
                        </span>
                        <b className="text-xs lg:text-sm xl:text-base">
                          Commission Rate
                        </b>
                      </div>
                      <div className="min-w-[120px] lg:min-w-[160px] xl:min-w-[200px]">
                        {" "}
                        {eyesPartnerToggle.exchangeIntRate ? (
                          partnerExchangeDetails?.interestRate ? (
                            partnerExchangeDetails?.interestRate
                          ) : (
                            <div className="text-xs lg:text-sm xl:text-base">
                              Not Available
                            </div>
                          )
                        ) : (
                          <div className="h-4 bg-zinc-200 w-[120px] lg:w-[160px] xl:w-[200px]" />
                        )}
                      </div>
                      {showPartnerExchangeToken?.changeInterestRate ? (
                        <div className="flex gap-6 items-center">
                          <input
                            type="number"
                            value={partnerTokenValue}
                            onChange={(e) =>
                              setPartnerTokenValue(e.target.value)
                            }
                            placeholder="0"
                          />
                          <button
                            onClick={() =>
                              handleAddPartnerExchangeToken(
                                "changeInterestRate",
                                false
                              )
                            }
                            className="btn btn-sm-width btn-sm btn-primary flex-shrink-0"
                          >
                            Change
                          </button>
                          <button
                            onClick={() =>
                              setShowPartnerExchangeToken({
                                ...initialShowExchange,
                                changeInterestRate: false,
                              })
                            }
                            className="btn btn-sm btn-danger"
                          >
                            Cancel
                          </button>
                        </div>
                      ) : (
                        <span
                          onClick={() =>
                            setShowPartnerExchangeToken({
                              ...initialShowExchange,
                              changeInterestRate: true,
                            })
                          }
                          className="btn btn-lg-width btn-sm btn-primary"
                        >
                          Change Commission Rate
                        </span>
                      )}
                    </div>
                    <div className="flex gap-2 items-center">
                      <div
                        onClick={() =>
                          handlePartnerSwitchChange("exchangeIntRate")
                        }
                        className="text-xs xl:text-sm font-semibold cursor-pointer gap-1 flex-center"
                      >
                        <span className="flex-shrink-0 text-black/80">
                          Read More{" "}
                        </span>
                        <span className="text-2xl">
                          {partnerSwitchToggle.exchangeIntRate
                            ? reactIcons.arrowUp
                            : reactIcons.arrowDown}
                        </span>
                      </div>
                    </div>
                  </div>
                  <Collapse in={partnerSwitchToggle.exchangeIntRate}>
                    {" "}
                    <p className="text-sm font-semibold text-zinc-600">
                      Interest Rate
                    </p>
                  </Collapse>
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-6 items-center mb-4 mt-5">
            <h4>Social handler</h4>
          </div>

          <div className="grid grid-cols-1 gap-4">
            <div className="p-3 py-5 rounded-lg border border-zinc-200 ">
              <div className="grid grid-cols-3 gap-3 mb-3">
                <div>
                  <input
                    className="w-full"
                    type="text"
                    value={form.telegram}
                    name="telegram"
                    placeholder="Telegram"
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <input
                    className="w-full"
                    type="text"
                    value={form.discord}
                    name="discord"
                    placeholder="Discord"
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <input
                    className="w-full"
                    type="text"
                    value={form.facebook}
                    name="facebook"
                    placeholder="Facebook"
                    onChange={handleChange}
                  />
                </div>
              </div>

              <button
                className="btn btn-sm btn-primary ml-auto w-[200px]"
                onClick={handleSubmit}
              >
                {socialData == null ? "Save" : "Update"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Setting;
